import http from "./httpService";

const endpointPath = "RestLocation.svc";
const saveReviewsPutMethod = "AddReview";

function saveReviewsURL() {
  return `${endpointPath}/${saveReviewsPutMethod}`;
}

export async function saveReview(newReview) {
  const config = await http.post(saveReviewsURL(), newReview, {
    params: null,
  });

  return config ? true : false;
}
