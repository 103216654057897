import React, { useEffect, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import CardWrapper from "../cardWrapper/cardWrapper";
import Events from "../events/events";
import GoogleMap from "../googleMap/googleMap";
import LocationInformation from "../locationInformation/locationInformation";
// import RegistrationLoginLinks from "../registrationLoginLinks/registrationLoginLinks";
import SocialMediaIcons from "../socialMediaIcons/socialMediaIcons";
import admins from "../../config.json";
import "./eventLandingPage.scss";
// import MapboxGL from "../mapboxGL/mapboxGL";
import {
  createMapboxFeatureArray,
  // getBreadcrumbItems,
  getGEOBreadcrumbItems,
} from "../../utils/bullseyeUtils";
import BreadcrumbMenu from "../breadcrumbMenu";
import { getUrlParameterValue } from "../../utils/utils";
import { useMutationResize } from "../../hooks/useMutationResize";
import { useRef } from "react";

const cssBaseURL = process.env.REACT_APP_BULLSEYE_RESOURCE;
const homepage = process.env.REACT_APP_VIRTUAL_PATH;

const MapboxGL = React.lazy(() => import("../mapboxGL"));

const EventLandingPage = ({ clientConfig, event, crumbs }) => {
  let containerDivStyle = {};
  let socialMediaIcons = [];
  const showBreadcrumb = getUrlParameterValue("breadcrumb");
  const showMode = getUrlParameterValue("mode");

  if (clientConfig.containerWidth > 0) {
    containerDivStyle = { maxWidth: clientConfig.containerWidth };
  }

  if (event.FacebookURL) {
    socialMediaIcons.push({
      name: "facebook",
      url: admins.facebookMainUrl + event.FacebookURL,
    });
  }

  if (event.TwitterURL) {
    socialMediaIcons.push({
      name: "twitter",
      url: admins.TwitterMainUrl + event.TwitterURL,
    });
  }

  useEffect(() => window.parent.postMessage("scroll", "*"), []);

  const listMapContainer = useRef();
  useMutationResize(listMapContainer.current);

  return (
    <>
      <div
        ref={listMapContainer}
        className={clientConfig.isResponsive ? "container-fluid" : "container"}
        style={containerDivStyle}
      >
        <div className="row event-landing-custom-header-class">
          <div className="col-12 bl-no-gutter-sm">
            {clientConfig.enableCustomHeaderFooter && clientConfig.headerCode && (
              <div
                dangerouslySetInnerHTML={{
                  __html: clientConfig.headerCode,
                }}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 slider__main eventLandingPage__main">
            {/* {clientConfig.showLocationLink && (
              <div className="my-2 d-flex flex-column align-items-end">
                <RegistrationLoginLinks
                  loginUrl={loginUrl.replace("{0}", clientConfig.subdomain)}
                  registerLink={registerUrl
                    .replace("{0}", clientConfig.subdomain)
                    .replace("{1}", clientConfig.configClientSearch.clientId)}
                />
              </div>
            )} */}
            {navigator.userAgent !== "ReactSnap" && showBreadcrumb !== "false" && (
              <div className="card mt-2">
                <BreadcrumbMenu
                  items={getGEOBreadcrumbItems(
                    crumbs,
                    event.EventTitle,
                    clientConfig.templateTypeID,
                    clientConfig.breadcrumbText,
                    "EventLandingPage",
                    clientConfig.showInterfaceLocationListOnly,
                    clientConfig.cityCollectionId,
                    null,
                    null,
                    null,
                    clientConfig.interfaceName,
                    null,
                    clientConfig.hostDomain,
                    clientConfig.isIframeEmbed,
                    event,
                    showMode
                  )}
                />
                {/* <BreadcrumbMenu
                  items={getBreadcrumbItems(
                    crumbs,
                    event.EventTitle,
                    clientConfig.templateTypeID,
                    clientConfig.clientTerm.defaultStoreLocator,
                    event,
                    "event"
                  )}
                /> */}
              </div>
            )}
            <div>
              <h1 className="text-center">{event.EventTitle}</h1>
            </div>
            <hr className="w-1 m-0 p-0"></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8">
            <CardWrapper>
              <Events
                eventList={[{ ...event }]}
                maxItemsToDisplay={1}
                showDateLikeDescription={true}
              ></Events>
            </CardWrapper>
            {(event.EventDescription || event.EventImageURL) && (
              <CardWrapper>
                <>
                  <h2 className="bullseye-red">Details</h2>
                  <p>{event.EventDescription}</p>
                  {event.EventImageURL && (
                    <img src={event.EventImageURL} alt={event.EventTitle}></img>
                  )}
                </>
              </CardWrapper>
            )}
            {(event.OrganizerName || event.OrganizerDescription) && (
              <CardWrapper>
                <>
                  <h2 className="bullseye-red">Organizer</h2>
                  <strong>{event.OrganizerName}</strong>
                  <p>{event.OrganizerDescription}</p>
                </>
              </CardWrapper>
            )}
          </div>
          <div className="col-12 col-md-4">
            {event.EventLocations.slice(0, 10).map((loc) => {
              const {
                Address1: address1,
                Address2: address2,
                City: city,
                PhoneNumber: phone,
                StateAbbr: stateAbbr,
                PostCode: postalCode,
                Name: locationName,
                Latitude: latitude,
                Longitude: longitude,
                Id: id,
              } = loc;

              return (
                <CardWrapper key={id}>
                  <>
                    <div
                      className="mb-3 eventLanding__mapContainer"
                      style={{ height: 220 }}
                    >
                      {clientConfig.showGoogleMap ? (
                        <GoogleMap
                          markers={[
                            {
                              latitude: latitude,
                              longitude: longitude,
                              id: id,
                            },
                          ]}
                          googleApikey={clientConfig.mappingSettings[0].apiKey}
                          showLandingPageLink={false}
                          customPin={
                            clientConfig.defaultMapIcon ??
                            "/local/images/beIcon.png"
                          }
                          showPopup={false}
                          hideControls={true}
                        />
                      ) : (
                        clientConfig.showMapbox && (
                          <Suspense fallback={<div>Loading ... </div>}>
                            <MapboxGL
                              features={createMapboxFeatureArray([
                                {
                                  latitude: latitude,
                                  longitude: longitude,
                                  id: id,
                                },
                              ])}
                              mapboxToken={
                                clientConfig?.mappingSettings[0]?.token
                              }
                              customPin={clientConfig.defaultMapIcon}
                              showPopup={false}
                            ></MapboxGL>
                          </Suspense>
                        )
                      )}
                    </div>
                    <LocationInformation
                      address1={address1}
                      address2={address2}
                      city={city}
                      phone={phone}
                      stateAbbr={stateAbbr}
                      postalCode={postalCode}
                      locationName={locationName}
                      locationId={id}
                    ></LocationInformation>
                  </>
                </CardWrapper>
              );
            })}
            {socialMediaIcons.length > 0 && (
              <CardWrapper>
                <SocialMediaIcons icons={socialMediaIcons}></SocialMediaIcons>
              </CardWrapper>
            )}
            {event.RegistrationURL && (
              <div className="mt-3">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lg btn-bullseye w-100"
                  href={event.RegistrationURL}
                >
                  Register Now
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="row event-landing-custom-footer-class">
          <div className="col-12 bl-no-gutter-sm">
            {clientConfig.enableCustomHeaderFooter && clientConfig.footerCode && (
              <div
                dangerouslySetInnerHTML={{
                  __html: clientConfig.footerCode,
                }}
              />
            )}
          </div>
        </div>
      </div>
      {clientConfig.configClientSearch.clientId > 0 && (
        <Helmet>
          {/* <script
            src={`${cssBaseURL}/${clientConfig.configClientSearch.clientId}/landing/${clientConfig.name}.advanced.min.js`}
          ></script> */}
          <script>{`${clientConfig.advancedStyleJS}`}</script>
          {clientConfig.faviconFileUrl ? (
            <link rel="icon" href={clientConfig.faviconFileUrl} />
          ) : (
            <link rel="icon" href={`${homepage}favicon.ico`} />
          )}
        </Helmet>
      )}
    </>
  );
};

export default EventLandingPage;
