import _ from "lodash";
import { parse, stringify } from "qs";
import { Fragment } from "react";
import { getCustomPin } from "./bullseyeUtils2";
import {
  COUNTRY_SEARCH_STYLE,
  SEARCH_STYLE,
  BULLSEYE_TEMPLATE_TYPE_LOCATOR,
  SEARCH_TYPE,
} from "./constants";
import {
  removeURLParameter,
  getUrlParameterValue,
  capitalizeStringFirstLetter,
} from "./utils";

const bullseyeResources = process.env.REACT_APP_BULLSEYE_RESOURCE + "/";

/** This is directly related with DoSearch2 parameter do not add any new parameter
 *  Without discussion
 */
const knownParameters = [
  "countryid",
  "country",
  "postalcode",
  "postcode",
  "zip",
  "city",
  "state",
  // "leadsource",
  // "langcode",
  // "lng",
  // "languagecode",
  // "lang",
  "keyword",
  // "radius",
  "lat",
  "lng",
  "latitude",
  "longitude",
  "distanceunit",
  "categoryids",
  "category",
  "categories",
  "searchlogid",
  // "searchdisplaytext",
  // "urlparent",
  // "f",
];

export function getSearchParametersToStr(searchParameters, countries) {
  let countryName = searchParameters.countryId
    ? countries.find((x) => x.id === searchParameters.countryId).name
    : "";

  return [
    countryName,
    searchParameters.state,
    searchParameters.city,
    searchParameters.postalCode,
  ]
    .filter((x) => x)
    .join(", ");
}

export function validRadiusSearches(countryRegion) {
  return (
    countryRegion &&
    (countryRegion.searchTypeId === SEARCH_TYPE.internationalRadius ||
      countryRegion.searchTypeId === SEARCH_TYPE.radius ||
      countryRegion.searchTypeId === SEARCH_TYPE.territoryOrRadius ||
      countryRegion.searchTypeId === SEARCH_TYPE.territoryAndRadius)
  );
}

export function buildSearchParameters(
  querystring,
  searchParameters,
  langCode,
  searchLogId,
  enableCustomLLP,
  addReturnURL = true
) {
  if (!querystring && langCode) {
    querystring = "?LangCode=" + langCode;
  }

  //Remove repeated parameters
  let newQueryString = querystring
    .replace(/.*\?/g, "")
    .split("&")
    .filter((e, i, a) => a.indexOf(e) === i)
    .join("&");

  querystring = querystring.replace(/\?.*/g, "?" + newQueryString);

  let searchParams = parse(querystring.toLocaleLowerCase(), {
    ignoreQueryPrefix: true,
  });

  Object.keys(searchParams).forEach((param) => {
    // This is going to delete all known parameters and
    // only keep the extra parameters that are not part
    // of bullseye search.
    if (knownParameters.includes(param)) {
      delete searchParams[param];
    }

    // Delete extra parameters to avoid repeting in the querystring

    [
      "langcode",
      "lng",
      "languagecode",
      "lang",
      "urlparent",
      "searchdisplaytext",
      "radius",
    ].forEach((x) => delete searchParams[x]);

    if (searchParameters) {
      Object.keys(searchParameters).forEach((bullseyeParam) => {
        if (searchParameters[bullseyeParam]) {
          searchParams[bullseyeParam] = searchParameters[bullseyeParam];
        }
      });
    }
  });

  if (langCode) {
    searchParams.langCode = langCode;
  }

  if (searchLogId) {
    searchParams.searchlogid = searchLogId;
  }

  //const urlParent = getUrlParameterValue("urlParent");

  if (!searchParams || Object.keys(searchParams).length === 0) return "";

  let parameters = stringify(searchParams, { addQueryPrefix: true });

  // if (addReturnURL) {
  //   if (enableCustomLLP) {
  //     parameters +=
  //       "&urlR=" +
  //       (urlParent
  //         ? encodeURI(urlParent)
  //         : encodeURI(window.location.href.split("?")[0]));
  //   } else {
  //     parameters += "&urlR=" + encodeURI(window.location.href.split("?")[0]);
  //   }
  // }

  return parameters;
}

export function buildGetDirectionsLink(
  address1,
  city,
  stateAbbr,
  postalCode,
  country
) {
  return `https://maps.google.com/maps?saddr=&daddr=${address1 || ""} ${
    city || ""
  } ${stateAbbr || ""} ${postalCode || ""} ${country || ""}`;
}

export function getQueryStringParam(fullQuerystring, key) {
  const searchParams = parse(fullQuerystring.toLocaleLowerCase(), {
    ignoreQueryPrefix: true,
  });

  if (Array.isArray(key)) {
    for (let stringKey of key) {
      if (Object.keys(searchParams).includes(stringKey.toLocaleLowerCase())) {
        return searchParams[stringKey.toLocaleLowerCase()];
      }
    }
  } else {
    if (Object.keys(searchParams).includes(key.toLocaleLowerCase())) {
      return searchParams[key.toLocaleLowerCase()];
    } else {
      return null;
    }
  }

  return null;
}

export function getValidQuerystringParams(fullQuerystring) {
  if (!fullQuerystring) return {};

  const searchParams = parse(fullQuerystring, {
    ignoreQueryPrefix: true,
  });

  let returnObject = {};

  Object.keys(searchParams).forEach((param) => {
    const lowerCaseParam = param.toLocaleLowerCase();
    const objectValue = searchParams[param];

    if (knownParameters.includes(lowerCaseParam)) {
      switch (lowerCaseParam) {
        case "leadsource":
          returnObject.leadSource = objectValue;
          break;
        case "langcode":
        case "languagecode":
        case "lng":
        case "lang":
          returnObject.langCode = objectValue;
          break;
        case "lat":
          returnObject.latitude = objectValue;
          break;

        case "lon":
          returnObject.longitude = objectValue;
          break;
        case "countryid":
        case "country":
          if (parseInt(objectValue, 10) > 0) {
            returnObject.countryId = parseInt(objectValue, 10);
          }
          break;
        case "radius":
          if (parseInt(objectValue, 10) > 0) {
            returnObject.radius = parseInt(objectValue, 10);
          }
          break;
        case "postalcode":
        case "zip":
        case "postcode":
          returnObject.postalCode = objectValue;
          break;
        case "distanceunit":
          returnObject.distanceUnit = objectValue;
          break;
        case "categoryids":
        case "category":
        case "categories":
          returnObject.categoryids = objectValue;
          break;
        default:
          returnObject[lowerCaseParam] = objectValue;
          break;
      }
    } else {
      // remove from the returnObject unknown parameters
    }

    if (
      !returnObject.distanceUnit &&
      (returnObject.countryId === 1 || returnObject.countryId === 88)
    ) {
      returnObject.distanceUnit = "mi";
    } else if (!returnObject.distanceUnit && returnObject.countryId > 0) {
      returnObject.distanceUnit = "km";
    }
  });

  /** If the only filter is the category We are manage this on the next component using
   * a normal querystring
   */
  if (Object.keys(returnObject).length === 1 && returnObject?.categoryids) {
    delete returnObject.categoryids;
  }

  if (searchParams["ip"]) {
    return returnObject;
  }

  // No search should could be done without country
  if (
    Object.keys(returnObject).length > 0 &&
    !returnObject?.countryId &&
    !returnObject?.keyword
  ) {
    return { error: "CountryId or Keyword is required!" };
  }

  return returnObject;
}

export function GetSearchStyleTag(searchStyleId, countryId) {
  if (countryId === 1) {
    // USA
    return {
      tag: COUNTRY_SEARCH_STYLE.CITY_STATE_OR_ZIPCODE,
      requiredTag: "defaultCityandStateorZipisRequiredTerm",
    };
  }

  switch (searchStyleId) {
    case SEARCH_STYLE.city:
      return {
        tag: COUNTRY_SEARCH_STYLE.CITY_TAG,
        requiredTag: "defaultCityIsRequiredTerm",
      };
    case SEARCH_STYLE.cityAndRegion:
      return {
        tag: COUNTRY_SEARCH_STYLE.CITY_AND_REGION_TAG,
        requiredTag: "defaultCityAndRegionAreRequiredTerm",
      };
    case SEARCH_STYLE.cityAndRegionORPostcode:
      return {
        tag: COUNTRY_SEARCH_STYLE.CITY_AND_REGION_OR_POSTCODE_TAG,
        requiredTag: "defaultCityandProvinceorPostalCodeisRequiredTerm",
      };
    case SEARCH_STYLE.cityORPostcode:
      return {
        tag: COUNTRY_SEARCH_STYLE.CITY_OR_POSTCODE_TAG,
        requiredTag: "defaultCityorPostCodeisRequiredTerm",
      };
    case SEARCH_STYLE.cityORRegion:
      return {
        tag: COUNTRY_SEARCH_STYLE.CITY_OR_REGION_TAG,
        requiredTag: "defaultCityorRegionisRequiredTerm",
      };
    case SEARCH_STYLE.cityORRegionORPostcode:
      return {
        tag: COUNTRY_SEARCH_STYLE.CITY_OR_REGION_OR_POSTCODE_TAG,
        requiredTag: "defaultCityorRegionorPostCodeisRequiredTerm",
      };
    case SEARCH_STYLE.regionORPostcode:
      return {
        tag: COUNTRY_SEARCH_STYLE.REGION_OR_POSTCODE_TAG,
        requiredTag: "defaultRegionORPostcodeIsRequiredTerm",
      };
    default:
      return { tag: "", requiredTag: "" };
  }
}

/** Fix bullseye resource admin Relative path to absolute path */
export function fixRelativePath(imagePath) {
  if (!imagePath) return imagePath;

  let returnPath = imagePath;

  if (imagePath.toLowerCase().includes("~/resources/")) {
    returnPath = imagePath
      .toLowerCase()
      .replace("~/resources/", bullseyeResources);
  }

  // I did this because sometimes the server returns encode url
  // and if I only apply the encodeURI sometimes the urls are double encode
  return encodeURI(decodeURI(returnPath));
}

export function getAttributeValue(attributeList, attributeId, isImage) {
  if (!attributeList || attributeList.length === 0) return "";

  const attribute = attributeList.find(
    (attr) => attr.AttributeId === attributeId
  );

  if (attribute) {
    return isImage
      ? fixRelativePath(attribute.AttributeValue)
      : attribute.AttributeValue;
  }

  return "";
}

export function mapBullseyeDataToProps(object) {
  let newObject = _.mapKeys(object, (value, key) => {
    const newKey = _.camelCase(key);

    switch (newKey) {
      case "countryCode":
        return "country";
      case "stateAbbreviation":
        return "stateAbbr";
      case "phoneNumber":
        return "phone";
      case "name":
        return "locationName";
      case "postCode":
        return "postalCode";

      default:
        return newKey;
    }
  });

  return newObject;
}

export function mapBullseyeHoursToProps(dailyHoursList) {
  return dailyHoursList.map((hour, index) => {
    // Format the Name of The Day depending of today or special hours
    const nameOfDay = [<Fragment key="name">{hour.NameOfDay}</Fragment>];
    const hours = [];

    if (index === 0) {
      nameOfDay.push(
        <small className="ml-1" key="today">
          (today)
        </small>
      );
    }

    if (hour.HolidayName) {
      nameOfDay.push(
        <small className="ml-1" key={hour.HolidayName}>
          ({hour.HolidayName})
        </small>
      );
    }

    if (hour.NoHoursAvailable) {
      hours.push(
        <span key="closed" className="bullseye-btn bullseye-btn-danger">
          Closed
        </span>
      );
    } else if (hour.HoursDisplayText) {
      const splitHour = hour.HoursDisplayText.split("-");

      hours.push(<time key="start_time">{splitHour[0]}</time>);
      hours.push(<span key="separator"> - </span>);
      hours.push(<time key="end_time">{splitHour[1]}</time>);
    }

    if (hour.SpecialHours) {
      hours.push(
        <span
          key="special_hour"
          className="ml-1 bullseye-btn bullseye-btn-success"
        >
          Special Hours
        </span>
      );
    }

    if (hour.HolidayHours) {
      hours.push(" ");
      hours.push(
        <span
          key={hour.HolidayHours}
          className="bullseye-btn bullseye-btn-success"
        >
          Holiday Hours
        </span>
      );
    }

    return {
      day: nameOfDay,
      hours: hours,
    };
  });
}

export function removeSpaces(str) {
  if (!str) return "";
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/--/g, "-");
}

export function buildLocalPageURL(
  countryCode,
  stateAbbr,
  city,
  locationName,
  locationId,
  interfaceName,
  baseURL,
  isListInterface = false,
  isInternetLocation = false,
  seoProxyKeyword,
  seoLocationIdentifier,
  thirdPartyId,
  eventUrl,
  interfaceId
) {
  let items = [];
  let locationIdentifier = null;

  if (!locationId) {
    return null;
  }

  if (baseURL.lastIndexOf("/") === baseURL.length - 1) {
    baseURL = baseURL.slice(0, -1);
  }

  if (!interfaceName) {
    interfaceName = interfaceId;
  }

  items.push(baseURL);
  items.push(interfaceName);

  if (eventUrl) {
    items.push(eventUrl);
  }

  if (seoLocationIdentifier === 2) {
    locationIdentifier = thirdPartyId;
  }

  // if is Event URL We are using the event id not the thirdpartyId
  if (seoLocationIdentifier === 1 || eventUrl) {
    locationIdentifier = locationId;
  }

  // We should not create the link if no identifier is present
  if (!locationIdentifier) return null;

  if (seoProxyKeyword?.length > 0) {
    locationName = seoProxyKeyword;
  }

  if (countryCode) items.push(countryCode);
  if (stateAbbr) items.push(stateAbbr);
  if (city) items.push(removeSpaces(city));

  const slug = `${removeSpaces(locationName)}-${
    city ? removeSpaces(city) : ""
  }-${stateAbbr ? stateAbbr : ""}-${locationIdentifier}`;
  items.push(slug);

  let resultURL = items.join("/");
  let urlQueryStringParams = "";

  if (isListInterface) {
    urlQueryStringParams = localStorage.getItem("urlParams")
      ? localStorage.getItem("urlParams")
      : "";
  }

  return resultURL + (urlQueryStringParams ? urlQueryStringParams : "");
}

export function getCountryCodes(countries) {
  // return ["us", "ca", "mx", "co"];
  if (!countries || countries?.length === 0 || countries?.length > 5) return [];

  return countries.map((country) => country.code);
}

export function createMapboxFeatureArray(locationsList, categoryIcons) {
  let featureArray = [];

  locationsList.forEach((loc) => {
    const locationCustomIcon = getCustomPin(
      categoryIcons,
      loc && loc.categoryIds?.split(",").map((x) => parseInt(x))
    );

    featureArray.push({
      type: "Feature",
      properties: {
        name: loc.locationName,
        address1: loc.address1,
        city: loc.city,
        stateAbbr: loc.state,
        postalCode: loc.postalCode,
        country: loc.country,
        id: loc.id,
        iconSize: [35, 35],
        imageUrl: loc.imageFileUrl,
        customPin: locationCustomIcon,
      },
      geometry: {
        type: "Point",
        coordinates: [loc.longitude, loc.latitude],
      },
    });
  });

  return featureArray;
}

export function createSeoLocationObject(location) {
  let finalLocationObject = {};

  finalLocationObject.URL = location.url;
  finalLocationObject.Name = location.locationName;
  finalLocationObject.Latitude = location.latitude;
  finalLocationObject.Longitude = location.longitude;
  finalLocationObject.EmailAddress = location.emailAddress;
  finalLocationObject.PhoneNumber = location.phone;
  finalLocationObject.logo = location.imageFileUrl;
  finalLocationObject.City = location.city;
  finalLocationObject.StateAbbreviation = location.state;
  finalLocationObject.PostCode = location.postalCode;
  finalLocationObject.Address1 = location.address1;
  finalLocationObject.ContactPosition = location.contactPosition;
  finalLocationObject.DailyHoursList = location.dailyHoursList;

  return finalLocationObject;
}

export function getGEOBreadcrumbItems(
  crumbs,
  itemDisplayText,
  interfaceTypeId,
  defaultStoreLocatorText,
  itemType,
  locationListActive,
  cityCollectionId,
  countryCode,
  stateAbbr,
  city,
  interfaceName,
  isInternetLocation,
  urlHostReturn,
  isIframeEmbed,
  infoObject,
  previewMode
) {
  let breadcrumbItems = [];
  let virtualDirectory;
  let locationListDirectory;

  const embedParameter = getUrlParameterValue("f");

  let returnToHostUrl = true;

  if (embedParameter === "1") {
    returnToHostUrl = true;
  } else {
    returnToHostUrl = false;
  }

  if (!returnToHostUrl) {
    if (isIframeEmbed && urlHostReturn) {
      isIframeEmbed = true;
    }
  }

  const urlHostName = window.location.hostname;
  //const returnURL = getUrlParameterValue("urlR");

  if (locationListActive) {
    //CityList only can be supported in USA and Canada.
    locationListDirectory =
      cityCollectionId &&
      cityCollectionId > 0 &&
      (countryCode === "US" || countryCode === "CA")
        ? `/${process.env.REACT_APP_CITYLIST_ONLY_NAME}/`
        : `/${process.env.REACT_APP_LIST_ONLY_NAME}/`;
  }

  if (
    interfaceTypeId === BULLSEYE_TEMPLATE_TYPE_LOCATOR.embeddableReactInterface
  ) {
    virtualDirectory = process.env.REACT_APP_VIRTUAL_PATH;
  } else {
    virtualDirectory = process.env.REACT_APP_VIRTUAL_CLASSIC_INTERFACE_PATH;
  }

  crumbs
    .slice()
    .reverse()
    .forEach(function (item, idx) {
      let urlItem;
      let displayTextItem;
      if (idx === crumbs.length - 1) {
        breadcrumbItems.push({
          displayText: itemDisplayText,
        });
      } else {
        if (
          locationListActive &&
          itemType === "LocationLandingPage" &&
          !isInternetLocation
        ) {
          //Geo breadcrumb
          if (item.name === "Standard Locator") {
            //Add store locator
            let locatorURL = `//${urlHostName}${item.path
              .replace("/local/", virtualDirectory)
              .replace("//", "")}${window.location.search}`;

            if (isIframeEmbed && !previewMode) {
              locatorURL = `${urlHostReturn}${window.location.search}`;
            }

            urlItem = locatorURL;
            displayTextItem = defaultStoreLocatorText;

            breadcrumbItems.push({
              url: urlItem,
              displayText: displayTextItem,
              isExternalLink: true,
            });

            //Country - return main page location list
            let locationListBaseUrl = `//${urlHostName}/local${locationListDirectory}${interfaceName}`;
            urlItem = locationListBaseUrl + `${window.location.search}`;
            displayTextItem = countryCode;

            breadcrumbItems.push({
              url: urlItem,
              displayText: displayTextItem,
              isExternalLink: true,
            });

            //State Level - Return to state level locationList
            urlItem =
              locationListBaseUrl +
              `/${countryCode}/${stateAbbr}/${window.location.search}`;
            displayTextItem = stateAbbr;

            breadcrumbItems.push({
              url: urlItem,
              displayText: displayTextItem,
              isExternalLink: true,
            });

            //City Level - Return to City Level List
            if (city && city.length > 0) {
              urlItem =
                locationListBaseUrl +
                `/${countryCode}/${stateAbbr}/${
                  cityCollectionId && cityCollectionId > 0
                    ? city
                    : removeSpaces(city)
                }/${window.location.search}`;
              displayTextItem = city;

              breadcrumbItems.push({
                url: urlItem,
                displayText: displayTextItem,
                isExternalLink: true,
              });
            }
          }
        } else {
          //normal breadcrumb

          if (item.name === "Standard Locator") {
            //Add store locator
            let locatorURL = `//${urlHostName}${item.path
              .replace("/local/", virtualDirectory)
              .replace("//", "")}${window.location.search}`;

            if (isIframeEmbed && !previewMode) {
              locatorURL = `${urlHostReturn}${window.location.search}`;
            }

            urlItem = locatorURL;
            displayTextItem = defaultStoreLocatorText;
          }

          if (itemType === "EventLandingPage") {
            const locationIdParam = getUrlParameterValue("locationId");
            if (item.name === "Local Page 1") {
              const location = infoObject.EventLocations.find(
                (x) => x.Id.toString() === locationIdParam
              );

              urlItem = `//${urlHostName}${item.path.replace(
                "//",
                ""
              )}${removeURLParameter(window.location.search, "locationId")}`;
              displayTextItem = location ? location.Name : "Landing Page";
            } else {
              displayTextItem = defaultStoreLocatorText;
              if (isIframeEmbed && !previewMode) {
                urlItem = `${urlHostReturn}${window.location.search}`;
              } else {
                urlItem = `//${urlHostName}${item.path
                  .replace("/local/", virtualDirectory)
                  .replace("//", "")}${window.location.search}`;
              }
            }
          }

          breadcrumbItems.push({
            url: urlItem,
            displayText: displayTextItem,
            isExternalLink: true,
          });
        }
      }
      if (displayTextItem) {
      }
    });

  return breadcrumbItems;
}
export function getGEOBreadcrumbItemsLocationList(
  defaultStoreLocatorText,
  countryCode,
  stateAbbr,
  city,
  interfaceName,
  interfaceTypeId,
  mainUrl,
  urlHostReturn,
  isIframeEmbed
) {
  let breadcrumbItems = [];
  let urlQueryStringParams = localStorage.getItem("urlParams")
    ? localStorage.getItem("urlParams")
    : "";
  let locationListDirectory = process.env.REACT_APP_LIST_ONLY_NAME;
  const urlHostName = mainUrl;

  const embedParameter = getUrlParameterValue("f");

  let returnToHostUrl = true;

  if (embedParameter === "1") {
    returnToHostUrl = true;
  } else {
    returnToHostUrl = false;
  }

  if (!returnToHostUrl) {
    if (isIframeEmbed && urlHostReturn) {
      isIframeEmbed = true;
    }
  }

  let locatorUrl = `${urlHostName}/local/${interfaceName}${removeURLParameter(
    urlQueryStringParams,
    "urlR"
  )}`;

  if (isIframeEmbed) {
    locatorUrl = `${urlHostReturn}${urlQueryStringParams}`;
  }

  let locationListBaseUrl = `${urlHostName}/local/${locationListDirectory}/${interfaceName}`;

  //Proceed to add locator link
  let urlItem;
  let displayTextItem;

  urlItem = locatorUrl;
  displayTextItem = defaultStoreLocatorText;
  breadcrumbItems.push({
    url: urlItem,
    displayText: displayTextItem,
    isExternalLink: true,
  });

  if (countryCode && countryCode.length > 0) {
    urlItem = locationListBaseUrl;
    displayTextItem = countryCode.toUpperCase();

    breadcrumbItems.push({
      url: urlItem,
      displayText: displayTextItem,
      isExternalLink: true,
    });
  }

  if (stateAbbr && stateAbbr.length > 0) {
    urlItem = `${locationListBaseUrl}/${countryCode}/${stateAbbr}/`;
    displayTextItem = stateAbbr.toUpperCase();

    breadcrumbItems.push({
      url: urlItem,
      displayText: displayTextItem,
      isExternalLink: city && city.length > 0 ? true : false,
    });
  }

  if (city && city.length > 0) {
    //City Level doesn't need to have a link
    let cityName = city
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    displayTextItem = capitalizeStringFirstLetter(
      cityName.replaceAll("-", " ")
    );

    breadcrumbItems.push({
      displayText: displayTextItem,
      isExternalLink: false,
    });
  }

  return breadcrumbItems;
}

export function getBreadcrumbItems(
  crumbs,
  displayText,
  interfaceTypeId,
  defaultStoreLocator,
  infoObject,
  type,
  cityCollectionId
) {
  let breadcrumbItems = [];
  const urlHostName = window.location.hostname;

  let isList = getUrlParameterValue("islist");

  const locationIdParam = getUrlParameterValue("locationId");
  const isInternetLocation = getUrlParameterValue("internetlocation");
  const returnURL = getUrlParameterValue("urlR");

  let virtualDirectory;

  if (
    interfaceTypeId === BULLSEYE_TEMPLATE_TYPE_LOCATOR.embeddableReactInterface
  ) {
    virtualDirectory = "/local/";
  } else {
    virtualDirectory = "/pages/";
  }

  crumbs
    .slice()
    .reverse()
    .forEach(function (item, idx) {
      let urlItem;
      let displayTextItem;
      if (idx === crumbs.length - 1) {
        breadcrumbItems.push({
          displayText: displayText,
        });
      } else {
        if (type === "event" && item.name === "Local Page 1") {
          let location = infoObject.EventLocations.find(
            (x) => x.Id === locationIdParam
          );
          urlItem = `//${urlHostName}${item.path.replace(
            "//",
            ""
          )}${removeURLParameter(window.location.search, "locationId")}`;
          displayTextItem = location ? location.Name : "Landing Page";
        } else if (isList && item.name === "Standard Locator") {
          let urlValues = window.location.pathname.split("/");
          const interfaceName = urlValues[2];
          const country = urlValues[3];
          const state = urlValues[4];
          const city = urlValues[5];

          const virtualLocationListFolder = cityCollectionId
            ? process.env.REACT_APP_CITYLIST_ONLY_NAME
            : process.env.REACT_APP_LIST_ONLY_NAME;

          if (isInternetLocation) {
            //Return List internet location URL
            urlItem = `//${urlHostName}/local/${process.env.REACT_APP_LIST_ONLY_NAME}/${interfaceName}`;
          } else {
            //Return List Normal Location URL

            urlItem = `//${urlHostName}/local/${virtualLocationListFolder}/`;

            if (
              virtualLocationListFolder ===
              process.env.REACT_APP_CITYLIST_ONLY_NAME
            ) {
              //Proceed to read the parameter for city list back functionality
              const citylistcountry = getUrlParameterValue("citylistcountry");
              const cityliststate = getUrlParameterValue("cityliststate");
              const citylistcity = getUrlParameterValue("citylistcity");

              let params = window.location.search;
              params = removeURLParameter(params, "citylistcountry");
              params = removeURLParameter(params, "cityliststate");
              params = removeURLParameter(params, "citylistcity");
              params = removeURLParameter(params, "islist");

              urlItem += `${interfaceName}/${citylistcountry}/${cityliststate}/${citylistcity}/${params}`;
            } else {
              urlItem += `${interfaceName}/${country}/${state}/${city
                .toLocaleLowerCase()
                .replace(" ", "-")}/`;
            }
          }
          displayTextItem = "Location List";
        } else {
          if (returnURL && returnURL.length > 0) {
            urlItem = `${returnURL}${removeURLParameter(
              window.location.search,
              "urlR"
            )}`;
          } else {
            urlItem = `//${urlHostName}${item.path
              .replace("/local/", virtualDirectory)
              .replace("//", "")}${window.location.search}`;
          }
          displayTextItem = defaultStoreLocator;
        }

        breadcrumbItems.push({
          url: urlItem,
          displayText: displayTextItem,
          isExternalLink: true,
        });
      }
    });

  return breadcrumbItems;
}

export function getEventLandingPageURL(event, urlPath, locationId) {
  const regexSpecialCharacters = /[&#,+()$~%.'":*?<>{}]/g;
  const regexSpaceCharacters = /[ ]/g;
  let eventURL = `${event.EventTitle.replace(
    regexSpecialCharacters,
    ""
  ).replace(regexSpaceCharacters, "-")}-${event.EventId}`;
  let urlParameters;
  if (window.location.search.length > 0) {
    urlParameters = `${window.location.search}&locationId=${locationId}`;
  } else {
    urlParameters = `?locationId=${locationId}`;
  }

  return `//${window.location.host}${urlPath}/events/${eventURL}${urlParameters}`;
}

export const getLandingPageURL = (baseURL, windowHref) => {
  let finalLandingPageURL = "";
  if (baseURL) {
    finalLandingPageURL =
      baseURL.replace("/pages/", "") +
      window.location.pathname.replace("/local/", "").split("?")[0];
  } else {
    finalLandingPageURL = windowHref.split("?")[0];
  }

  return finalLandingPageURL;
};
