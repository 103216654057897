import { SearchState } from ".";
import { SearchParameters } from "../../interfaces";

type SearchActionType = {
  type: "[Search] - SetParameters";
  payload: SearchParameters;
};

export const searchReducer = (
  state: SearchState,
  action: SearchActionType
): SearchState => {
  switch (action.type) {
    case "[Search] - SetParameters":
      return {
        ...state,
        searchParameters: action.payload,
      };

    default:
      return state;
  }
};
