import axios from "axios";
import * as Sentry from "@sentry/react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

axios.defaults.baseURL = process.env.REACT_APP_URL_API;
axios.defaults.timeout = 30000; // 30s

axios.interceptors.response.use(null, function (error) {
  setTimeout(() => {
    Sentry.setExtra(error);
    Sentry.captureException(error);
  }, 1);

  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.error("Unexpected Service error", error.request);

    if (error.request.response.includes("System.Exception: 8003")) {
      window.location.pathname = "error/not-configured-expired";
      return Promise.reject(error);
    }

    if (
      error.request.response.includes(
        "MaxMind.GeoIP2.Exceptions.AddressNotFoundException"
      ) ||
      error.config.url.includes("cloudflare.com") ||
      error.config.url.includes("LogSearch") ||
      error.config.url.includes("GetGeoLocationIP") // We don't want to interrupt the flow if the client was not detected
    ) {
      // toast.error("Invalid IP");
      return Promise.reject(error);
    }

    window.location.pathname = "error/unexpected";
  } else if (
    error.response.status === 404 &&
    !error.response.statusText.includes("No valid form")
  ) {
    console.error("Error: ", error);
    // toast.error("Location not found");
    window.location.pathname = "error/not-found";
  } else if (error.response.status === 401) {
    console.error("Error: ", error);
    toast.error("Unauthorized");
  }

  return Promise.reject(error);
});

export function setSecurityParameters(clientId, apiKey) {
  if (!clientId || !apiKey) return;

  axios.defaults.params = {};
  axios.defaults.params["ClientId"] = clientId;
  axios.defaults.params["ApiKey"] = apiKey;
}

export function clearSecurityParameters() {
  axios.defaults.params = {};
}

export function setSecurityParameters2(clientId, apiKey) {
  axios.defaults.params = {};
  axios.defaults.params["ClientId"] = clientId
    ? clientId
    : process.env.REACT_APP_CLIENT_ID;
  axios.defaults.params["ApiKey"] = apiKey
    ? apiKey
    : process.env.REACT_APP_API_KEY;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setSecurityParameters,
  clearSecurityParameters,
};
