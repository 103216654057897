import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import PropTypes from "prop-types";

import DynamicInputSelector from "./dynamicInputSelector";
import ValidationsGenerator from "./validationGenerator";
import "./dynamicForm.scss";
import { Helmet } from "react-helmet-async";

const focusOnError = (submitErrors) => {
  if (document) {
    setTimeout(() => {
      const errorField = document.querySelector(".is-invalid");

      if (errorField) {
        errorField.focus();
      }
    }, [5]);
  }
};

/** Form component, to display forms through a configuration file, with this they are dynamically created
 *  the configuration file has the definition of the inputs and their validations, olso has the submit actions
 *
 */
const DynamicForm = ({
  formLeadConfiguration,
  title,
  subTitle,
  extraTitle,
  small = false,
  compact = false,
  GoogleCaptchaKey = "",
  onSubmit,
  countriesAndStatesConfig,
  cancelButtonAction,
  cagetoriesTree = [],
  hasContainerSetted,
  onCountryChange,
  defaultValues,
  hasError = false,
  customErrorMessage = null,
}) => {
  const { response, enableCaptcha } = formLeadConfiguration;
  const responseContainer = useRef();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    isValidCaptcha: false,
    errorValidCaptcha: "",
    submited: false,
  });
  const [formFields, setFormFields] = useState([]);

  const getShortAddressConfiguration = (fields) => {
    let configShortAddress = {};
    const stAddress = fields.find((x) => x.inputOptionName === "StreetAddress");
    if (stAddress) {
      configShortAddress.streetAddress = {
        validations: ValidationsGenerator(stAddress),
      };
    }
    const City = fields.find((x) => x.inputOptionName === "City");
    if (City) {
      configShortAddress.city = { validations: ValidationsGenerator(City) };
    }
    const State = fields.find((x) => x.inputOptionName === "State");
    if (State) {
      configShortAddress.state = { validations: ValidationsGenerator(State) };
    }
    const AddressLine2 = fields.find(
      (x) => x.inputOptionName === "AddressLine2"
    );
    if (AddressLine2) {
      configShortAddress.addressLine2 = {
        validations: ValidationsGenerator(AddressLine2),
      };
    }
    return configShortAddress;
  };

  const loadConfiguration = () => {
    const { fields } = formLeadConfiguration;
    /** create a new array with the new inputs field to display */
    let newArr = [];

    if (formLeadConfiguration && fields) {
      // make the shortAddressConfiguration before readAllFields
      const existsShortAddress = fields.find((x) => x.inputOptionId === 6)
        ? true
        : false;
      let configShortAddress = {};
      if (existsShortAddress) {
        configShortAddress = getShortAddressConfiguration(fields);
      }

      /** read all the fields on the JSON */
      fields.forEach((item) => {
        /** get the rules validation for the field */
        const newValidationItem = ValidationsGenerator(item);

        let required = newValidationItem?.required ? true : false;

        newArr.push({
          required: required,
          inputOptionName: item.inputOptionName,
          name: item.name ? item.name : item.id.toString(),
          validations: newValidationItem,
          label: item.label,
          placeholder: item.placeholder,
          options:
            item.inputOptionName.toLowerCase() === "categories"
              ? cagetoriesTree
              : item.options,
          configShortAddres: item.inputOptionId === 6 ? configShortAddress : [],
        });
      });
    }
    setFormFields(newArr);
    setIsLoading(false);
  };

  const onSave = (data) => {
    const { isValidCaptcha } = formData;
    /** Validated if captcha is needed */
    if (enableCaptcha) {
      if (!isValidCaptcha) {
        setFormData({ errorValidCaptcha: "Invalid Captcha!" });
        return;
      }
    }
    setFormData({ submited: true });
    onSubmit(data);

    if (responseContainer?.current) {
      responseContainer.current.scrollIntoView();
    }
  };

  /** onChange Input saves on the State */
  const fieldOnChange = (index) => (e) => {
    let newArr = [...formFields];
    newArr[index].value = e.target.value;
    setFormFields(newArr);
  };
  /** Captcha state */
  function onChangeCaptcha(value) {
    if (value) setFormData({ isValidCaptcha: true });
  }

  useEffect(() => {
    if (isLoading) loadConfiguration();
  }, []);

  return (
    <>
      {isLoading && <div>Loading</div>}
      {!isLoading && !formData.submited && (
        <div className="w-100 dynamicForm__container">
          {!hasError && (
            <>
              <div className="bullseye-red h4 mb-4 dynamicForm__formTitle">
                {formLeadConfiguration.title
                  ? formLeadConfiguration.title
                  : title}
              </div>
              {subTitle && (
                <div className="mb-2 dynamicForm__formSubTitle">{subTitle}</div>
              )}
              {extraTitle && (
                <div className="dynamicForm__formExtraTitle">{extraTitle}</div>
              )}
            </>
          )}

          <form
            onSubmit={handleSubmit(onSave, focusOnError)}
            className="dynamicForm__mainForm"
          >
            {!hasError && (
              <>
                {formFields &&
                  formFields.map((item, index) => {
                    return (
                      <DynamicInputSelector
                        key={index}
                        compact={compact}
                        small={small}
                        name={`${item.name}`}
                        label={item.label}
                        inputOptionName={item.inputOptionName}
                        required={item.required}
                        register={register}
                        errors={errors}
                        placeholder={item.placeholder}
                        options={item.options}
                        onChange={() => fieldOnChange(index)}
                        validations={
                          window.bullseyeLocationsConfig?.leadForm?.validations[
                            item.name
                          ]?.required
                            ? {
                                required:
                                  window.bullseyeLocationsConfig.leadForm
                                    .validations[item.name].required,
                              }
                            : item.validations
                        }
                        countriesAndStatesConfig={countriesAndStatesConfig}
                        onCountryChange={onCountryChange}
                        configShortAddres={item.configShortAddres}
                      />
                    );
                  })}
                {formFields && enableCaptcha && (
                  <>
                    <ReCAPTCHA
                      sitekey={GoogleCaptchaKey}
                      onChange={onChangeCaptcha}
                      className={`${
                        hasContainerSetted ? "g-recaptcha__dynamicForm" : ""
                      }`}
                    />
                    <div className="invalid-feedback d-block">
                      {formData.errorValidCaptcha}
                    </div>
                  </>
                )}
                {formFields && (
                  <div className="form-group">
                    <Button
                      className={`mt-3 mr-3 btn-bullseye ${
                        !cancelButtonAction ? "w-100" : ""
                      }`}
                      variant="secondary"
                      type="submit"
                    >
                      Submit
                    </Button>
                    {cancelButtonAction && (
                      <Button
                        className="mt-3 btn-secondary"
                        variant="secondary"
                        onClick={cancelButtonAction}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
            {hasError && (
              <div className="invalid-feedback-xl d-block">
                {customErrorMessage}
              </div>
            )}
          </form>
        </div>
      )}
      {!isLoading && formData.submited && (
        <div ref={responseContainer} className="p-3 dynamicForm__thanksMessage">
          <div className="bullseye-red h4 mb-2">Thank You</div>
          <div className="d-block">{response}</div>
        </div>
      )}
      {!isLoading && !formData.submited && (
        <Helmet>
          <script>{`if (window.leadFormLoaded) {console.log('event: leadFormLoaded'); window.leadFormLoaded();}`}</script>
        </Helmet>
      )}
    </>
  );
};

DynamicForm.propTypes = {
  /** Json file from the WS  */
  formLeadConfiguration: PropTypes.shape({
    /** Tittle Form label */
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    extraTitle: PropTypes.string,
    /**is the property ContainerWidth is setter, requerired for captcha styles  */
    hasContainerSetted: PropTypes.bool,
    /** Type of the formSumission the action of the submit */
    //formSubmissionTypeID: PropTypes.number.isRequired,
    /** Message to show on submit */
    //response: PropTypes.string,
    /** if the formSubmissionTypeID=2 this is url where is called after submit */
    //submissionURL: PropTypes.string,
    /** Option is requiered Captcha on the form, if not comming, the default is false */
    enableCaptcha: PropTypes.bool,
  }).isRequired,
  /** Location infomation */
  /** Location Name */
  locationName: PropTypes.string,
  /** Location address */
  locationAddress: PropTypes.string,
  /** Location ID */
  locationId: PropTypes.number,
  /** option to if paint the small form inputs, if not comming, the default is false  */
  small: PropTypes.bool,
  /** option to if paint the conpact form, if not comming, the default is false  */
  compact: PropTypes.bool,
  /** Google Key with permission to Captcha */
  GoogleCaptchaKey: PropTypes.string,
};

export default DynamicForm;
