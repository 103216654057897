import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "../label/label";
const DateTimeInput = ({
  name,
  label,
  compact,
  small,
  errors,
  register,
  onChange,
  required,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const onChangevalue = (date, e) => {
    setStartDate(date);
    onChange(e);
  };
  return (
    <div className="form-group">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <ReactDatePicker
        selected={startDate}
        className={`form-control p-2 ${small ? "form-control-sm" : ""} ${
          errors["startDate"] ? "is-invalid" : ""
        } `}
        name="startDatePicker"
        onChange={(date, e) => onChangevalue(date, e)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        openToDate={new Date()}
        minDate={new Date()}
        autoComplete={"off"}
      />
      <input
        id={name}
        type={"hidden"}
        value={startDate}
        name={name}
        ref={register}
      />
      {errors[name] && (
        <div className="invalid-feedback d-block">{errors[name]?.message}</div>
      )}
    </div>
  );
};
export default DateTimeInput;
