import React from "react";

export const BullseyeContext = React.createContext({
  location: {
    address1: null,
    address2: null,
    city: null,
    stateAbbr: null,
    postalCode: null,
    country: null,
    locationName: null,
    phone: null,
    contactName: null,
    contactPosition: null,
  },
  landingPageConfiguration: {
    showViewPhoneNumber: null,
  },
});
