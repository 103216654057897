export const GEOCODE_SERVICES = {
  google: 1,
  mapbox: 2,
  be_google: 3,
  be_mapbox: 4,
};

export const getGoogleAnalyticEventName = (
  responseType,
  additionalInformation
) => {
  switch (responseType) {
    case SEARCH_RES_LOG_TYPE.urlClicked:
      return "Click local page website";
    case SEARCH_RES_LOG_TYPE.directionsClicked:
      return "Click local page directions";
    case SEARCH_RES_LOG_TYPE.emailClicked:
      return "Click local page email " + additionalInformation;
    case SEARCH_RES_LOG_TYPE.phoneNumerClicked:
      return "Click local page phone";
    case SEARCH_RES_LOG_TYPE.moreInfoClicked:
      return "Click more information";
    case SEARCH_RES_LOG_TYPE.attributeClicked:
      return "Click local page attribute " + additionalInformation;
    default:
      return responseType;
  }
};

export const FORM_SUBMISSION_TYPES = {
  DisplayMessage: 1,
  RedirectURL: 2,
  RedirectEmbeddedInterface: 3,
};

export const SEARCH_STYLE = {
  none: 0,
  cityAndRegionORPostcode: 1,
  cityORPostcode: 2,
  regionORPostcode: 3,
  cityORRegionORPostcode: 4,
  city: 5,
  cityORRegion: 6,
  cityAndRegion: 7,
};

export const COUNTRY_SEARCH_STYLE = {
  CITY_TAG: "CityOnly",
  CITY_OR_POSTCODE_TAG: "CityORPostcode",
  CITY_OR_REGION_TAG: "CityORRegion",
  CITY_AND_REGION_TAG: "CityAndRegion",
  CITY_OR_REGION_OR_POSTCODE_TAG: "CityORRegionORPostcode",
  CITY_AND_REGION_OR_POSTCODE_TAG: "CityProvinceORPostalCode",
  REGION_OR_POSTCODE_TAG: "RegionORPostcode",
  CITY_STATE_OR_ZIPCODE: "CityStateORZipCode",
};

export const SEARCH_TYPE = {
  radius: 1,
  territory: 2,
  literal: 3,
  country: 4,
  territoryOrRadius: 5,
  territoryAndRadius: 6,
  internetOnly: 7,
  internationalRadius: 8,
  mapLocations: 9,
  locationGroupsLocations: 10,
  territoryLocations: 11,
  companyNameLocations: 12,
  territoryStateOnly: 13,
};
export const CATEGORY_MODE = {
  dropdownList: 1,
  checkboxes: 2,
  multiselect: 3,
  radioButtons: 4,
  label: 5,
};
export const BULLSEYE_LOCATOR_TEMPLATE = {
  listFormat: 1,
  freeWebLocatorWithMap: 6,
  mapOnSide: 7,
  freeFacebook: 8,
  premiumFacebook: 9,
  mapOnTop: 10,
  shopify: 11,
  widget: 12,
  wordPress: 13,
  locationList: 14,
  mapOnly: 15,
  listOnly: 16,
};
export const BULLSEYE_TEMPLATE_TYPE_LOCATOR = {
  standAlone: 1,
  facebook: 2,
  shopify: 3,
  widget: 4,
  wordpress: 5,
  listOnly: 6,
  embeddableInterface: 7,
  promotionPageWithLocationSearch: 8,
  embeddableReactInterface: 9,
};
export const GEOCODING_SERVICE = {
  Google: 1,
  Mapbox: 2,
  BEGoogle: 3,
  BEMapbox: 4,
  Geonames: 46,
};
export const BULLSEYE_REVIEWS_STATUS = {
  Pending: 1,
  Approved: 2,
  Rejected: 3,
};
export const TYPE_SEARCH_BY = {
  bullseyeLocationID: 1,
  thirdPartyID: 2,
};
export const TESTIMONIAL_SUBATTRIBUTE = {
  testimonial: "testimonial",
  citation: "testimonial citations",
};

export const INPUTS_VALIDATIONS_PATTERNS = {
  // eslint-disable-next-line no-useless-escape
  url: /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
  imageURL: /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  // imageURLPattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+(?:png|jpg|jpeg|gif|svg)+$/g,
};
export const REVIEW_TYPES = {
  bullseye: "BEReviews",
  google: "Google",
  yelp: "Yelp",
};
export const COUNTRIES_LIST_IDS = {
  NO_COUNTRY: 0,
  USA: 1,
  CANADA: 2,
  UK: 88,
};

export const SEARCH_RES_LOG_TYPE = {
  urlClicked: 1,
  emailClicked: 2,
  locationResultsClicked: 3,
  locationMapClicked: 4,
  directionsClicked: 5,
  promotionPrint: 6,
  promotionShare: 7,
  locationPromotionPrint: 8,
  attributeClicked: 9,
  phoneNumberClicked: 10,
  moreInfoClicked: 11,
};

export const SEARCH_RES_LOG_TYPE_DESC = [
  "Url Clicked",
  "Email Clicked",
  "Location Results Clicked",
  "Location Map Clicked",
  "Directions Clicked",
  "Promotion Print",
  "Promotion Share",
  "Location Promotion Print",
  "Attribute Clicked",
  "Phone Number Clicked",
  "MoreInfo Clicked",
];

export const EVENT_LOG_TYPE = {
  logSearch: 1,
};

export const formReviewConfiguration = {
  fields: [
    {
      id: 1,
      inputOptionName: "text",
      name: "FirstName",
      label: "First Name",
      validations: [
        {
          boolValue: true,
          errorMessage: "Name is required",
          rule: "isRequired",
        },
      ],
    },
    {
      id: 2,
      inputOptionName: "text",
      name: "LastName",
      label: "Last Name",
      validations: [
        {
          boolValue: true,
          errorMessage: "Last Name is required",
          rule: "isRequired",
        },
      ],
    },
    {
      id: 3,
      inputOptionName: "rate",
      name: "OverallRating",
      label: "Rate it!",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Rate is required",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Rate is required",
          rule: "min",
        },
      ],
    },
    {
      id: 4,
      inputOptionName: "Comments",
      name: "Comments",
      placeholder: "Add your comments",
      label: "Comments",
      validations: [
        {
          boolValue: true,
          errorMessage: "Comments are required.",
          rule: "isRequired",
        },
      ],
    },
    {
      id: 5,
      inputOptionName: "rateService",
      name: "RatingCategoryOne",
      label: "Professionalism",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Professionalism is required",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Professionalism is required",
          rule: "min",
        },
      ],
    },
    {
      id: 6,
      inputOptionName: "rateService",
      name: "RatingCategoryTwo",
      label: "Product Knowledge",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Product Knowledge is required",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Product Knowledge is required",
          rule: "min",
        },
      ],
    },
    {
      id: 7,
      inputOptionName: "rateService",
      name: "RatingCategoryThree",
      label: "Service",
      isNumber: true,
      validations: [
        {
          boolValue: true,
          errorMessage: "Service is required",
          rule: "isRequired",
        },
        {
          min: 0,
          errorMessage: "Service is required",
          rule: "min",
        },
      ],
    },
    {
      id: 8,
      inputOptionName: "yesno",
      name: "Recommend",
      label: "Would you recommend this Location?",
      validations: [],
    },
  ],
};
