import React, { useEffect, useState } from "react";
import { useClientConfiguration } from "../../hooks/useClientConfiguration";
import { useGetLocationList } from "../../hooks/useGetLocationList";
import BullseyeLoader from "../bullseyeLoader/bullseyeLoader";
import BreadcrumbMenu from "../breadcrumbMenu/breadcrumbMenu";

import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import {
  capitalizeStringFirstLetter,
  getUrlParameterValue,
} from "../../utils/utils";
import {
  buildLocalPageURL,
  removeSpaces,
  getGEOBreadcrumbItemsLocationList,
} from "../../utils/bullseyeUtils";
import "./listOnly.scss";
import { useTracking } from "react-tracking";

const cssBaseURL = process.env.REACT_APP_BULLSEYE_RESOURCE;
const homepage = process.env.REACT_APP_VIRTUAL_PATH;

const ListOnly = () => {
  const { interfaceName, country, state, city } = useParams();
  const clientConfig = useClientConfiguration(interfaceName);
  const showMode = getUrlParameterValue("mode");

  const [locations, loading] = useGetLocationList(
    parseInt(clientConfig.clientId),
    clientConfig.apiKey,
    clientConfig.countries.map((country) => country.id).join(","),
    clientConfig.interfaceConfigCategories
      .map((cat) => cat.categoryId)
      .join(","),
    clientConfig.internetOnlySearch
  );

  let containerDivStyle = {};

  if (clientConfig.containerWidth > 0) {
    containerDivStyle = { maxWidth: clientConfig.containerWidth };
  }

  const [items, setItems] = useState([]);

  const { trackEvent } = useTracking({
    page: window.location.pathname,
    eventCategory: "ListOnly",
    googleAnalytic: clientConfig.googleAnalyticsCode,
  });

  //Register the page view event for GA
  useEffect(() => {
    if (!loading) {
      trackEvent({
        event: "pageview",
      });
    }
  }, []);

  useEffect(() => {
    if (!loading && locations.locations.length > 0) {
      //proceed to filter locations that doesnt have city entered
      const finalLocationsList = locations.locations.filter(
        (loc) => loc.City && loc.InternetLocation === false
      );

      const finalInternetLocationList = locations.locations.filter(
        (loc) => loc.InternetLocation === true
      );

      locations.locations = finalLocationsList;
      locations.InternetLocations = finalInternetLocationList;
    }

    if (country && state && city) {
      //Logic to find locations by city
      if (!loading && locations.locations.length > 0) {
        const filteredLocations = locations.locations.filter(
          (x) =>
            x.StateAbbr === state &&
            x.CountryCode === country &&
            removeSpaces(x.City) === city
        );

        const finalElements = [];
        finalElements.push({
          mainTitle:
            "Locations in " +
            capitalizeStringFirstLetter(filteredLocations[0].City),
          mainTitleId: city,
          isExternalLink: true,
          locationInfo: filteredLocations
            .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0))
            .map((locationElement) => {
              return {
                id: locationElement.Id,
                title: locationElement.Name,
                url: buildLocalPageURL(
                  locationElement.CountryCode,
                  locationElement.StateAbbr,
                  locationElement.City,
                  locationElement.Name,
                  locationElement.Id,
                  interfaceName,
                  "/local/",
                  navigator.userAgent !== "ReactSnap",
                  false,
                  clientConfig.proxyKeyword,
                  clientConfig.locationIdentifier,
                  locationElement.ThirdPartyId
                ),
                count: "",
              };
            }),
        });

        setItems(finalElements);
      }
    } else if (country && state && !city) {
      //Logic to find cities in specific state
      const cityItems = [];
      const finalElements = [];

      if (!loading && locations.locations.length > 0) {
        //Filter locations by state and country

        const filteredLocations = locations.locations
          .filter(
            (x) => x.StateAbbr === state && x.CountryCode === country && x.City
          )
          .sort((a, b) => (a.City > b.City ? 1 : b.City > a.City ? -1 : 0));

        //get country information
        const countries = locations.locations.find(
          (x) => x.CountryCode === country
        );

        //get state information
        const states = locations.states.find(
          (x) => x.StateAbbr === state && x.CountryId === countries.CountryId
        );

        //find unique cities name in the locations array
        const uniqueCities = [
          ...new Set(filteredLocations.map((item) => item.City)),
        ];

        uniqueCities.forEach((element) => {
          cityItems.push({
            cityName: element,
            locationCount: filteredLocations.filter((x) => x.City === element)
              .length,
          });
        });

        finalElements.push({
          mainTitle: "Cities in " + states.StateName,
          mainTitleId: states.StateId,
          isExternalLink: false,
          locationInfo: cityItems.map((cityElement) => {
            return {
              id: cityElement.cityName,
              title: cityElement.cityName,
              url: removeSpaces(cityElement.cityName) + "/",
              count: `(${cityElement.locationCount})`,
            };
          }),
        });

        setItems(finalElements);
      }
    } else {
      //logic to show states by countries
      if (!loading && items) {
        //Save the url parameter into the localstorage
        localStorage.setItem("urlParams", window.location.search);
        const finalElements = [];
        locations.countries.forEach((element) => {
          finalElements.push({
            mainTitle: element.CountryName,
            mainTitleId: element.CountryId,
            isExternalLink: false,
            locationInfo: locations.states
              .filter(
                (x) =>
                  x.CountryId === element.CountryId &&
                  locations.locations.filter(
                    (loc) =>
                      loc.CountryId === x.CountryId && loc.StateId === x.StateId
                  ).length > 0
              )
              .map((stateElement) => {
                return {
                  id: stateElement.StateId,
                  title: stateElement.StateName,
                  url:
                    interfaceName +
                    "/" +
                    element.CountryCode +
                    "/" +
                    stateElement.StateAbbr +
                    "/",
                  count: `(${
                    locations.locations.filter(
                      (x) =>
                        x.CountryId === element.CountryId &&
                        x.StateId === stateElement.StateId
                    ).length
                  })`,
                };
              }),
          });
        });

        //Add internet locations
        if (locations && locations.InternetLocations?.length > 0) {
          finalElements.push({
            mainTitle: "Internet Locations",
            mainTitleId: "InternetLocationsId",
            isExternalLink: true,
            locationInfo: locations.InternetLocations.map(
              (internetLocationElement) => {
                return {
                  id: internetLocationElement.Id,
                  title: internetLocationElement.Name,
                  url: buildLocalPageURL(
                    internetLocationElement.CountryCode,
                    internetLocationElement.StateAbbr,
                    internetLocationElement.City,
                    internetLocationElement.Name,
                    internetLocationElement.Id,
                    interfaceName,
                    "/local/",
                    navigator.userAgent !== "ReactSnap",
                    true,
                    clientConfig.proxyKeyword,
                    clientConfig.locationIdentifier,
                    internetLocationElement.ThirdPartyId
                  ),
                  count: "",
                };
              }
            ),
          });
        }

        setItems(finalElements);
      }
    }
  }, [loading, country, state, city]);

  /* END AC */
  return (
    <>
      <div
        className={
          (clientConfig.isResponsive ? "container-fluid " : "container ") +
          "mt-2 mb-3 px-4 bullseye-red listonly-container"
        }
        style={containerDivStyle}
      >
        {!loading && clientConfig?.enableCustomHeaderFooter && (
          <div
            dangerouslySetInnerHTML={{ __html: clientConfig.headerCode }}
          ></div>
        )}
        <div className="mb-2">
          {navigator.userAgent !== "ReactSnap" &&
            !showMode &&
            showMode !== "preview" && (
              <BreadcrumbMenu
                items={getGEOBreadcrumbItemsLocationList(
                  clientConfig.breadcrumbText,
                  country,
                  state,
                  city,
                  interfaceName,
                  clientConfig.templateTypeID,
                  window.location.origin,
                  clientConfig.hostDomain,
                  clientConfig.isIframeEmbed
                )}
              />
              // <a
              //   href={getURLReturn(
              //     interfaceName,
              //     country,
              //     state,
              //     city,
              //     localStorage.getItem("urlParams"),
              //     clientConfig.templateTypeID
              //   )}
              // >
              //   Go Back
              // </a>
            )}
        </div>
        {loading && <BullseyeLoader />}
        <div className="headerCustomCode"></div>
        {!loading &&
          items &&
          items.length > 0 &&
          items
            .filter((x) => x.locationInfo.length > 0)
            .map((item, index) => (
              <>
                <div className="border rounded my-1 p-3 shadow-sm bg-white listonly__state--container">
                  <div className="px-3 py-1 my-1">
                    <div className="col-12 py-2">
                      <h2 className="text-center">{item.mainTitle}</h2>
                    </div>
                  </div>
                  <div className="items" key={item.mainTitle + index}>
                    {item.locationInfo.map((locationInfoItem, index) => (
                      <div className="item" key={locationInfoItem.id + index}>
                        {locationInfoItem.url ? (
                          <Link
                            key={locationInfoItem.title + index}
                            to={locationInfoItem.url}
                          >
                            {locationInfoItem.title} {locationInfoItem.count}
                          </Link>
                        ) : (
                          locationInfoItem.title
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
        <div className="footerCustomCode"></div>
        {!loading && clientConfig?.enableCustomHeaderFooter && (
          <div
            dangerouslySetInnerHTML={{ __html: clientConfig.footerCode }}
          ></div>
        )}
      </div>
      {!loading && clientConfig.clientId > 0 && (
        <Helmet>
          <>
            {/* <script
              src={`${cssBaseURL}/${clientConfig.clientId}/landing/${interfaceName}.advanced.min.js`}
            ></script> */}

            {/* <link
              rel="stylesheet"
              type="text/css"
              href={`${cssBaseURL}/${clientConfig.clientId}/landing/${interfaceName}.min.css`}
            />
            <link
              rel="stylesheet"
              type="text/css"
              href={`${cssBaseURL}/${clientConfig.clientId}/landing/${interfaceName}.advanced.min.css`}
            /> */}

            <style>{`${clientConfig.basicStyleCSS}`}</style>
            <style>{`${clientConfig.advancedStyleCSS}`}</style>
            <script>{`${clientConfig.advancedStyleJS}`}</script>

            <link
              rel="canonical"
              href={`${clientConfig.landingPageBaseURL
                .replace("/local/", "")
                .replace("/pages/", "")}${window.location.pathname}`}
            />
            {clientConfig.faviconFileUrl ? (
              <link rel="icon" href={clientConfig.faviconFileUrl} />
            ) : (
              <link rel="icon" href={`${homepage}favicon.ico`} />
            )}
          </>
        </Helmet>
      )}
    </>
  );
};

export default ListOnly;
