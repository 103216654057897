import React from "react";
import BusinessHoursTable from "./businessHoursTable";
import { Accordion } from "react-bootstrap";
import AccordtionCustomToggle from "../accordionCustomToggle/accordionCustomToggle";

const BusinessHoursAccordion = ({ title, daysArray, titleClass }) => {
  return (
    <Accordion defaultActiveKey="1">
      <AccordtionCustomToggle eventKey="0">
        <span className={`${titleClass} businessHours__toggleContainer`}>
          {title}
        </span>
      </AccordtionCustomToggle>
      <Accordion.Collapse eventKey="0">
        <BusinessHoursTable daysArray={daysArray} />
      </Accordion.Collapse>
    </Accordion>
  );
};

export default BusinessHoursAccordion;
