import { FC, PropsWithChildren, useContext, useReducer } from "react";
import { SearchParameters } from "../../interfaces";
import { LocationsContext } from "../locations";
import { SearchContext2, searchReducer } from "./";

export interface SearchState {
  isLoading: boolean;
  searchParameters: SearchParameters;
}

const Search_INITIAL_STATE: SearchState = {
  isLoading: false,
  searchParameters: {
    postalCode: null,
    city: null,
    state: null,
    radius: 0,
    countryId: -1,
    latitude: null,
    longitude: null,
    keyword: null,
    categoryIDs: null,
    _reference: "A0",
  },
};

export const SearchProvider2: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, Search_INITIAL_STATE);

  const { setLocations } = useContext(LocationsContext);

  const doSearch = async () => {
    try {
      setLocations({
        DistanceIsMetric: true,
        FindNearestSearch: true,
        ResultList: [],
        SearchReqLogId: 666,
        TotalResults: 40,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setParameters = (parameters: SearchParameters) => {
    (window as any).searchFields = {
      ...parameters,
    };
    dispatch({ type: "[Search] - SetParameters", payload: parameters });
  };

  return (
    <SearchContext2.Provider value={{ ...state, doSearch, setParameters }}>
      {children}
    </SearchContext2.Provider>
  );
};
