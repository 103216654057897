import React, { useEffect, useState } from "react";
import Reviews from "../reviews/reviews";
import OverallRating from "../reviews/overallRating";
import {
  getDaysAgoFromToday,
  sortArrayYelpReviewsByDateDesc,
} from "../../utils/utils";
import { getYelpReviews } from "../../services/restLocationService";

const YelpReviews = ({
  title,
  reviewConfiguration,
  yelpPlaceID,
  returnHeaderOnly = false,
  showSmallHeader = false,
}) => {
  const [newReviews, setNewReviews] = useState([]);
  /** yelp reviews */
  useEffect(() => {
    const getYelpReviewsByLocationAsync = async () => {
      const reviews = await getYelpReviews(yelpPlaceID);

      if (reviews && reviews?.resultList?.length > 0) {
        let orderList = sortArrayYelpReviewsByDateDesc(reviews.resultList);
        const oderReviews = {
          rating: reviews.averageRating ?? 0,
          countReviews: reviews.totalReviews ?? 0,
          urlMoreReviews: reviews.yelpPlaceURL ?? "",
          reviews: orderList.map((x) => {
            return {
              author_name: x.reviewerName,
              rating: x.overallRating,
              relative_time_description: getDaysAgoFromToday(x.relativeTime),
              text: x.comments,
            };
          }),
        };
        setNewReviews(oderReviews);
      }
    };
    getYelpReviewsByLocationAsync();
  }, [yelpPlaceID]);
  return (
    <>
      <div id="bu-google-reviews" className="d-none"></div>
      {newReviews &&
        newReviews?.reviews?.length > 0 &&
        (!returnHeaderOnly ? (
          <Reviews
            title={title}
            listReviews={newReviews}
            reviewConfiguration={reviewConfiguration}
          />
        ) : (
          <OverallRating
            countReviews={newReviews.countReviews}
            rating={newReviews.rating}
            showOverallRating={true}
            smallReviews={showSmallHeader}
            urlMoreReviews={newReviews.urlMoreReviews}
            sourceTypeName={"Yelp"}
          />
        ))}
    </>
  );
};

export default YelpReviews;
