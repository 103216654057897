import React from "react";
import GenericGrid from "../genericGrid/genericGrid";

const GenericGridList = ({ containerClass, data, onMoreInfoClick }) => {
  console.log("GenericGridList: ", data);
  return (
    <>
      {data &&
        data.map((m, index) => {
          return (
            <GenericGrid
              key={index}
              data={m}
              onMoreInfoClick={onMoreInfoClick}
            ></GenericGrid>
          );
        })}
    </>
  );
};

export default GenericGridList;
