import { INPUTS_VALIDATIONS_PATTERNS } from "../../utils/constants";
/** Function to convert the JSON validation to Form validations  */
const ValidationsGenerator = (field) => {
  if (!field.validations) return null;
  const { validations } = field;

  let allValidation = {};
  let isNumber = false;

  /** Validation for email */
  if (field.isEmail)
    allValidation.pattern = {
      value: INPUTS_VALIDATIONS_PATTERNS.email,
      message: "invalid email address",
    };
  /** Validation for url */
  if (field.isUrl)
    allValidation.pattern = {
      value: INPUTS_VALIDATIONS_PATTERNS.url,
      message: "invalid url address",
    };

  /** required validation */
  if (field.required) allValidation.required = "Field is required";

  /** is number validation */
  if (field.isNumber) {
    allValidation.valueAsNumber = true;
    isNumber = true;
  }

  if (validations) {
    validations.forEach((item) => {
      let { rule, boolValue, errorMessage } = item;
      if (rule === "isRequired" && boolValue)
        allValidation.required = errorMessage;
      if (rule === "isUrl")
        allValidation.pattern = {
          value: INPUTS_VALIDATIONS_PATTERNS.url,
          message: errorMessage,
        };
      /** Validation for email */
      if (rule === "isEmail")
        allValidation.pattern = {
          value: INPUTS_VALIDATIONS_PATTERNS.email,
          message: errorMessage,
        };

      /** the minimum and maximum characters validation */
      if (!isNumber && rule === "minSize")
        allValidation.minLength = {
          value: item.minSize,
          message: errorMessage,
        };
      else if (!isNumber && rule === "maxSize")
        allValidation.maxLength = {
          value: item.maxSize,
          message: errorMessage,
        };
      /** the minimum or maximum value for number */ else if (
        isNumber &&
        rule === "min"
      )
        allValidation.min = {
          value: item.min,
          message: errorMessage,
        };
      else if (isNumber && rule === "max")
        allValidation.max = {
          value: item.maxSize,
          message: errorMessage,
        };
    });
  }
  return allValidation;
};

export default ValidationsGenerator;
