import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  Suspense,
} from "react";

/** Components */
import CardWithImage from "../cardWithImage";
import LocationInformation from "../locationInformation";
import SimpleListWithTitle from "../simpleListWithTitle";
import Slider from "../slider";
import SocialMediaIcons from "../socialMediaIcons";
import VideoPlayer from "../videoPlayer";
import Testimonials from "../testimonials";
import GoogleReviews from "../googleReviews";
import BullseyeReviews from "../bullseyeReviews";
import GoogleMap from "../googleMap";
import BusinessHours from "../businessHours";
import PhotoListWithTitle from "../photoListWithTitle";
import NearbyLocations from "../nearbyLocations";
import Showcase from "../showcase";
import MultiCarousel from "../multiCarousel";
import GalleryImages from "../galleryImages";
import CardWrapper from "../cardWrapper";
import Events from "../events";
import FormLead from "../formLead";
import SEO from "../seo";
import YelpReviews from "../yelpReviews";
import BreadcrumbMenu from "../breadcrumbMenu";
import BullseyeLoader from "../bullseyeLoader";
// import MapboxGL from "../mapboxGL/mapboxGL";
// import GoogleMapStatic from "../googleMapStatic/googleMapStatic";

/** Custom State Hooks */
import { useDoSearch2 } from "../../hooks/useDoSearch2";
import { useLocation } from "../../hooks/useLocation";

import { saveReview } from "../../services/restBullseyeReviews";
import {
  fixRelativePath,
  getAttributeValue,
  // getBreadcrumbItems,
  getEventLandingPageURL,
  getGEOBreadcrumbItems,
  getLandingPageURL,
} from "../../utils/bullseyeUtils";

import { documentIcon, getUrlParameterValue, NewId } from "../../utils/utils";
import {
  BULLSEYE_REVIEWS_STATUS,
  GEOCODING_SERVICE,
  REVIEW_TYPES,
  TESTIMONIAL_SUBATTRIBUTE,
  TYPE_SEARCH_BY,
} from "../../utils/constants";

import { useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { BullseyeContext } from "../../context/bullseyeContext";
import { useMutationResize } from "../../hooks/useMutationResize";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { LocationsContext } from "../../context/locations";

const registerUrl = process.env.REACT_APP_BUL_REGISTER_URL;

const MapboxGL = React.lazy(() => import("../mapboxGL"));

const LandingPage = ({
  landingPageConfiguration,
  apiKey: apiKeyConfiguration,
  crumbs,
}) => {
  const { location } = useParams(); // props.match.params.interfaceName;

  const bullseyeContext = useContext(BullseyeContext);
  bullseyeContext.landingPageConfiguration = {
    showViewPhoneNumber: landingPageConfiguration.showViewPhoneNumber,
  };

  const urlHostName = window.location.hostname;
  const urlParams = window.location.search;
  const locationTrans = location.replace("$d$", "-");

  const showMode = getUrlParameterValue("mode");
  const showBreadcrumb = getUrlParameterValue("breadcrumb");
  // https://electricvine.atlassian.net/browse/BUL-6429
  // const isIframe = getUrlParameterValue("f") === "1";
  const searchlogid = getUrlParameterValue("searchlogid");

  //new events
  const [newEventsState, setNewEvents] = useState([]);

  // about
  const [seoAbout, setSeoAbout] = useState({
    description: "",
    image: "",
  });

  // id to know how to do getLocation2 by ThirdPartyID or BullseyeID
  //if the configuration is search by locationId, set the locationValue
  //if the showMode=preview always search by locationId
  const locationValue =
    showMode === "preview" ||
    showMode === "previewLocalPage" ||
    landingPageConfiguration.locationIdentifierId ===
      TYPE_SEARCH_BY.bullseyeLocationID
      ? locationTrans
      : null;

  //if the configuration is search by thirdPartyId, set the thirdPartyIdValue
  const thirdPartyIdValue =
    landingPageConfiguration.locationIdentifierId ===
    TYPE_SEARCH_BY.thirdPartyID
      ? locationTrans
      : null;

  const [locationData] = useLocation(
    locationValue,
    thirdPartyIdValue,
    landingPageConfiguration.landingPageId
  );
  const bullseyeLocation = locationData.location;
  const [mainHeaderContainer, setMainHeaderContainer] = useState([]);
  const [mainBodyContainer, setMainBodyContainer] = useState([]);
  const [sideMenuContainer, setSideMenuContainer] = useState([]);
  const [googleMapIsReady, setGoogleMapIsReady] = useState(false);

  /** configuration for Nearby Locations */
  const [searchParameters, setSearchParameters] = useState({
    countryId: null,
    latitude: null,
    longitude: null,
    state: null,
    city: null,
    pageSize: 5,
    radius: 50,
    startIndex: 0,
  });

  const getBlockByType = useCallback(
    (blockType) => {
      const block = landingPageConfiguration.blocks.find(
        (x) => x.blockType === blockType
      );

      return block;
    },
    [landingPageConfiguration.blocks]
  );

  // Look for nearby locations
  useEffect(() => {
    if (
      bullseyeLocation.Latitude !== 0 &&
      bullseyeLocation.Longitude !== 0 &&
      getBlockByType(37) //nearby location module
    ) {
      setSearchParameters({
        countryId: 0,
        state: null,
        city: null,
        ready: true,
        latitude: bullseyeLocation.Latitude,
        longitude: bullseyeLocation.Longitude,
      });
    }
  }, [bullseyeLocation]);

  const [doSearch2State] = useDoSearch2(
    searchParameters,
    useCallback(() => {}, []),
    {
      pageSize: 4,
      radius: 500,
      startIndex: 0,
      configClientSearch: {
        clientId: landingPageConfiguration.clientId,
        apiKey: landingPageConfiguration.apiKey,
      },
    }
  );

  const { locations } = useContext(LocationsContext);

  const { Track, trackEvent } = useTracking({
    page: window.location.pathname,
    eventCategory: "LocalPages",
    searchReqLogId: searchlogid,
    locationId: bullseyeLocation.Id,
    locationName: bullseyeLocation.Name,
    googleAnalytic: landingPageConfiguration.googleAnalyticsCode,
  });

  let mapIconURL;

  if (
    landingPageConfiguration.interfaceIcons?.length === 1 &&
    landingPageConfiguration.interfaceIcons[0].InterfaceIconCategories
      .length === 0
  ) {
    mapIconURL = fixRelativePath(landingPageConfiguration.mapIconURL);
  }

  if (
    landingPageConfiguration.interfaceIcons &&
    landingPageConfiguration.interfaceIcons.length >= 1 &&
    bullseyeLocation.CategoryIds
  ) {
    // Array of current assigned categories
    const currentCategories = bullseyeLocation.CategoryIds.split(",");

    const categoryDictionary = landingPageConfiguration.interfaceIcons.find(
      (icon) => {
        return (
          icon.InterfaceIconCategories.length > 0 &&
          icon.InterfaceIconCategories.some((cat) =>
            currentCategories.includes(cat.toString())
          )
        );
      }
    );

    if (categoryDictionary) {
      mapIconURL = fixRelativePath(categoryDictionary.CustomMapIcon);
    }
  }

  const nearbyLocations = locations.filter((x) => x.id !== bullseyeLocation.Id);

  useEffect(() => {
    if (locationData.loading) return;

    const mainBodyContainer = [];
    const mainHeaderContainer = [];
    const sideMenuContainer = [];

    const socialMediaIcons = [];
    const twoColumnComponents = [];

    const directionsBlock = getBlockByType(5); // Directions
    const emailBlock = getBlockByType(6); // Email
    const websiteBlock = getBlockByType(7); // Website
    const phoneBlock = getBlockByType(8); // Phone
    const contactNameBlock = getBlockByType(30); // Contact Name
    const contactPositionBlock = getBlockByType(31); // Contact Position
    const bannerImageBlock = getBlockByType(26); // Banner image
    const logoImageBlock = getBlockByType(27); // Logo image
    let bannerImage;
    let logoImage;

    bullseyeContext.location = {
      address1: bullseyeLocation.Address1,
      city: bullseyeLocation.City,
      stateAbbr: bullseyeLocation.StateAbbreviation,
      postalCode: bullseyeLocation.PostCode,
      country: bullseyeLocation.CountryCode,
      locationName: bullseyeLocation.Name,
      phone: phoneBlock && bullseyeLocation.PhoneNumber,
      contactName: contactNameBlock && bullseyeLocation.ContactName,
      contactPosition: contactPositionBlock && bullseyeLocation.ContactPosition,
    };

    if (bannerImageBlock) {
      bannerImage = getAttributeValue(
        bullseyeLocation.Attributes,
        bannerImageBlock.referenceId,
        true
      );

      if (!bannerImage) {
        bannerImage = "/local/images/default-dark.png";
      }
    }

    if (logoImageBlock) {
      logoImage = getAttributeValue(
        bullseyeLocation.Attributes,
        logoImageBlock.referenceId,
        true
      );
    }

    mainHeaderContainer.push(
      <CardWrapper paddingClass="p-0 mt-0" key={1}>
        <Slider
          title={bullseyeLocation.Name}
          logo={logoImage}
          // backgroundImage={bannerImage}
          bannerImage={bannerImage}
          directions={
            directionsBlock &&
            !bullseyeLocation.InternetLocation &&
            `https://maps.google.com/maps?saddr=&daddr=${
              bullseyeLocation.Address1
            } ${bullseyeLocation.City} ${bullseyeLocation.StateAbbr || ""} ${
              bullseyeLocation.PostCode
            } ${bullseyeLocation.CountryCode || ""}`
          }
          email={emailBlock && bullseyeLocation.EmailAddress}
          website={websiteBlock && bullseyeLocation.URL}
        ></Slider>
      </CardWrapper>
    );

    landingPageConfiguration.blocks.sort((x, y) => x.order - y.order);

    if (
      bullseyeLocation.CategoryTree &&
      bullseyeLocation.CategoryTree.length > 0
    ) {
      mainBodyContainer.push(
        <input
          type="hidden"
          name="categoryTree"
          value={JSON.stringify(bullseyeLocation.CategoryTree)}
        />
      );
    }

    landingPageConfiguration.blocks.forEach((block) => {
      const container =
        block.position === "MainBodyContainer"
          ? mainBodyContainer
          : sideMenuContainer;

      switch (block.blockType) {
        case 2: // Location Name, 1 - Map,
          const mapCoordinates = [
            {
              id: bullseyeLocation.Id,
              locationName: bullseyeLocation.Name,
              latitude: bullseyeLocation.Latitude,
              city: bullseyeLocation.City,
              state: bullseyeLocation.StateAbbreviation,
              postalCode: bullseyeLocation.PostCode,
              longitude: bullseyeLocation.Longitude,
              country: bullseyeLocation.CountryCode,
              address1: bullseyeLocation.Address1,
            },
          ];
          const storeHoursBlock = getBlockByType(10); // Store Hours

          let showTwoColumns = block.customSetting === "col1";

          if (
            !storeHoursBlock ||
            bullseyeLocation.DailyHoursList.length === 0
          ) {
            showTwoColumns = false;
          }

          const mapBlock = getBlockByType(1); // map
          const locationInformationBlock = (
            <CardWrapper
              key={1}
              paddingClass={
                block.position === "MainBodyContainer"
                  ? "p-3 mr-0 mr-lg-2 flex-grow-1"
                  : ""
              }
            >
              <div
                className={
                  block.position !== "MainBodyContainer" || showTwoColumns
                    ? showTwoColumns
                      ? ""
                      : "p-3"
                    : "d-flex d-flex justify-content-between flex-column flex-md-row"
                }
              >
                {mapBlock &&
                  (!bullseyeLocation.InternetLocation ||
                    landingPageConfiguration.showInternetLocationsOnMap) && (
                    <div
                      className={
                        "mb-3 w-100 landingPage__mapContainer" +
                        (block.position === "MainBodyContainer"
                          ? showTwoColumns
                            ? " pr-0"
                            : " pr-0 pr-md-4"
                          : "")
                      }
                      style={
                        mapBlock.customSetting === "static"
                          ? { height: 320, width: 320 }
                          : { height: 320 }
                      }
                    >
                      {/* {mapBlock.customSetting === "static" && (
                      <GoogleMapStatic
                        markers={mapCoordinates}
                        googleApikey={googleMapKey}
                        customPin={landingPageConfiguration.mapIconURL}
                        sizeX={320}
                        sizeY={320}
                        onMapReady={() => setGoogleMapIsReady(true)}
                      />
                    )} 
                    
                    {mapBlock.customSetting === "dynamic" && (
                      // here was the google map
                    )}
                    */}
                      {apiKeyConfiguration.GeocodingServiceID ===
                        GEOCODING_SERVICE.BEGoogle ||
                      apiKeyConfiguration.GeocodingServiceID ===
                        GEOCODING_SERVICE.Google ? (
                        <GoogleMap
                          markers={mapCoordinates}
                          googleApikey={apiKeyConfiguration.APIKey}
                          showLandingPageLink={false}
                          onMapReady={() => setGoogleMapIsReady(true)}
                          customPin={mapIconURL}
                          hideControls={true}
                          showPopup={false}
                        />
                      ) : apiKeyConfiguration.GeocodingServiceID ===
                          GEOCODING_SERVICE.BEMapbox ||
                        apiKeyConfiguration.GeocodingServiceID ===
                          GEOCODING_SERVICE.Mapbox ? (
                        <Suspense fallback={<div>Loading ... </div>}>
                          <MapboxGL
                            features={[
                              {
                                type: "Feature",
                                properties: {
                                  name: mapCoordinates[0].Name,
                                  address1: mapCoordinates[0].Address1,
                                  city: mapCoordinates[0].City,
                                  stateAbbr: mapCoordinates[0].State,
                                  postalCode: mapCoordinates[0].PostCode,
                                  country: mapCoordinates[0].CountryName,
                                  id: mapCoordinates[0].Id,
                                  iconSize: [23, 35],
                                  icon: mapIconURL,
                                },
                                geometry: {
                                  type: "Point",
                                  coordinates: [
                                    mapCoordinates[0].longitude,
                                    mapCoordinates[0].latitude,
                                  ],
                                },
                              },
                            ]}
                            mapboxToken={apiKeyConfiguration.Token}
                            showLandingPageLink={false}
                            showPopup={false}
                          />
                        </Suspense>
                      ) : (
                        <div>Map Setting Missing</div>
                      )}
                    </div>
                  )}
                <div
                  className="landingPage__locInfoContainer"
                  style={{ minWidth: "40%" }}
                >
                  <LocationInformation
                    locationId={bullseyeLocation.Id}
                    address1={bullseyeLocation.Address1}
                    city={bullseyeLocation.City}
                    stateAbbr={bullseyeLocation.StateAbbreviation}
                    postalCode={bullseyeLocation.PostCode}
                    country={bullseyeLocation.CountryCode}
                    locationName={bullseyeLocation.Name}
                    phone={phoneBlock && bullseyeLocation.PhoneNumber}
                    contactName={
                      contactNameBlock && bullseyeLocation.ContactName
                    }
                    contactPosition={
                      contactPositionBlock && bullseyeLocation.ContactPosition
                    }
                    showViewPhoneNumber={
                      phoneBlock && landingPageConfiguration.showViewPhoneNumber
                    }
                    claimThisListingUrl={
                      landingPageConfiguration.showClaimLocationLink
                        ? registerUrl
                            .replace("{0}", landingPageConfiguration.subdomain)
                            .replace("{1}", landingPageConfiguration.clientId) +
                          "&action=claim&locId=" +
                          bullseyeLocation.Id
                        : null
                    }
                  />
                </div>
              </div>
            </CardWrapper>
          );
          if (showTwoColumns) {
            twoColumnComponents.push(locationInformationBlock);
          } else {
            container.push(locationInformationBlock);
          }
          break;
        case 10: // Store Hours
          // todo implement David code to fix the open now and close now right nos is hard coding
          if (bullseyeLocation.DailyHoursList.length > 0) {
            const isClose =
              bullseyeLocation.OpenCloseStatus.toLowerCase() === "closed";

            const businessHours = (
              <CardWrapper
                key={10}
                paddingClass={
                  block.customSetting === "col2" ? "p-3 flex-grow-1" : "p-3"
                }
              >
                <BusinessHours
                  daysArray={bullseyeLocation.DailyHoursList.map((hour) => {
                    return {
                      day: hour.NameOfDay,
                      hours: hour.NoHoursAvailable
                        ? "Closed"
                        : hour.HoursDisplayText,
                    };
                  })}
                  isClose={isClose}
                  openCloseLabel={bullseyeLocation.OpenCloseStatus}
                  title={block.title}
                />
              </CardWrapper>
            );

            if (block.customSetting === "col2") {
              twoColumnComponents.push(businessHours);

              container.push(
                <div className="d-flex flex-wrap">
                  {twoColumnComponents.map((component) => {
                    return component;
                  })}
                </div>
              );
            } else {
              container.push(businessHours);
            }
          }
          break;
        case 11: // get the lead form Configuration
          if (bullseyeLocation.IsLeadManager) {
            container.push(
              <CardWrapper key={block.referenceId}>
                <FormLead
                  compact={false}
                  formId={block.referenceId}
                  locationIds={[bullseyeLocation.Id]}
                  title={block.title}
                  GoogleCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  clientId={landingPageConfiguration.clientId}
                  apiKey={landingPageConfiguration.apiKey}
                  hasContainerSetted={
                    landingPageConfiguration?.containerWidth > 0 ? true : false
                  }
                />
              </CardWrapper>
            );
          }
          break;
        case 12: // CustomHTML
          if (block.customHtml) {
            container.push(
              <CardWrapper key={12}>
                <div dangerouslySetInnerHTML={{ __html: block.customHtml }} />
              </CardWrapper>
            );
          }
          break;
        case 13: // Category Group
          const categoryTree = bullseyeLocation.CategoryTree.filter(
            (cat) => cat.ID === block.referenceId
          );

          if (
            categoryTree.length > 0 &&
            categoryTree[0].SubCategories.length > 0
          ) {
            container.push(
              <CardWrapper key={block.referenceId}>
                <SimpleListWithTitle
                  title={block.title}
                  items={categoryTree[0].SubCategories.map((cat) => {
                    return { id: cat.ID, title: cat.Name };
                  })}
                  numberOfColumns={1}
                />
              </CardWrapper>
            );
          }

          break;
        case 14: // events
          const eventsBlock = getBlockByType(14);

          if (
            bullseyeLocation.Events &&
            eventsBlock &&
            bullseyeLocation.Events.length > 0
          ) {
            let newEvents = [];

            bullseyeLocation.Events.forEach((eventItem) => {
              eventItem.urlEvent =
                showMode && showMode.toLocaleLowerCase() === "previewlocalpage"
                  ? null
                  : getEventLandingPageURL(
                      eventItem,
                      window.location.pathname,
                      bullseyeLocation.Id
                    );
              newEvents.push(eventItem);
            });
            setNewEvents(newEvents);
            container.push(
              <CardWrapper key={14}>
                <Events
                  title={eventsBlock.title}
                  eventList={newEvents}
                  maxItemsToDisplay={3}
                />
              </CardWrapper>
            );
          }
          break;
        case 15: // Complex Attributes
          // not in use
          break;
        case 16: // Benefits
          if (landingPageConfiguration.benefits?.length > 0) {
            container.push(
              <CardWrapper key={16}>
                <Showcase
                  title={block.title}
                  maxItemsToDisplay={3}
                  items={landingPageConfiguration.benefits}
                />
              </CardWrapper>
            );
          }
          break;
        case 17: // Gallery photos / Landing Page Photos
          // TODO: We need to support alternative text and not hard coding
          if (
            block.customSetting === "carousel" &&
            bullseyeLocation.LandingPageImages.length > 0
          ) {
            container.push(
              <CardWrapper key={17}>
                <MultiCarousel
                  photos={bullseyeLocation.LandingPageImages.map((photo) => {
                    return {
                      imageURL: photo,
                      id: NewId(),
                      alternativeText: "Bullseye Photo",
                    };
                  })}
                  title={block.title}
                  itemNumber={1}
                  containerExtraClass="m-2"
                />
              </CardWrapper>
            );
          }
          if (
            block.customSetting === "masonry" &&
            bullseyeLocation.LandingPageImages.length > 0
          ) {
            container.push(
              <CardWrapper key={171}>
                <GalleryImages
                  title="Gallery"
                  photos={bullseyeLocation.LandingPageImages.map((photo) => {
                    return {
                      imageURL: photo,
                      id: NewId(),
                      alternativeText: "some photo",
                    };
                  })}
                  columns={4}
                  direction="column"
                />
              </CardWrapper>
            );
          }
          break;
        case 18: // Promotions
          if (bullseyeLocation.Coupons.length > 0) {
            container.push(
              <CardWrapper key={18}>
                <PhotoListWithTitle
                  title={block.title}
                  items={bullseyeLocation.Coupons.map((coupon) => {
                    const couponUrlParams = urlParams ? urlParams : "?";

                    return {
                      id: coupon.CouponId,
                      image: coupon.ThumbnailImage,
                      body: coupon.CouponText,
                      url: coupon.PromoPageURL
                        ? coupon.PromoPageURL
                        : `//${urlHostName}/pages/coupons/${landingPageConfiguration.interfaceName}${couponUrlParams}&locid=${bullseyeLocation.Id}`,
                      inNewTab: true,
                    };
                  })}
                  maxItemsToDisplay={3}
                />
              </CardWrapper>
            );
          }
          break;
        case 19: // Reviews
          if (
            landingPageConfiguration.reviewConfiguration.reviewType ===
              REVIEW_TYPES.google &&
            bullseyeLocation.GooglePlaceID
          ) {
            // TODO: we need to validate if the api for google is loaded see how in ecoatm
            container.push((googleMapIsReady) => (
              <CardWrapper key={19}>
                <GoogleReviews
                  googleScriptsReady={googleMapIsReady}
                  title={block.title}
                  GoogleplaceId={bullseyeLocation.GooglePlaceID}
                />
              </CardWrapper>
            ));
          }

          if (
            landingPageConfiguration.reviewConfiguration.reviewType ===
              REVIEW_TYPES.bullseye &&
            landingPageConfiguration.showReviews &&
            bullseyeLocation.AllowBEReview
          ) {
            const onSubmitBullseyeReviews = (data) => {
              data.LocationId = bullseyeLocation.Id;

              // transform yesno question to bool
              data.Recommend = data.Recommend === "Yes" ? true : false;

              const dataForSubmit = {
                ClientId: landingPageConfiguration.clientId,
                ApiKey: landingPageConfiguration.apiKey,
                NewReview: data,
              };
              saveReview(dataForSubmit);
            };
            let extraTitle = "";
            if (bullseyeLocation.Address1)
              extraTitle = bullseyeLocation.Address1;
            if (bullseyeLocation.City)
              extraTitle += ` ${bullseyeLocation.City}`;
            if (
              extraTitle &&
              (bullseyeLocation.Address1 || bullseyeLocation.City)
            )
              extraTitle += ",";
            if (bullseyeLocation.StateAbbreviation)
              extraTitle += ` ${bullseyeLocation.StateAbbreviation}`;
            if (bullseyeLocation.PostCode)
              extraTitle += ` ${bullseyeLocation.PostCode}`;

            container.push(
              <CardWrapper key={191}>
                <BullseyeReviews
                  title={block.title}
                  subTitle={bullseyeLocation.Name}
                  extraTitle={extraTitle}
                  reviews={bullseyeLocation.BullseyeReviews.filter(
                    (x) => x.StatusID === BULLSEYE_REVIEWS_STATUS.Approved
                  )}
                  onSubmit={onSubmitBullseyeReviews}
                  reviewConfiguration={
                    landingPageConfiguration.reviewConfiguration
                  }
                  submitReviews={landingPageConfiguration.submitReviews}
                />
              </CardWrapper>
            );
          }
          if (
            landingPageConfiguration.reviewConfiguration.reviewType ===
              REVIEW_TYPES.yelp &&
            bullseyeLocation.YelpPlaceID
          ) {
            container.push(
              <CardWrapper key={block.referenceId}>
                <YelpReviews
                  title={block.title}
                  yelpPlaceID={bullseyeLocation.YelpPlaceID}
                  reviewConfiguration={
                    landingPageConfiguration.reviewConfiguration
                  }
                />
              </CardWrapper>
            );
          }
          break;
        case 20: // Facebook
          const facebook = bullseyeLocation.SocialMedia.find(
            (x) => x.name === "facebook"
          );

          if (facebook) {
            socialMediaIcons.push(facebook);
          }
          break;
        case 21: // Twitter
          const twitter = bullseyeLocation.SocialMedia.find(
            (x) => x.name === "twitter"
          );
          if (twitter) {
            socialMediaIcons.push(twitter);
          }

          break;
        case 22: // LinkedIn
          const linkedin = bullseyeLocation.SocialMedia.find(
            (x) => x.name === "linkedin"
          );
          if (linkedin) {
            socialMediaIcons.push(linkedin);
          }

          break;
        case 23: // Instagram
          const instagram = bullseyeLocation.SocialMedia.find(
            (x) => x.name === "instagram"
          );
          if (instagram) {
            socialMediaIcons.push(instagram);
          }

          break;
        case 24: // Pinterest
          const pinterest = bullseyeLocation.SocialMedia.find(
            (x) => x.name === "pinterest"
          );
          if (pinterest) {
            socialMediaIcons.push(pinterest);
          }
          break;
        case 25: // Yelp
          const yelp = bullseyeLocation.SocialMedia.find(
            (x) => x.name === "yelp"
          );
          if (yelp) {
            socialMediaIcons.push(yelp);
          }

          break;
        case 28: // ShowcaseContent
          // not in use
          break;
        case 32: // About
          const aboutAttribute = getAttributeValue(
            bullseyeLocation.Attributes,
            block.referenceId
          );
          const profileImage = getBlockByType(9); // Logo image

          if (aboutAttribute) {
            setSeoAbout({
              description: aboutAttribute,
              image: profileImage ? bullseyeLocation.ImageFileUrl : null,
            });
            container.push(
              <CardWrapper key={32}>
                <CardWithImage
                  body={aboutAttribute}
                  title={"About " + bullseyeLocation.Name}
                  image={profileImage ? bullseyeLocation.ImageFileUrl : null}
                />
              </CardWrapper>
            );
          }
          break;
        case 33: // Social Media
          if (socialMediaIcons.length > 0) {
            container.push(
              <CardWrapper key={33}>
                <SocialMediaIcons icons={socialMediaIcons} />
              </CardWrapper>
            );
          }
          break;
        case 34: // documents
          const documents = bullseyeLocation.Attributes.filter((attr) =>
            block.customSetting.includes(attr.AttributeId)
          );

          if (documents.length > 0) {
            container.push(
              <CardWrapper key={34}>
                <SimpleListWithTitle
                  title="Documents"
                  items={documents.map((doc) => {
                    return {
                      id: doc.AttributeId,
                      title: doc.AttributeDisplayText,
                      url: fixRelativePath(doc.AttributeValue),
                    };
                  })}
                  icon={documentIcon}
                />
              </CardWrapper>
            );
          }
          break;
        case 35: // Testimonials
          const testimonials = [];

          const testimonialsComplexAttr =
            bullseyeLocation.ComplexAttributes.filter(
              (attr) => attr.AttributeId === block.referenceId
            );

          testimonialsComplexAttr.forEach((testimonialInstance) => {
            // TODO: we can't do this, we need to find the correct attribute for description and author
            // for test we will assume right now the position [0] will be always the description
            testimonials.push({
              id: NewId(),
              description: testimonialInstance.SubAttributes.find(
                (t) =>
                  t.AttributeName.toLowerCase() ===
                  TESTIMONIAL_SUBATTRIBUTE.testimonial
              )?.AttributeValue,
              author: testimonialInstance.SubAttributes.find(
                (t) =>
                  t.AttributeName.toLowerCase() ===
                  TESTIMONIAL_SUBATTRIBUTE.citation
              )?.AttributeValue,
            });
          });
          if (testimonials.length > 0) {
            container.push(
              <CardWrapper key={35}>
                <Testimonials title={block.title} testimonials={testimonials} />
              </CardWrapper>
            );
          }
          break;
        case 36: // Videos
          const videos = [];

          const videosComplexAttr = bullseyeLocation.ComplexAttributes.filter(
            (attr) => attr.AttributeId === block.referenceId
          );

          videosComplexAttr.forEach((videoInstance) => {
            // This should not happen but we have some cases
            if (videoInstance.SubAttributes.length === 0) return;

            // TODO: we can't do this, we need to find the correct attribute for description and author
            // for test we will assume right now the position [0] will be always the description
            videos.push({
              videoUrl: videoInstance.SubAttributes[0].AttributeValue,
              previewImage: fixRelativePath(
                videoInstance.SubAttributes[0].AttributeDisplayImage
              ),
            });
          });
          if (videos.length > 0) {
            container.push(
              <CardWrapper key={36}>
                <div key={NewId()} style={{ height: 450 * videos.length }}>
                  <div className="bullseye-red h4">{block.title}</div>
                  {videos.map((video, index) => (
                    <div key={NewId()} style={{ height: 430 }}>
                      <VideoPlayer
                        title=""
                        videoList={video.videoUrl}
                        light={video.previewImage ?? false}
                        autoPlaying={video.previewImage ? true : false}
                      />
                    </div>
                  ))}
                </div>
              </CardWrapper>
            );
          }
          break;
        case 37: // Nearby Locations
          if (!bullseyeLocation.InternetLocation) {
            container.push((nearbyLocations) => (
              <CardWrapper key={37}>
                <NearbyLocations
                  title={block.title}
                  nearbyLocationsList={nearbyLocations}
                  customPin={null}
                />
              </CardWrapper>
            ));
          }
          break;
        default:
          break;
      }
    });

    setMainHeaderContainer(mainHeaderContainer);
    setMainBodyContainer(mainBodyContainer);
    setSideMenuContainer(sideMenuContainer);
  }, [
    bullseyeLocation,
    landingPageConfiguration,
    apiKeyConfiguration,
    getBlockByType,
    locationData.loading,
  ]);

  useEffect(() => {
    /** Google Analytics Universal */
    trackEvent({
      event: "pageview",
    });
    // ReactGA.initialize("UA-214494786-1", { debug: true });
    // ReactGA.pageview(window.location.pathname);
  }, []);

  const landingPageContainer = useRef();
  useMutationResize(landingPageContainer.current);

  const getComponentToMapDisplay = (el) => {
    if (typeof el === "function") {
      if (
        el.toString().includes("nearbyLocations") &&
        nearbyLocations.length > 0
      ) {
        return el(nearbyLocations);
      }
      if (el.toString().includes("googleScriptsReady"))
        return el(googleMapIsReady);
    } else {
      return el;
    }
  };

  return locationData.loading ? (
    <BullseyeLoader />
  ) : (
    <Track>
      <div
        ref={landingPageContainer}
        className={`container-fluid`}
        style={{ maxWidth: landingPageConfiguration.containerWidth }}
      >
        <SEO
          seoTitle={bullseyeLocation.SEO.SEOTitle}
          seoAbout={seoAbout}
          seoMetaDescription={
            bullseyeLocation.SEO.MetaDescription
              ? bullseyeLocation.SEO.MetaDescription
              : bullseyeLocation.Name
          }
          seoLocation={bullseyeLocation}
          seoEvents={newEventsState}
          seoCanonical={getLandingPageURL(
            landingPageConfiguration.baseURL,
            window.location.href
          )}
        />
        {landingPageConfiguration.enableCustomHeaderFooter &&
          // !isIframe &&
          landingPageConfiguration.headerCode && (
            <div className="row">
              <div
                className="col-12 mb-1 mt-2 localPage__header"
                dangerouslySetInnerHTML={{
                  __html: landingPageConfiguration.headerCode,
                }}
              />
            </div>
          )}
        {navigator.userAgent !== "ReactSnap" && showBreadcrumb !== "false" && (
          <div className="card mb-n3 mt-2">
            <BreadcrumbMenu
              items={getGEOBreadcrumbItems(
                crumbs,
                bullseyeLocation.Name,
                landingPageConfiguration.interfaceTypeId,
                landingPageConfiguration.breadcrumbText,
                "LocationLandingPage",
                landingPageConfiguration.showInterfaceLocationListOnly,
                landingPageConfiguration.cityCollectionId,
                bullseyeLocation.CountryCode,
                bullseyeLocation.StateAbbreviation,
                bullseyeLocation.City,
                landingPageConfiguration.interfaceName,
                bullseyeLocation.InternetLocation,
                landingPageConfiguration.hostDomain,
                landingPageConfiguration.isIframeEmbed,
                null,
                showMode
              )}
            />

            {/* <BreadcrumbMenu
              items={getBreadcrumbItems(
                crumbs,
                bullseyeLocation.Name,
                landingPageConfiguration.interfaceTypeId,
                landingPageConfiguration.clientTerm.DefaultStoreLocator,
                null,
                null,
                landingPageConfiguration.cityCollectionId
              )}
            /> */}
          </div>
        )}
        <div className="row">
          <div className="col-12 headerContainer__container">
            {mainHeaderContainer.map((el) => el)}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-5 col-lg-4 sideMenuContainer__container">
            {sideMenuContainer.map((el) => {
              return getComponentToMapDisplay(el);
            })}
          </div>
          <div className="col-12 col-md-7 col-lg-8 mainBodyContainer__container">
            {mainBodyContainer.map((el) => {
              return getComponentToMapDisplay(el);
            })}
          </div>
        </div>
        {landingPageConfiguration.enableCustomHeaderFooter &&
          // !isIframe &&
          landingPageConfiguration.footerCode && (
            <div className="row">
              <div
                className="col-12 mb-1 mt-2  localPage__footer"
                dangerouslySetInnerHTML={{
                  __html: landingPageConfiguration.footerCode,
                }}
              />
            </div>
          )}
      </div>

      <Helmet>
        <script>{`if (window.landingPageLoaded) {console.log('event: landingPageLoaded'); window.landingPageLoaded();}`}</script>
      </Helmet>
    </Track>
  );
};

export default LandingPage;
