import React, { Component } from "react";
import { fixRelativePath } from "../../utils/bullseyeUtils";
import AccordtionCustomToggle from "../accordionCustomToggle/accordionCustomToggle";
import { Accordion } from "react-bootstrap";
import GenericGrid from "../genericGrid/genericGrid";
import GenericGridList from "../genericGridList/genericGridList";

const ComplexGridAttributeAccordion = ({
  title,
  titleClass,
  complexAttribute,
  customPin,
  onMoreInfoClick,
}) => {
  return (
    <Accordion defaultActiveKey="1">
      <AccordtionCustomToggle eventKey="0">
        {customPin && (
          <img
            className="mr-2"
            style={{ height: 20, verticalAlign: "middle" }}
            src={customPin}
            alt="icon"
          />
        )}
        <span className={titleClass}>{title}</span>
      </AccordtionCustomToggle>
      <Accordion.Collapse eventKey="0">
        <GenericGridList
          data={complexAttribute}
          onMoreInfoClick={onMoreInfoClick}
        ></GenericGridList>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ComplexGridAttributeAccordion;
