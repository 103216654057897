import React from "react";

const Separator = ({ label }) => {
  return (
    <div className="d-block">
      <strong>{label}</strong>
      <hr className="mt-2 mb-3" />
    </div>
  );
};
export default Separator;
