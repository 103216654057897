import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import mapboxGeocode from "../utils/mapbox-geocode";
import { GEOCODE_SERVICES } from "../utils/constants";

export function useUniversalGeocoder(
  address,
  latitude,
  longitude,
  service,
  servicekey,
  region,
  language,
  hasError
) {
  const [currentLocation, setCurrentLocation] = useState({
    origin_formatted_address: null,
    latitude: 0,
    longitude: 0,
    formatted_address: null,
    country: null,
    geocode_success: false,
    loading: true,
    city: null,
    state: null,
  });

  const fetchGoogleGeocoding = async () => {
    // set Google Maps Geocoding API for purposes of quota management.
    Geocode.setApiKey(servicekey);

    // set response language. Defaults to english.
    Geocode.setLanguage(language || "en");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    if (region) {
      Geocode.setRegion(region);
    }

    // set location_type filter . Its optional.
    // google geocoder returns more that one address for given lat/lng.
    // In some case we need one address as response for which google itself provides a location_type filter.
    // So we can easily parse the result for fetching address components
    // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
    // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
    // Geocode.setLocationType("APPROXIMATE");

    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    try {
      const { results } = await Geocode.fromLatLng(latitude, longitude);

      const address = results[0].formatted_address;
      let city, state, country;
      for (let i = 0; i < results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < results[0].address_components[i].types.length;
          j++
        ) {
          switch (results[0].address_components[i].types[j]) {
            case "locality":
              city = results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = results[0].address_components[i].long_name;
              break;
            case "country":
              country = results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }

      setCurrentLocation({
        origin_formatted_address: address,
        latitude: results[0].geometry.location.lat,
        longitude: results[0].geometry.location.lng,
        formatted_address: city || state ? city + ", " + state : null,
        country: country,
        city: city,
        state: state,
        geocode_success: true,
        loading: false,
      });
    } catch (error) {
      setCurrentLocation({
        ...currentLocation,
        geocode_success: false,
        loading: false,
      });
      console.error(error);
    }
  };

  const fetchMapboxGeocodingLatLongAsync = async () => {
    mapboxGeocode.setApiKey(servicekey);
    mapboxGeocode.enableDebug = true;
    try {
      const response = await mapboxGeocode.fromLatLng(latitude, longitude);
      let city;
      let state;
      let country;

      if (response.features.length > 0) {
        response?.features[0].context.forEach((item) => {
          if (item.id.indexOf("place") >= 0) {
            city = item.text;
          }
          if (item.id.indexOf("region") >= 0) {
            state = item.text;
          }

          if (item.id.indexOf("country") >= 0) {
            country = item.text;
          }
        });

        setCurrentLocation({
          origin_formatted_address: address,
          latitude: response.features[0].geometry.coordinates[1],
          longitude: response.features[0].geometry.coordinates[0],
          formatted_address: city || state ? city + ", " + state : null,
          country: country,
          city: city,
          state: state,
          geocode_success: true,
          loading: false,
        });
      } else {
        setCurrentLocation({
          ...currentLocation,
          geocode_success: false,
          loading: false,
        });
      }
    } catch (error) {
      setCurrentLocation({
        ...currentLocation,
        geocode_success: false,
        loading: false,
      });
      console.error(error);
    }
  };

  const fetchMapboxGeocodingAddressAsync = async (
    servicekey,
    address,
    region
  ) => {
    mapboxGeocode.setApiKey(servicekey);
    mapboxGeocode.setRegion(region);
    // mapboxGeocode.enableDebug = false;

    try {
      const response = await mapboxGeocode.fromAdress(address);
      let city;
      let state;
      let country;

      if (response.features.length > 0) {
        response?.features[0].context.forEach((item) => {
          if (item.id.indexOf("place") >= 0) {
            city = item.text;
          }
          if (item.id.indexOf("region") >= 0) {
            state = item.text;
          }

          if (item.id.indexOf("country") >= 0) {
            country = item.text;
          }
        });

        setCurrentLocation({
          origin_formatted_address: address,
          latitude: response.features[0].geometry.coordinates[1],
          longitude: response.features[0].geometry.coordinates[0],
          formatted_address: city || state ? city + ", " + state : null,
          country: country,
          city: city,
          state: state,
          geocode_success: true,
          loading: false,
        });
      } else {
        setCurrentLocation({
          ...currentLocation,
          geocode_success: false,
          loading: false,
        });
      }
    } catch (error) {
      setCurrentLocation({
        ...currentLocation,
        geocode_success: false,
        loading: false,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    const validGeoPosition =
      latitude && longitude && latitude !== 0 && longitude !== 0;
    if ((!address && !validGeoPosition) || !service || !servicekey) {
      setCurrentLocation({
        ...currentLocation,
        geocode_success: false,
        loading: false,
      });
      return;
    }

    switch (service) {
      case GEOCODE_SERVICES.google:
      case GEOCODE_SERVICES.be_google:
        fetchGoogleGeocoding();

        break;

      case GEOCODE_SERVICES.mapbox:
      case GEOCODE_SERVICES.be_mapbox:
        // pending
        if (!address) {
          fetchMapboxGeocodingLatLongAsync();
        } else {
          //try address geocoding
          fetchMapboxGeocodingAddressAsync(servicekey, address, region);
        }
        //

        break;

      default:
        // let me think
        break;
    }
  }, [latitude, longitude, service, servicekey, region, language, hasError]);

  return currentLocation;
}
