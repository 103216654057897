import React from "react";
import BusinessHoursTable from "./businessHoursTable";

const BusinessHoursExpanded = ({
  title,
  daysArray,
  smallAlert,
  isClose,
  alertText,
}) => {
  let colorTextByStatus = isClose ? "text-danger" : "text-success";

  if (alertText && alertText.toLowerCase().includes("soon")) {
    colorTextByStatus = "text-warning";
  }
  return (
    <div className="businessHoursExpanded__container">
      <div className="bullseye-red h4 businessHoursExpanded__title">
        {title}
      </div>
      <small>{smallAlert}</small>
      <div
        className="alert alert-secondary my-1 businessHours__alertContainer"
        role="alert"
      >
        <span className={`font-weight-bold ${colorTextByStatus}`}>
          {alertText}
        </span>
      </div>
      <BusinessHoursTable daysArray={daysArray} />
    </div>
  );
};

export default BusinessHoursExpanded;
