import React from "react";
import PropTypes from "prop-types";
import { NewId } from "../../utils/utils";

const BusinessHoursTable = ({ daysArray }) => {
  return (
    <table className="w-100 m-2 businessHoursTable__table">
      <tbody>
        {daysArray.map((dayInfo) => {
          return (
            <tr key={NewId()}>
              <th scope="row" className="bl-text-bold-500">
                {dayInfo.day}
              </th>
              <td>{dayInfo.hours}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

BusinessHoursTable.propTypes = {
  /** Days of the week table*/
  daysArray: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the day of the week */
      day: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

      /** hours in format 08:00 AM - 05:00 PM*/
      hours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    })
  ).isRequired,
};

export default BusinessHoursTable;
