import http from "./httpService";

const endpointPath = "RestLocation.svc";
const locationPostMethod = "AddLocation";
const getYelpReviewsMethod = "Review/GetYelpReviews";

function getAddLocationConfigurationURL() {
  return `${endpointPath}/${locationPostMethod}`;
}
function getYelpReviewsURL(yelpPlaceID) {
  return `${process.env.REACT_APP_URL_WRAPPER}/${getYelpReviewsMethod}?placeId=${yelpPlaceID}`;
}
export async function addLocation(dataPost) {
  http.setSecurityParameters();

  const location = await http.post(getAddLocationConfigurationURL(), dataPost);

  return location.data;
}
export async function getYelpReviews(yelpPlaceID) {
  const location = await http.get(getYelpReviewsURL(yelpPlaceID), {
    params: null,
  });

  return location.data;
}
