import React from "react";
import PropTypes from "prop-types";

import OptionInput from "./optionInput";
import Label from "../label";
import ErrorDisplay from "../errorDisplay/errorDisplay";

const MultiOptionsInput = ({
  label,
  name,
  options,
  required = false,
  register,
  errors,
  type = "radio",
  inline = false,
  extraCssClass,
}) => {
  const inlineStyles = inline
    ? "w-100 form-check form-check-inline"
    : "d-flex flex-column";
  return (
    <fieldset
      className={"form-group " + extraCssClass}
      id={`attr_container_${name}`}
    >
      <Label
        label={label}
        htmlFor={name}
        required={required}
        id={`label_${name}`}
      />
      <div className={inlineStyles} aria-labelledby={`label_${name}`}>
        {options &&
          options.map((item, index) => (
            <OptionInput
              key={index}
              id={item.name}
              type={type === "radio" ? "radio" : "checkbox"}
              name={name}
              register={register}
              value={item.id}
              label={item.name}
              required={required}
            />
          ))}
      </div>
      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </fieldset>
  );
};

MultiOptionsInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,
  /** options array with the options to select */
  options: PropTypes.array.isRequired,
  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,

  /** type of the multiOption Radio or CheckBox */
  type: PropTypes.string.isRequired,
};

export default MultiOptionsInput;
