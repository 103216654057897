import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { ToastContainer } from "react-toastify";

import { LogSearchRsltwReq } from "./services/restSearchService";
import track from "react-tracking";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import { SEARCH_RES_LOG_TYPE_DESC } from "./utils/constants";
import "react-toastify/dist/ReactToastify.css";

import { getGoogleAnalyticEventName } from "./utils/constants";
import { SearchProvider } from "./context/searchContext";
import { LanguageProvider } from "./context/language";
import { routes } from "./routes";
import { SearchProvider2 } from "./context/search";
import { LocationsProvider } from "./context/locations";

function App2() {
  console.log("app start version 8.50.2...");

  function placeParams(pathRegex, params) {
    let segments = pathRegex.split("/");

    let finalValue = segments
      .map((segment) => {
        let offset = segment.indexOf(":") + 1;

        if (!offset) return segment;

        let key = segment.slice(offset);

        if (key.includes("slug")) {
          let slugSegment = key.split("-");
          let finalSlug = "";
          slugSegment.forEach((item) => {
            if (!finalSlug) {
              finalSlug += params[item.slice(item.indexOf(":") + 1)] + "-";
            } else {
              finalSlug += params[item.slice(item.indexOf(":") + 1)];
            }
          });
          return finalSlug;
        }
        return params[key];
      })
      .join("/");

    return finalValue;
  }

  return (
    <>
      <ToastContainer />
      <Switch>
        {routes.map(
          ({ path, Component, useSearchProvider, cleanPath }, key) => (
            <Route
              key={key}
              path={path}
              render={(props) => {
                const crumbs = routes
                  .filter(({ canonical }) =>
                    props.match.path.includes(canonical)
                  )
                  .map(({ path, cleanPath, ...rest }) => ({
                    path: placeParams(cleanPath, props.match.params),
                    ...rest,
                  }));

                if (useSearchProvider) {
                  return (
                    <LanguageProvider>
                      <SearchProvider>
                        <LocationsProvider>
                          <SearchProvider2>
                            <Component {...props} crumbs={crumbs} />
                          </SearchProvider2>
                        </LocationsProvider>
                      </SearchProvider>
                    </LanguageProvider>
                  );
                } else {
                  return <Component {...props} crumbs={crumbs} />;
                }
              }}
            ></Route>
          )
        )}
      </Switch>
    </>
  );
}

const recordGAEvent = (data) => {
  switch (data.event) {
    case "pageview":
      ReactGA.pageview(data.page);
      break;
    case "LocationSearchResult":
      ReactGA.event({
        category: data.app + " - " + data.eventCategory,
        action:
          data.eventNameGA ??
          getGoogleAnalyticEventName(
            data.responseType ?? data.genericResponseType,
            data.eventValue
          ),
        label: data.additionalInformation
          ? data.locationName + " " + data.additionalInformation
          : data.locationName,
      });
      break;
    case "click":
      ReactGA.event({
        category: data.app + " - " + data.eventCategory,
        action:
          data.eventNameGA ??
          getGoogleAnalyticEventName(
            data.responseType ?? data.genericResponseType,
            data.eventValue
          ),
        label: data.additionalInformation
          ? data.locationName + " " + data.additionalInformation
          : data.locationName,
      });
      break;
    default:
      break;
  }
};

export default track(
  { app: "Bullseye Locations" },
  {
    dispatch: async (data) => {
      if (navigator.userAgent === "ReactSnap") {
        return;
      }

      if (window.bullseyeLocationsConfig?.captureCustomEvents) {
        window.bullseyeLocationsConfig?.captureCustomEvents(data);
      }

      // Google Analytics
      if (data.googleAnalytic) {
        if ("ga" in window && window.ga.create) {
          recordGAEvent(data);
        } else {
          ReactGA.initialize(data.googleAnalytic, { debug: false });
          recordGAEvent(data);
        }
      }

      if (!data.responseType) return;

      // Bullseye Log Request
      try {
        await LogSearchRsltwReq(
          data.locationId,
          data.responseType,
          data.searchReqLogId,
          data?.attributeId,
          data?.attributeTypeId
        );
      } catch (error) {
        toast.error(
          "Error registering in the log search the " +
            SEARCH_RES_LOG_TYPE_DESC[data.responseType - 1] ?? ""
        );
        console.error(error);
      }
    },
  }
)(App2);
