import React from "react";
import ReactPlayer from "react-player/lazy";
import PropTypes from "prop-types";

/** Simple component to show a video or list of videos
 * parameters
 * Title: this component receive the title of the header
 * autoPlaying: if we want the video to play when loading the page, default = false
 * videoList: array of string with the URL's videos
 *  Examples:
 *      videoList = ["https://cdn.shopify.com/s/files/1/0162/3841/1830/files/how-it-works-edited_1.mp4?v=1601323279"]
 *      videoList = ["urlVideo1","urlVideo2","urlVideo3"....]
 */

const VideoPlayer = ({
  title,
  autoPlaying,
  videoList,
  titleCssClass,
  light,
  height,
}) => {
  return (
    <>
      {title && (
        <div className={`bullseye-red h4 ${titleCssClass ?? ""}`}>{title}</div>
      )}
      <ReactPlayer
        url={videoList}
        controls={true}
        width={"100%"}
        height={height}
        playing={autoPlaying}
        light={light}
        onError={(e) => console.error("error video", e)}
      />
    </>
  );
};
VideoPlayer.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string,
  /** If is true will automatically start playing*/
  autoPlaying: PropTypes.bool,
  /** array of strings with the URL of the videos, at least one video should come */
  videoList: PropTypes.array.isRequired,
  /** CSS class to change the look and feel of the title property */
  titleCssClass: PropTypes.string.isRequired,
  /** If image is specify the video won't play automatically, instead a preview image is displayed */
  light: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /** Height of the video container */
  height: PropTypes.number.isRequired,
};

VideoPlayer.defaultProps = {
  autoPlaying: false,
  light: false,
  height: "90%",
};

export default VideoPlayer;
