import { GetIpAddress, GetLatLngByIP } from "../services/restGeoLocation";

import { useEffect, useState } from "react";

const useGeoLocation = (
  useGPS,
  useIPAddress = true,
  ip = null,
  ready = false
) => {
  const defaultState = {
    loaded: false,
    hasError: false,
    error: "",
    coordinates: { lat: 0, lng: 0 },
  };

  const clearGeoLocation = () => {
    setLocation({ ...defaultState, loaded: true });
  };

  const [geoLocation, setLocation] = useState(defaultState);

  const onSuccess = async (location) => {
    console.log("GPS Location", location);

    let dataResult = null;
    let country = null;

    try {
      dataResult = await GetIpAddress();
    } catch (error) {
      console.error(error);
    }

    if (dataResult) {
      const resultArray = dataResult.split("\n");
      country = resultArray[8].replace("loc=", "");
    }

    setLocation({
      loaded: true,
      hasError: false,
      error: {},
      coordinates: {
        country: country,
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = async (error) => {
    if (navigator.userAgent.indexOf("Chrome-Lighthouse") > -1) {
      return;
    }

    if (useGPS) {
      setLocation({
        loaded: true,
        hasError: true,
        error: "GPS was denied",
        coordinates: { lat: 0, lng: 0 },
      });

      return;
    }

    console.log(error.code, error.message);

    let ipResult = ip;
    // Override the IP for testing
    // ipResult = "96.65.29.177"; // Somerville, NJ, USA
    // ipResult = "142.213.160.134"; // Quebec, CA

    if (!ip) {
      const dataResult = await GetIpAddress();

      const resultArray = dataResult.split("\n");
      ipResult = resultArray[2].replace("ip=", "");
    }

    const userGeoLocation = await GetLatLngByIP(ipResult);

    if (!userGeoLocation) {
      //clearGeoLocation();

      setLocation({
        loaded: true,
        hasError: true,
        error: "Error trying to get your location by ip",
        coordinates: { lat: 0, lng: 0 },
      });

      console.error("Error trying to get your location by ip", ipResult);
      return;
    }

    // default values center of USA
    setLocation({
      loaded: true,
      hasError: false,
      error: {},
      coordinates: {
        lat: userGeoLocation.lat, // Number(coordinates[3]) || 40.73061,
        lng: userGeoLocation.lon, // Number(coordinates[4]) || -73.935242,
        postalCode: userGeoLocation.postalCode, // string e.g. 01010
        country: userGeoLocation.country, // country abbr e.g. US
        countryName: userGeoLocation.countryName, // country name e.g. Guatemala
        city: userGeoLocation.city, // city name e.g. Miami
        state: userGeoLocation.state, // state abbr e.g. FL
        stateName: userGeoLocation.stateName, // state name e.g. Florida
      },
    });
  };

  useEffect(() => {
    if (!ready) return;

    if (!useGPS && !useIPAddress && !ip) {
      clearGeoLocation();
      return;
    }

    if (geoLocation.coordinates.lat || geoLocation.coordinates.lng) return;

    if (!("geolocation" in navigator)) {
      onError({ code: -1, message: "Geolocation not supported" });
      console.log("Geolocation not supported");
      return;
    }
    if (!useGPS && (useIPAddress || ip)) {
      onError({ code: -1, message: "Ip detection preferer" });
      return;
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, [
    useGPS,
    useIPAddress,
    geoLocation.coordinates.lat,
    geoLocation.coordinates.lng,
    ready,
    ip,
  ]);

  return [geoLocation, clearGeoLocation];
};

export default useGeoLocation;
