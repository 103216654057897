import React from "react";
import "./slider.scss";

import PropTypes from "prop-types";
import { useTracking } from "react-tracking";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { NewId } from "../../utils/utils";

/** The header component is full width, where some navigation elements and other content such as the location's logo and image are located.*/
const Slider = ({
  title,
  website,
  email,
  directions,
  backgroundImage,
  bannerImage,
  logo,
  backgroundOpacy = 0.0,
}) => {
  const links = [];
  const separator = (id) => (
    <span key={id} className="mx-3 border-right"></span>
  );

  if (website) {
    links.push(
      <a
        key={1}
        target="_blank"
        rel="noopener noreferrer"
        href={website}
        onClickCapture={() =>
          trackEvent({
            eventType: EVENT_LOG_TYPE.logSearch,
            responseType: SEARCH_RES_LOG_TYPE.urlClicked,
            event: "click",
            eventNameGA: "Click website",
            additionalInformation: website
          })
        }
      >
        Website
      </a>
    );
  }

  if (email) {
    if (links.length > 0) links.push(separator(NewId()));

    links.push(
      <a
        key={2}
        href={"mailto:" + email}
        onClickCapture={() =>
          trackEvent({
            eventType: EVENT_LOG_TYPE.logSearch,
            responseType: SEARCH_RES_LOG_TYPE.emailClicked,
            event: "click",
            eventNameGA: "Click email",
            additionalInformation: email
          })
        }
      >
        Email
      </a>
    );
  }

  if (directions) {
    if (links.length > 0) links.push(separator(NewId()));

    links.push(
      <a
        key={3}
        target="_blank"
        rel="noopener noreferrer"
        href={directions}
        onClickCapture={() =>
          trackEvent({
            eventType: EVENT_LOG_TYPE.logSearch,
            responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
            event: "click",
            eventNameGA: "Click directions"
          })
        }
      >
        Directions
      </a>
    );
  }

  const { trackEvent } = useTracking();

  return (
    <div
      className="d-flex flex-column justify-content-end slider__main slider__main--container"
      style={
        backgroundImage
          ? {
              backgroundImage:
                `linear-gradient(rgba(0, 0, 0, ${backgroundOpacy}), rgba(0, 0, 0, ${backgroundOpacy})), url(` +
                backgroundImage +
                ")",
              height: 350,
            }
          : {}
      }
    >
      {bannerImage && (
        <img className="img-fluid" src={bannerImage} alt={title}></img>
      )}
      <hr className="w-1 m-0 p-0" />

      <div className="d-flex flex-column flex-md-row slider__main--information">
        <div className="slider__main--logoContainer">
          {logo && <img src={logo} alt={title}></img>}
        </div>
        <div className="pt-1 slider__main--infoContainer">
          <h2>{title}</h2>
          <div className="slider__iconsArray">
            {links.length > 0 && links.map((link) => link)}
          </div>
        </div>
      </div>
    </div>
  );
};
Slider.propTypes = {
  /** Title of the card */
  title: PropTypes.string.isRequired,
  /**  URL to the website option*/
  website: PropTypes.string,
  /**  URL to the email option*/
  email: PropTypes.string,
  /**  URL to the direction option*/
  directions: PropTypes.string,
  /**  To use the whole div background with an image */
  backgroundImage: PropTypes.string,
  /** Image that fill only the image place holder */
  bannerImage: PropTypes.string,
  /** Logo fo the company */
  logo: PropTypes.string.isRequired,
  /** Image background to the header */
  backgroundOpacy: PropTypes.number,
};
export default Slider;
