import React, { Component } from "react";
import { fixRelativePath } from "../../utils/bullseyeUtils";
import AccordtionCustomToggle from "../accordionCustomToggle/accordionCustomToggle";
import { Accordion } from "react-bootstrap";
import GenericGrid from "../genericGrid/genericGrid";

const ComplexGridAttributeExpanded = ({
  title,
  titleClass,
  complexAttribute,
  customPin,
  onMoreInfoClick,
}) => {
  return (
    <>
      {customPin && (
        <img
          className="mr-2"
          style={{ height: 20, verticalAlign: "middle" }}
          src={customPin}
          alt="icon"
        />
      )}
      <span className={titleClass}>{title}</span>
      {complexAttribute &&
        complexAttribute.map((cp, index) => {
          return (
            <GenericGrid
              key={index}
              data={cp}
              onMoreInfoClick={onMoreInfoClick}
            ></GenericGrid>
          );
        })}
    </>
  );
};

export default ComplexGridAttributeExpanded;
