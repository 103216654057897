import { createContext } from "react";

export interface ContextProps {
  clientTerms: { [key: string]: string };
  language: string;

  //methods
  setClientTerms: (
    language: string,
    clientTerms: { [key: string]: string }
  ) => void;
  getClientTerm: (key: string, defaultValue: string) => string;
}

export const LanguageContext = createContext({
  language: "en",
  getClientTerm: (key, defaultValue) => defaultValue,
} as ContextProps);
