import React, { useState } from "react";
import Label from "../label";
import ReactStars from "react-rating-stars-component";
import PropTypes from "prop-types";
import "./rateInput.scss";
import ErrorDisplay from "../errorDisplay/";

const RateInput = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  errors,
  type = "text",
  placeholder = "",
  required = false,
  onChange,
  ...props
}) => {
  const [valueRating, setValurRating] = useState();
  const ratingChanged = (newRating) => {
    setValurRating(newRating);
  };

  return (
    <div className="form-group rateInput__stars">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <ReactStars
        count={5}
        value={valueRating}
        onChange={ratingChanged}
        size={35}
        activeColor="#ffd700"
        edit={true}
      />
      <input
        id={name}
        type={"hidden"}
        value={valueRating}
        name={name}
        ref={register}
        onChange={onChange}
        {...props}
      />
      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </div>
  );
};

RateInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,

  /** text to put into the input like placeholder */
  placeholder: PropTypes.string,

  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,
  /** type of the multiOption Radio or CheckBox */
  type: PropTypes.string,
  /** child component to display next to the input */
  children: PropTypes.node,

  props: PropTypes.object,
};

export default RateInput;
