let DEBUG = false;
let API_KEY = null;
let LANGUAGE = "en";
let COUNTRY = null;
const MAPBOX_API_URL = process.env.REACT_APP_MAPBOX_GEOCODING_URL;

function log(message, warn = false) {
  if (DEBUG) {
    if (warn) {
      console.warn(message);
    } else {
      console.log(message);
    }
  }
}

async function handleUrl(url) {
  const response = await fetch(url).catch(() =>
    Promise.reject(new Error("Error fetching data"))
  );

  const json = await response.json().catch(() => {
    log("Error parsing server response");
    return Promise.reject(new Error("Error parsing server response"));
  });

  if (response.status === 200) {
    log(json);
    return json;
  }
  log(`${json.message}.\nServer returned status code ${response.status}`, true);
  return Promise.reject(
    new Error(
      `${json.message}.\nServer returned status code ${response.status}`
    )
  );
}

const mapboxGeocode = {
  /**
   *
   *
   * @param {string} apiKey
   */
  setApiKey(apiKey) {
    API_KEY = apiKey;
  },

  /**
   *
   *
   * @param {string} language
   */
  setLanguage(language) {
    LANGUAGE = language;
  },

  /**
   *
   *
   * @param {string} country
   */
  setRegion(country) {
    COUNTRY = country;
  },

  /**
   *
   *
   * @param {boolean} [flag=true]
   */
  enableDebug(flag = true) {
    DEBUG = flag;
  },

  /**
   *
   *
   * @param {string} lat
   * @param {string} lng
   * @param {string} [apiKey]
   * @param {string} [language]
   * @param {string} [country]
   * @returns {Promise}
   */
  async fromLatLng(lat, lng, apiKey, language, country) {
    if (!lat || !lng) {
      log("Provided coordinates are invalid", true);
      return Promise.reject(new Error("Provided coordinates are invalid"));
    }

    const latLng = `${lng},${lat}`;
    let url = `${MAPBOX_API_URL}${encodeURIComponent(latLng)}.json`;

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY;
      url += `?access_token=${API_KEY}`;
    }

    if (language || LANGUAGE) {
      LANGUAGE = language || LANGUAGE;
      url += `&language=${LANGUAGE}`;
    }

    if (country || COUNTRY) {
      COUNTRY = country || COUNTRY;
      url += `&country=${encodeURIComponent(COUNTRY)}`;
    }

    return handleUrl(url);
  },

  async fromAdress(address, apiKey, language, country) {
    let url = `${MAPBOX_API_URL}${encodeURIComponent(address)}.json`;

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY;
      url += `?access_token=${API_KEY}`;
    }

    if (language || LANGUAGE) {
      LANGUAGE = language || LANGUAGE;
      url += `&language=${LANGUAGE}`;
    }

    if (country || COUNTRY) {
      COUNTRY = country || COUNTRY;
      url += `&country=${encodeURIComponent(COUNTRY)}`;
    }

    return handleUrl(url);
  },
};

export default mapboxGeocode;
