import React from "react";
import PropTypes from "prop-types";

import Label from "../label";
import Option from "./dropDownOption";
import ErrorDisplay from "../errorDisplay/errorDisplay";

const DropDownInput = ({
  name,
  label,
  options,
  small = false,
  required = false,
  register,
  value,
  errors,
  onChange,
  noSelectionText,
  ...props
}) => {
  return (
    <div className="form-group" id={`attr_container_${name}`}>
      <Label htmlFor={name} label={label} required={required} />

      <select
        className={`form-control ${small ? "form-control-sm" : ""} ${
          errors[name] ? "is-invalid" : ""
        } `}
        id={name}
        name={name}
        ref={register}
        onChange={onChange}
        aria-required={required || null}
        {...props}
      >
        {noSelectionText && (
          <Option key={"-1"} name={noSelectionText} value=""></Option>
        )}
        {options &&
          options.map((item, index) => (
            <Option key={index} name={item.name} value={item.value} />
          ))}
      </select>

      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </div>
  );
};
DropDownInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,
  /** options array with the options to select */
  options: PropTypes.array.isRequired,
  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,
  /** function to change value */
  onChange: PropTypes.func.isRequired,

  props: PropTypes.object,
};

export default DropDownInput;
