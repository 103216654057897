import React from "react";
import PropTypes from "prop-types";

import BusinessHoursExpanded from "./businessHoursExpanded";
import BusinessHoursAccordion from "./businessHoursAccordion";

/** Component to display a list of business and holiday hours
 *  in a table also this component could behave as a button with
 *  a popup implementation depending on the props
 */
const BusinessHours = ({
  daysArray,
  openCloseLabel,
  isClose,
  smallAlert,
  title,
  titleClass,
  showAccordion,
}) => {
  let alertText = isClose ? "Close Now" : "Open Now";
  // If a custom openClose label is specify that will override the default value of the alert
  alertText = !openCloseLabel ? alertText : openCloseLabel;

  return !showAccordion ? (
    <BusinessHoursExpanded
      title={title}
      daysArray={daysArray}
      smallAlert={smallAlert}
      isClose={isClose}
      alertText={alertText}
    />
  ) : (
    <BusinessHoursAccordion
      title={alertText}
      titleClass={titleClass}
      daysArray={daysArray}
    />
  );
};

// Specifies the default values for props:
BusinessHours.defaultProps = {
  showAccordion: false,
};

BusinessHours.propTypes = {
  /** Days of the week*/
  daysArray: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the day of the week */
      day: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

      /** hours in format 08:00 AM - 05:00 PM*/
      hours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    })
  ).isRequired,

  /** Is Close indicates the control the location is
   *  close and change the color of the text to red if
   *  not close the color will be green */
  isClose: PropTypes.bool,

  /** Open or Close label for Subtitle*/
  openCloseLabel: PropTypes.string,

  /** Small alert if need it, only visible when accordion = false */
  smallAlert: PropTypes.string,

  /** Title to display above the business hours, only visible when show accordion = false */
  title: PropTypes.string,

  /** This parameter change the behavior of the business hours table
   * if set to true a title and caret will show instead of the full
   * table expanded
   */
  showAccordion: PropTypes.bool,
};

BusinessHours.defaultProps = {
  /** Default value for isClose flag is false */
  isClose: false,
};

export default BusinessHours;
