import React from "react";
import PropTypes from "prop-types";
import "./showcase.scss";

/**
 * Showcase Card with an icon on the top, a title below and paragraph
 */
const ShowcaseCard = ({ icon, title, body, links, itemCssClass }) => {
  const iconIsSpriteSvg = icon && icon.includes(".svg#");

  return (
    <div className={`m-1 showcase__card--maxSize ${itemCssClass || ""}`}>
      {!iconIsSpriteSvg && (
        <img
          className="my-3 mx-auto d-block img-fluid"
          src={icon}
          alt={title || "showcase"}
        />
      )}
      {iconIsSpriteSvg && (
        <svg role="img" className="my-3 d-block nohover showcase__widthSquare50">
          <use href={icon} />
        </svg>
      )}

      <strong>{title}</strong>
      <p>{body}</p>
      <ul className="list-unstyled">
        {links &&
          links.length > 0 &&
          links.map((link) => {
            return (
              <li key={link.url}>
                <a href={link.url}>{link.title}</a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

ShowcaseCard.propTypes = {
  /** Icon URL to show on top */
  icon: PropTypes.string,
  /** Main card title */
  title: PropTypes.string,
  /** Description in a paragraph */
  body: PropTypes.string.isRequired,
  /** Links to display in the bottom of the Card */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      /** URL (Open in the same tab) */
      url: PropTypes.string.isRequired,
      /** Display text for the Link */
      title: PropTypes.string.isRequired,
    })
  ),
};

export default ShowcaseCard;
