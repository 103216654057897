import React from "react";
import NoLocations from "./no-locations";
import NotConfiguredExpired from "./not-configured-expired";
import NotFound from "./notFound";
import UnexpectedError from "./unexpectedError";
import NotConfigured from "./not-configured"

const ErrorPage = (props) => {
  let errorType = props.match?.params
    ? props.match?.params.errorType
    : props.errorType;

  if (!errorType) {
    errorType = "not-found";
  }
  console.log(errorType);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="my-5 p-1 p-md-5" style={{ width: "90%" }}>
              {errorType && errorType === "not-found" ? <NotFound /> : <></>}
              {errorType && errorType === "no-locations" ? (
                <NoLocations />
              ) : (
                <></>
              )}
              {errorType && errorType === "unexpected" ? (
                <UnexpectedError />
              ) : (
                <></>
              )}
              {errorType && errorType === "not-configured-expired" ? (
                <NotConfiguredExpired/>
              ) : (
                <></>
              )}
              {errorType && errorType === "not-configured" ? (
                <NotConfigured/>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
