import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Label = ({ htmlFor, label, required, helpTooltip, className, id }) => {
  return (
    <>
      <label htmlFor={htmlFor} className={className} id={id}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      {helpTooltip && (
        <OverlayTrigger overlay={<Tooltip>{helpTooltip}</Tooltip>}>
          <svg
            role="img"
            className="ml-2"
            width={14}
            height={14}
            style={{ marginTop: "-0.4rem " }}
          >
            <use
              xlinkHref={`/images/b-icons/bootstrap-icons.svg#${"question-square-fill"}`}
            />
          </svg>
        </OverlayTrigger>
      )}
    </>
  );
};

Label.propTypes = {
  /** HTML ID for associated input */
  htmlFor: PropTypes.string,

  /** Label text */
  label: PropTypes.string.isRequired,

  /** Display asterisk after label if true */
  required: PropTypes.bool,
};

export default Label;
