import React from "react";
//import PropTypes from "prop-types";

const DropDownOption = ({ name, value }) => {
  return <option value={value}>{name}</option>;
};

/*DropDownOption.propTypes = {
  name: PropTypes.string.isRequired,

  
  value: PropTypes.string.isRequired,
};*/
export default DropDownOption;
