import { useEffect, useState } from "react";
import { getLocationList } from "../services/restSearchService";

export function useGetLocationList(
  clientId,
  apiKey,
  countriesIds,
  categoryIds,
  returnOnlyInternetLocations
) {
  const [locationList, setLocationList] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLocatorConfiguration = async () => {
      const locations = await getLocationList(
        clientId,
        apiKey,
        countriesIds,
        "json",
        true,
        true,
        1,
        "results",
        categoryIds,
        returnOnlyInternetLocations
      );

      setLocationList(locations);
      setLoading(false);
    };
    if (clientId > 0 && apiKey) {
      getLocatorConfiguration();
    }
  }, [clientId, apiKey]);

  return [locationList, loading];
}
