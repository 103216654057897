import React, { Component, useState } from "react";
import ComplexGridAttributeExpanded from "./complexGridAttributeExpanded";
import ComplexGridAttributeAccordion from "./complexGridAttributeAccordion";
import { Modal } from "react-bootstrap";
import LinkButton from "../linkButton/linkButton";
import "./complexGridAttribute.scss";

const ComplexGridAttribute = ({
  title,
  titleClass,
  showAccordion,
  complexAttribute,
  customPin,
}) => {
  const buildCustomAttribute = (attr) => {
    let config = [];
    let newObjCustom = {
      columns: [],
      data: [],
    };

    // let extraColumns = {
    //   Treatment: "Treatment",
    //   Yield: "Yield (bu/ac)",
    //   "Yield Advantage": "Yield Advantage",
    //   "Plant Count": "Plant Count (Plants/M Row)",
    //   "Disease Rating": "Disease Rating",
    //   Vigour: "Vigour (1-9)",
    //   "Blackleg Rating": "Blackleg Rating (0-4)",
    // };
    var extraColumns = window?.bullseyeLocationsConfig?.complexAttributeColumns;

    if (attr && extraColumns) {
      for (const [key, value] of Object.entries(extraColumns)) {
        attr.forEach((atr) => {
          atr.SubAttributes?.forEach((sb) => {
            if (
              sb?.AttributeName === key &&
              sb?.AttributeValue &&
              !newObjCustom.columns.includes(value)
            ) {
              newObjCustom.columns.push(value);
            }
          });
        });
      }
    }

    attr.forEach((complex) => {
      if (complex && complex.SubAttributes?.length > 0) {
        let getValues = buildSubAttributes(complex.SubAttributes, newObjCustom);
        if (getValues) {
          newObjCustom.data.push(getValues);
        }
      }
    });

    config.push(newObjCustom);

    return config;
  };

  const buildSubAttributes = (subAttributes, newObjCustom) => {
    let newObj = {};
    // let newCustomObj = {};
    let order = 0;
    let treatment = subAttributes.find(
      (f) => f.AttributeName === "Treatment"
    )?.AttributeValue;

    if (treatment || newObjCustom.columns.includes("Treatment")) {
      newObj.treatment = treatment;
      order = treatment.includes("Competitor")
        ? 3
        : treatment.includes("Utrisha") ||
          treatment.includes("LumiGEN") ||
          treatment.includes("Fungicide")
        ? 6
        : 0;
    }

    let yieldVal = `${
      subAttributes.find((f) => f.AttributeName === "Yield")?.AttributeValue
    }`;

    if (
      yieldVal ||
      newObjCustom.columns.includes(
        "Yield <span class='ca-header-measure'>(bu/ac)</span>"
      )
    ) {
      newObj.yield = yieldVal;
    }

    let yieldAdvantageVal = subAttributes.find(
      (f) => f.AttributeName === "Yield Advantage"
    )?.AttributeValue;

    if (
      yieldAdvantageVal ||
      newObjCustom.columns.includes(
        "Yield Advantage <span class='ca-header-measure'>(bu/ac)</span>"
      )
    ) {
      newObj.yieldAdvantage = yieldAdvantageVal;
    }

    let plantCount = subAttributes.find(
      (f) => f.AttributeName === "Plant Count"
    )?.AttributeValue;

    if (
      plantCount ||
      newObjCustom.columns.includes(
        "Plant Count <span class='ca-header-measure'>(Plants/M Row)</span>"
      )
    ) {
      newObj.plantCount = plantCount;
    }
    //
    let sclerotinia = subAttributes.find(
      (f) => f.AttributeName === "Sclerotinia"
    )?.AttributeValue;

    if (sclerotinia || newObjCustom.columns.includes("Sclerotinia")) {
      newObj.sclerotinia = sclerotinia;
    }

    let vigour = subAttributes.find(
      (f) => f.AttributeName === "Vigour"
    )?.AttributeValue;

    if (
      vigour ||
      newObjCustom.columns.includes(
        "Vigour <span class='ca-header-measure'>(1-9)</span>"
      )
    ) {
      newObj.vigour = vigour;
    }

    let blacklegRating = subAttributes.find(
      (f) => f.AttributeName === "Blackleg Rating"
    )?.AttributeValue;

    if (
      blacklegRating ||
      newObjCustom.columns.includes(
        "Blackleg Rating <span class='ca-header-measure'>(0-4)</span>"
      )
    ) {
      newObj.blacklegRating = blacklegRating;
    }

    newObj.order = order;

    return newObj;
  };

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const onMoreInfoClick = (data) => {
    setShowModal(true);
  };

  return (
    <>
      {window?.bullseyeLocationsConfig?.showComplexAttributeImageTitle && (
        <div className="flex-fill">
          {customPin && (
            <img
              className="mr-2"
              style={{ height: 20, verticalAlign: "middle" }}
              src={customPin}
              alt="icon"
            />
          )}
          {title && (
            <span className={`bullseye-red ${titleClass}`}>{title}</span>
          )}
        </div>
      )}
      {complexAttribute && (
        <LinkButton
          url={"#"}
          isEventCancelable={false}
          onClickButton={onMoreInfoClick}
          title={"Show Data"}
          className={"float-right"}
        ></LinkButton>
      )}
      {
        <Modal
          className="listMapLocator__leadModal"
          show={showModal}
          backdrop="static"
          onHide={closeModal}
          size={"xl"}
          centered
        >
          <Modal.Header
            closeButton={true}
            className="listMapLocator__leadModal-greyBk"
          >
            {customPin && (
              <img
                className="mr-2"
                style={{ height: 20, verticalAlign: "middle" }}
                src={customPin}
                alt="icon"
              />
            )}
            <span className={titleClass}>{title}</span>
          </Modal.Header>
          <Modal.Body>
            {!showAccordion ? (
              <ComplexGridAttributeExpanded
                // customPin={customPin}
                // title={title}
                complexAttribute={buildCustomAttribute(complexAttribute)}
                titleClass={titleClass}
                onMoreInfoClick={onMoreInfoClick}
              />
            ) : (
              <ComplexGridAttributeAccordion
                // customPin={customPin}
                // title={title}
                complexAttribute={buildCustomAttribute(complexAttribute)}
                titleClass={titleClass}
                onMoreInfoClick={onMoreInfoClick}
              />
            )}
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default ComplexGridAttribute;
