import React from "react";

const ErrorDisplay = ({ errorMessage }) => {
  return (
    <>
      {errorMessage && (
        <div className="invalid-feedback d-block font-weight-bold">
          {errorMessage}
        </div>
      )}
    </>
  );
};
export default ErrorDisplay;
