import React from "react";
import { useEffect } from "react";

const TestInterfaceIframe = () => {
  useEffect(() => {
    var bullseyelistener = function (e) {
      // var regex = new RegExp("^.*bullseyelocations-staging.com.*$");
      var regex = new RegExp(".*");

      if (regex.test(e.origin)) {
        if (parseInt(e.data)) {
          document.getElementById("bullseye_iframe").height =
            Math.round(e.data) < 600 ? 700 : Math.round(e.data);
        } else if (e.data === "scroll") {
          window.scrollTo(0, 0);
        }
      }
    };

    if (window.addEventListener) {
      window.addEventListener("message", bullseyelistener, false);
    } else {
      window.attachEvent("onmessage", bullseyelistener);
    }
  }, []);
  return (
    <div className="fluid-container text-center">
      <img
        src="/local/images/banners/banner_1200x150.png"
        alt="A cool header"
      ></img>
      <iframe
        src="https://app.bullseyelocations-staging.com/local/musicstaff1"
        style={{ width: "100%", border: 0 }}
        height="750"
        title="Bullseye Locations Search"
        allowFullScreen={true}
        allow="geolocation"
        id="bullseye_iframe"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default TestInterfaceIframe;
