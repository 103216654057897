import React from "react";
import { Helmet } from "react-helmet-async";
import { buildLocalPageURL } from "../../utils/bullseyeUtils";
import { BULLSEYE_REVIEWS_STATUS } from "../../utils/constants";
import { dateToStringFormat } from "../../utils/utils";

const SEO = ({
  seoTitle,
  seoAbout,
  seoMetaDescription,
  seoCanonical,
  seoLocation,
  seoEvents,
  seoLocationsArray,

  interfaceName = "",
  seoProxyKeyword = "",
  seoLocationIdentifier = "",
  interfaceId = 0,
}) => {
  const createJsonld = () => {
    let jsonBody = "";

    if (seoLocationsArray && seoLocationsArray.length > 0) {
      jsonBody = "[";
      seoLocationsArray.forEach((location, index, seoLocationsArray) => {
        const landingPageURL = interfaceName
          ? buildLocalPageURL(
              location.country,
              location.state ? location.state : location.stateAbbr,
              location.city,
              location.locationName,
              location.id,
              interfaceName,
              seoCanonical,
              false,
              false,
              seoProxyKeyword,
              seoLocationIdentifier,
              location.thirdPartyId,
              null,
              interfaceId
            )
          : "";

        jsonBody += `{
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "url":"${location.url ? location.url : landingPageURL}",
          "name":"${location.locationName}",
          "description":"${location.locationName}",
          "logo":"${location.imageFileUrl ? location.imageFileUrl : ""}",
          "telephone": "${location.phone}",
          "email": "${location.emailAddress}",
          "geo":{
            "@type": "GeoCoordinates",
            "latitude": ${location.latitude},
            "longitude": ${location.longitude}
          },
          "openingHoursSpecification": [
            ${
              location.dailyHoursList
                ? location.dailyHoursList.map((e) => {
                    const splitHour = e.HoursDisplayText.split("-");
                    if (e.NoHoursAvailable || splitHour?.length < 1) {
                      return `{
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": "https://schema.org/${e.NameOfDay}"}`;
                    }

                    if (splitHour?.length > 1) {
                      return `{
                        "@type": "OpeningHoursSpecification",
                        "closes":  "${splitHour[1]}",
                        "dayOfWeek": "https://schema.org/${e.NameOfDay}",
                        "opens":  "${splitHour[0]}"
                      }`;
                    }

                    return "";
                  })
                : ""
            }
          ],
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "${location.city}",
            "addressRegion": "${location.state}",
            "postalCode": "${location.postalCode}",
            "streetAddress": "${location.address1}"
          },
          "contactPoint" : [
            { "@type" : "ContactPoint",
              "telephone" : "${location.phone}",
              "contactType" : "${
                location.contactPosition ? location.contactPosition : ""
              }",
              "name":"${location.contactName ? location.contactName : ""}"
            } ],
          "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${location.latitude},
                "longitude": ${location.longitude}
            },
            "geoRadius": 50
        }
        }${seoLocationsArray.length - 1 !== index ? "," : ""}`;
      });
      jsonBody += "]";
    }
    return jsonBody;
  };
  return (
    <>
      <Helmet>
        {seoLocation && (
          <script type="application/ld+json">
            {`
              { "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "url":"${seoLocation.URL ? seoLocation.URL : seoCanonical}",
                "name":"${seoLocation.Name}",
                "description":"${seoMetaDescription}",
                "image":"${seoAbout ? seoAbout.image : ""}",
                "logo":"${
                  seoLocation.ImageFileUrl ? seoLocation.ImageFileUrl : ""
                }",
                "telephone": "${seoLocation.PhoneNumber}",
                "email": "${seoLocation.EmailAddress}",
                "geo":{
                  "@type": "GeoCoordinates",
                  "latitude": ${seoLocation.Latitude},
                  "longitude": ${seoLocation.Longitude}
                },
                "review":[
                  ${
                    seoLocation.BullseyeReviews
                      ? seoLocation.BullseyeReviews.filter(
                          (x) => x.StatusID === BULLSEYE_REVIEWS_STATUS.Approved
                        ).map((e) => {
                          return `{                      
                        "@type": "Review",
                        "author": "${e.FirstName} ${e.LastName}",
                        "datePublished": "${dateToStringFormat(
                          e.DateCreated,
                          "YYYY-MM-DD"
                        )}",
                        "reviewBody": "${e.Comments}",
                        "name": "",
                        "reviewRating": {
                          "@type": "Rating",
                          "ratingValue": "${
                            e.OverallRating
                          }"                          
                        }                      
                      }`;
                        })
                      : ""
                  }
                  ],
                "event":[
                  ${
                    seoEvents
                      ? seoEvents.map((e) => {
                          return `{
                      "@type": "Event",
                      "name": "${e.EventTitle}",
                      "startDate": "${dateToStringFormat(
                        e.StartDate,
                        "MMM DD"
                      ).toUpperCase()}",
                      "endDate": "${dateToStringFormat(
                        e.EndDate,
                        "MMM DD"
                      ).toUpperCase()}"}`;
                        })
                      : ""
                  }],
                "openingHoursSpecification": [
                  ${
                    seoLocation.DailyHoursList
                      ? seoLocation.DailyHoursList.map((e) => {
                          const splitHour = e.HoursDisplayText.split("-");

                          if (e.NoHoursAvailable || splitHour?.length < 1) {
                            return `{
                              "@type": "OpeningHoursSpecification",
                              "dayOfWeek": "https://schema.org/${e.NameOfDay}"}`;
                          }

                          if (splitHour?.length > 1) {
                            return `{
                              "@type": "OpeningHoursSpecification",
                              "closes":  "${splitHour[1]}",
                              "dayOfWeek": "https://schema.org/${e.NameOfDay}",
                              "opens":  "${splitHour[0]}"
                            }`;
                          }

                          return "";
                        })
                      : ""
                  }
                ],
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "${seoLocation.City}",
                  "addressRegion": "${seoLocation.StateAbbreviation}",
                  "postalCode": "${seoLocation.PostCode}",
                  "streetAddress": "${seoLocation.Address1}"
                },
                "contactPoint" : [
                  { "@type" : "ContactPoint",
                    "telephone" : "${seoLocation.PhoneNumber}",
                    "contactType" : "${
                      seoLocation.ContactPosition
                        ? seoLocation.ContactPosition
                        : ""
                    }",
                    "name":"${
                      seoLocation.ContactName ? seoLocation.ContactName : ""
                    }"
                  } ],
                "areaServed": {
                  "@type": "GeoCircle",
                  "geoMidpoint": {
                      "@type": "GeoCoordinates",
                      "latitude": ${seoLocation.Latitude},
                      "longitude": ${seoLocation.Longitude}
                  },
                  "geoRadius": 50
              }
            }
            `}
          </script>
        )}
        {seoLocationsArray && seoLocationsArray.length > 0 && (
          <script type="application/ld+json">{createJsonld()}</script>
        )}
        <title>{seoTitle}</title>
        <meta name="description" content={seoMetaDescription} />
        {seoCanonical && (
          <>
            <link rel="canonical" href={seoCanonical} />
          </>
        )}
      </Helmet>
      <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
        {seoLocation?.Latitude > 0 && (
          <meta itemProp="latitude" content={seoLocation.Latitude} />
        )}
        {seoLocation?.Longitude > 0 && (
          <meta itemProp="longitude" content={seoLocation.Longitude} />
        )}
        {seoLocation?.EmailAddress && (
          <meta itemProp="email" content={seoLocation.EmailAddress} />
        )}
      </div>
    </>
  );
};

export default SEO;
