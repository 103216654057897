import { useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export function useMutationResize(container) {
  useEffect(() => {
    const containerDiv = container;

    if (!containerDiv) return;

    // Create an observer instance linked to the callback function
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        window.parent.postMessage(`${height + 45}`, "*");
      }
    });

    // Start observing the target node for resize
    resizeObserver.observe(containerDiv);

    // Later, you can stop observing
    return () => resizeObserver.unobserve(containerDiv);
  }, [container]);
}
