import http from "./httpService";

const endpointPath = "InterfaceConfiguration";
const getInterfaceConfigurationionGetMethod = "GetInterfaceConfiguration";
const getGeoConfiguration = "GeoConfiguration/ValidateGeoLocation";

function getGeoConfigurationURL(countryId, city, stateAbbr) {
  return `${process.env.REACT_APP_URL_WRAPPER}/${getGeoConfiguration}?countryId=${countryId}&city=${city}&stateAbbr=${stateAbbr}`;
}
function getInterfaceConfigurationURL(
  interfaceName,
  apiKeyName,
  language,
  interfaceId
) {
  return `${
    process.env.REACT_APP_URL_WRAPPER
  }/${endpointPath}/${getInterfaceConfigurationionGetMethod}?interfaceName=${interfaceName}${
    apiKeyName ? "&apiKeyName=" + apiKeyName : ""
  }${language ? "&languageCode=" + language : ""}${
    interfaceId ? "&interfaceId=" + interfaceId : ""
  }`;
}

export async function getInterfaceConfig(
  interfaceName,
  apiKeyName = "",
  language,
  interfaceId
) {
  //return sampleDataConfig;
  const config = await http.get(
    getInterfaceConfigurationURL(
      interfaceName,
      apiKeyName,
      language,
      interfaceId
    ),
    {
      params: null,
    }
  );
  return config.data;
}

export async function isValidCountryAndState(
  apiKey,
  clientId,
  countryId,
  city,
  stateAbbr
) {
  http.setSecurityParameters(clientId, apiKey);
  const config = await http.get(
    getGeoConfigurationURL(countryId, city, stateAbbr)
  );
  return config.data;
}
