import React from "react";
import {
  datesAreEquals,
  convertEpochToDate,
  convertJsonTimeToString,
} from "../../utils/utils";
import "./events.scss";

import PropTypes from "prop-types";
import { useTracking } from "react-tracking";

/**
 * Component to show events support one date, or range of dates
 * @param {string} title
 * @param {string} eventList
 * @param {string} maxItemsToDisplay
 * @param {string} showDateLikeDescription
 * @returns {ReactComponent} React Component
 */
const Events = ({
  title,
  eventList,
  maxItemsToDisplay,
  showDateLikeDescription,
}) => {
  const { trackEvent } = useTracking();

  return (
    <div className="events__container">
      <div className="bullseye-red h4">{title}</div>
      {eventList && eventList.length > 0 && (
        <ul className="list-unstyled">
          {eventList
            .slice(
              0,
              maxItemsToDisplay > 0 ? maxItemsToDisplay : eventList.length
            )
            .map((item) => (
              <li
                className={`media list-border-bottom mt-3 pb-3`}
                key={item.EventId}
              >
                <div className="card event__cardDate border-bullseye mr-3">
                  <div className="bullseye-red card-body text-center py-2">
                    <div
                      className={
                        item.EndDate &&
                        !datesAreEquals(item.StartDate, item.EndDate)
                          ? "event__twoDate mt-1"
                          : "event__oneDate mt-3"
                      }
                    >
                      {convertEpochToDate(
                        parseInt(item.StartDate.substr(6)),
                        true
                      )}
                    </div>
                    {item.EndDate &&
                      !datesAreEquals(item.StartDate, item.EndDate) && (
                        <>
                          <div className="event__to">TO</div>
                          <div className="event__twoDate">
                            {convertEpochToDate(
                              parseInt(item.EndDate.substr(6)),
                              true
                            )}
                          </div>
                        </>
                      )}
                  </div>
                </div>
                <div className="media-body">
                  {!showDateLikeDescription && (
                    <>
                      <strong>{item.EventTitle}</strong>

                      <>
                        <div className="event__description-Container">
                          {item.EventDescription &&
                          item.EventDescription.length >= 70
                            ? `${item.EventDescription.slice(0, 65)}...`
                            : item.EventDescription}
                        </div>
                        {navigator.userAgent !== "ReactSnap" && (
                          <a
                            href={item.urlEvent}
                            rel="noopener noreferrer"
                            className="d-block"
                            onClickCapture={() => {
                              if (item.urlEvent) {
                                trackEvent({
                                  event: "click",
                                  genericResponseType:
                                    "Click local page event " + item.EventTitle,
                                });
                              }
                            }}
                          >
                            More Info
                          </a>
                        )}
                      </>
                    </>
                  )}
                  {showDateLikeDescription && (
                    <>
                      <div>
                        <strong>
                          {convertEpochToDate(
                            parseInt(item.StartDate.substr(6))
                          )}
                        </strong>
                        {` ${convertJsonTimeToString(item.StartTime)} ${
                          item.EndDate ? " to " : ""
                        } `}
                      </div>
                      {item.EndDate && (
                        <div>
                          <strong>
                            {convertEpochToDate(
                              parseInt(item.EndDate.substr(6))
                            )}
                          </strong>
                          {` ${convertJsonTimeToString(item.EndTime)} `}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
Events.propTypes = {
  /** Title of the card */
  title: PropTypes.string,
  /** List of events to display */
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      /** Event id */
      EventId: PropTypes.number.isRequired,
      /** Begin date of the event */
      StartDate: PropTypes.string.isRequired,
      /** End date of the event */
      EndDate: PropTypes.string,
      /** Title */
      EventTitle: PropTypes.string.isRequired,
      /** Description of the Event */
      EventDescription: PropTypes.string,
      /** Url for more info */
      RegistrationURL: PropTypes.string,
    })
  ).isRequired,
  /** Max items to display, if  maxItemsToDisplay = 0 will display all items*/
  maxItemsToDisplay: PropTypes.number,
  /** If is enable will show the event start date and end date in the description section*/
  showDateLikeDescription: PropTypes.bool,
};

// Specifies the default values for props:
Events.defaultProps = {
  showDateLikeDescription: false,
};

export default Events;
