import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { setSecurityParameters } from "../../services/httpService";
import {
  findClientMappingSettings,
  getLandingPageConfiguration,
} from "../../services/restSearchService";
import { fixRelativePath } from "../../utils/bullseyeUtils";
import { getUrlParameterValue } from "../../utils/utils";

// Components
import LandingPage from "./landingPage";

const cssBaseURL = process.env.REACT_APP_BULLSEYE_RESOURCE;

const GetParameterPage = (props) => {
  const [landingPageConfiguration, setLandingPageConfiguration] = useState({
    loading: true,
    config: {
      landingPageId: 0,
      templateCssURLPath: null,
      advancedStyleCssPath: null,
      blocks: [],
      landingPageShowcaseContent: [],
      apiKey: null,
      reviewType: "",
      clientId: 0,
      mapIconURL: null,
      interfaceIcons: [],
    },
  });

  const [mapKey, setMapKey] = useState({
    loading: true,
    apiKeyConfiguration: null,
  });

  const { interfaceName, location } = useParams(); // props.match.params.interfaceName;

  const pageId = getUrlParameterValue("localpageid");
  const showMode = getUrlParameterValue("mode");
  const interfaceid = getUrlParameterValue("InterfaceId");

  useEffect(() => {
    const setLandingPageConfig = async () => {
      const response = await getLandingPageConfiguration({
        interfaceName: interfaceName,
        interfaceId: interfaceid,
        location: location.replace("$d$", "-"),
        landingPageId: pageId,
        isPreview: showMode && showMode === "preview",
      });

      // We need to manage sprites on svg to support color change
      // We will use local svg for benefits in the future maybe we
      // need to change this and build better solution
      if (response.benefits?.length > 0) {
        response.benefits = response.benefits.map((benefit) => {
          if (!benefit.iconName && benefit.iconUrl) return benefit;
          benefit.iconUrl =
            "/images/benefits/benefit_icons.svg#" + benefit.iconName; // 😒 I don't like this hardcoding path

          // the result of each icon should be something like this /images/benefits/benefit_icons.svg#benefit_lightbulb
          return benefit;
        });
      }

      response.interfaceName = interfaceName;

      if (response.faviconFileUrl) {
        response.faviconFileUrl = fixRelativePath(response.faviconFileUrl);
      }

      setSecurityParameters(response.clientId, response.apiKey);
      setLandingPageConfiguration({ loading: false, config: response });
    };

    setLandingPageConfig();
  }, [interfaceName, location, pageId, showMode, interfaceid]);

  useEffect(() => {
    if (landingPageConfiguration.loading) return;

    const getMapKey = async () => {
      const response = await findClientMappingSettings();

      setMapKey({
        loading: false,
        apiKeyConfiguration: response.MappingSettings[0],
      });
    };

    getMapKey();
  }, [landingPageConfiguration.loading]);

  return (
    !landingPageConfiguration.loading &&
    !mapKey.loading && (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={landingPageConfiguration.config.templateCssURLPath}
          />

          <link
            rel="stylesheet"
            type="text/css"
            href={`${cssBaseURL}/${
              landingPageConfiguration.config.clientId
            }/landing/${interfaceName}${
              interfaceid && interfaceid > 0 ? "." + interfaceid : ""
            }.min.css`}
          />

          <link
            rel="stylesheet"
            type="text/css"
            href={`${cssBaseURL}/${
              landingPageConfiguration.config.clientId
            }/landing/${interfaceName}${
              interfaceid && interfaceid > 0 ? "." + interfaceid : ""
            }.advanced.min.css`}
          />
          <link
            rel="canonical"
            href={`${window.location.href.slice(
              0,
              window.location.href.indexOf("?")
            )}`}
          />
          {landingPageConfiguration.config.faviconFileUrl && (
            <link
              rel="icon"
              type="image/jpg"
              href={landingPageConfiguration.config.faviconFileUrl}
            />
          )}
        </Helmet>

        <LandingPage
          {...props}
          apiKey={mapKey.apiKeyConfiguration}
          landingPageConfiguration={landingPageConfiguration.config}
        />

        {landingPageConfiguration.config.clientId > 0 && (
          <Helmet>
            <script
              src={`${cssBaseURL}/${landingPageConfiguration.config.clientId}/landing/${interfaceName}.advanced.min.js`}
            ></script>
          </Helmet>
        )}
      </>
    )
  );
};

export default GetParameterPage;
