import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import GoogleMapReact from "google-map-react";

import { LanguageContext } from "../../context/language";

import GoogleMapMarker from "./googleMapMarker";

import { getCustomPin } from "../../utils";
import useSupercluster from "use-supercluster";

import PropTypes from "prop-types";

const Marker = ({ children }) => children;

/**
 * Component to display a GoogleMap with a list of Markers
 */
const GoogleMap = ({
  markers,
  googleApikey,
  onMapReady,
  onClick,
  markerSelected,
  showLandingPageLink,
  showLandingPageLinkTarget,
  customPin,
  containerRef,
  showImagesInResults,
  showPopup = true,
  hideControls = false,
  searchOrigin,
  getOriginCenter,
  onDragend,
  doNotFitMap = false,
  interfaceName,
  categoryIcons,
  returnUrlParameters,
  initConfigMap,
  enableClustering = false,
}) => {
  const createMapOptions = () => {
    return {
      minZoom: 1,
      fullscreenControl: !(window.screen.width < 768),
      mapTypeControl: true && !hideControls,
      streetViewControl: true && !hideControls,
      styles: window.bullseyeLocationsConfig?.googleMapStyle
        ? window.bullseyeLocationsConfig.googleMapStyle
        : null,
    };
  };

  const { language } = useContext(LanguageContext);

  const isValidInitialCenterPoint = useMemo(
    () =>
      markers.length > 0 &&
      markers[0].latitude !== 0 &&
      markers[0].longitude !== 0,
    [markers]
  );

  const initPositionMap = useMemo(
    () => ({
      center: {
        lat:
          markers.length > 0
            ? markers[0].latitude
            : initConfigMap.latitude
            ? initConfigMap.latitude
            : 41.850033,
        lng:
          markers.length > 0
            ? markers[0].longitude
            : initConfigMap.longitude
            ? initConfigMap.longitude
            : -87.6500523,
      },
      zoom:
        markers.length === 1 && isValidInitialCenterPoint
          ? 14
          : initConfigMap?.zoom
          ? initConfigMap.zoom
          : 3,
    }),
    [markers, initConfigMap, isValidInitialCenterPoint]
  );

  const [gmap, setGmap] = useState();

  const [currentLocation, setCurrentLocation] = useState({
    latitude: 0,
    longitude: 0,
    marker: { id: 1, locationName: "Your current Location" },
    pin: "https://app.bullseyelocations.com/pages/images/youarehere.gif",
  });

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map) => {
    if (onMapReady) {
      onMapReady(map);
    }
    if (!gmap) {
      setGmap(map);
    }
    // mapRef.current = mapRef;
  };

  // const mapRef = useRef();

  // Return the center when this is calculated
  // this helps for example to the driving distance
  useEffect(() => {
    if (getOriginCenter) {
      getOriginCenter({
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
        formatted_address: currentLocation.formatted_address,
        country: currentLocation.country,
      });
    }

    if (markers.length === 0) return;

    if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
      const bounds = gmap.getBounds();

      bounds.extend(
        new window.google.maps.LatLng(
          currentLocation.latitude,
          currentLocation.longitude
        )
      );
      console.log("Google maps fit bounds");
      gmap.fitBounds(bounds);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (!gmap) return;

    // Get The center of the current Search
    let request = null;

    if (
      searchOrigin?.latitude &&
      searchOrigin?.longitude &&
      searchOrigin.latitude !== 0 &&
      searchOrigin.longitude !== 0
    ) {
      request = {
        location: new window.google.maps.LatLng(
          searchOrigin.latitude,
          searchOrigin.longitude
        ),
      };
    }

    if (searchOrigin?.address && searchOrigin?.country) {
      request = {
        address: searchOrigin?.address,
        componentRestrictions: {
          country: searchOrigin?.country,
        },
      };
    }

    if (!request) return;

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(request, function (results, status) {
      console.log("Request geocode from google ", status, results);

      if (status === window.google.maps.GeocoderStatus.OK) {
        let state, city, country;

        if (results[0]) {
          for (const component of results[0].address_components) {
            for (const type of component.types) {
              if (type === "locality") {
                city = component.short_name;
                break;
              }
              if (type === "administrative_area_level_1") {
                state = component.short_name;
                break;
              }
              if (type === "country") {
                country = component.short_name;
                break;
              }
            }
          }

          setCurrentLocation({
            ...currentLocation,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            formatted_address: city || state ? city + ", " + state : null,
            country: country,
          });
        }
      }
    });
  }, [gmap, searchOrigin]);

  useEffect(() => {
    if (!gmap) return;

    if (markers && markerSelected > 1) {
      const marker = markers.find((obj) => obj.id === markerSelected);

      if (marker && marker.latitude !== 0 && marker.longitude !== 0) {
        // for large screen we will pan to center, for small on the bottom of the map
        // the containerRef is the div that contains the map
        const divHeightOfTheMap = containerRef.current.clientHeight;
        const offSetFromBottom = 50;

        setTimeout(() => {
          gmap.setCenter({ lat: marker.latitude, lng: marker.longitude });

          // Because is clustering we need to make zoom to the
          if (clusters.length > 0) {
            gmap.setZoom(12);
          }

          if (
            containerRef &&
            (window.screen.width < 768 || markers.length === 1)
          ) {
            gmap.panBy(0, -(divHeightOfTheMap / 2 - offSetFromBottom));
          } else {
            gmap.panBy(0, -100);
          }
        }, 250);
      }
    }
  }, [markerSelected]);

  useEffect(() => {
    if (!gmap) return;
    window.map = gmap;

    // For this part we need to have markers
    if (!markers || markers.length <= 0 || doNotFitMap) return;

    // Fit map to bounds
    if (markers.length > 0 && markerSelected < 1) {
      const bounds = new window.google.maps.LatLngBounds();

      markers.forEach((marker) => {
        if (marker.latitude !== 0 || marker.longitude !== 0) {
          bounds.extend(
            new window.google.maps.LatLng(marker.latitude, marker.longitude)
          );
        }
      });

      if (markers.length === 1) {
        if (markers[0].latitude !== 0 || markers[0].longitude !== 0) {
          gmap.setZoom(13);
          gmap.panTo({ lat: markers[0].latitude, lng: markers[0].longitude });
        }
      } else {
        // The setTimeout is used because if not the markers get cutoff
        setTimeout(() => {
          gmap.fitBounds(bounds);
        }, 250);
      }

      if (gmap.getZoom() > 12) {
        gmap.setZoom(14);
      }
    }
  }, [gmap, markers]);

  // get map bounds
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);

  // clustering
  const { clusters, supercluster } = useSupercluster({
    points: enableClustering
      ? markers
          .filter(
            (x) =>
              x.latitude && x.longitude && x.latitude !== 0 && x.longitude !== 0
          )
          .map((marker) => ({
            type: "Feature",
            id: marker.id,
            properties: {
              cluster: true,
              marker,
            },
            geometry: {
              type: "Point",
              coordinates: [marker.longitude, marker.latitude],
            },
          }))
      : [],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 6, minPoints: 3 },
  });

  const clusterColors = [
    "#008CFF",
    "#FFBF00",
    "#FFBF00",
    "#FFBF00",
    "#FF00BE",
    "#FF00BE",
    "#FF0000",
    "#FF0000",
  ];

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleApikey,
          libraries: ["places"],
          language: language,
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
        defaultCenter={initPositionMap.center}
        defaultZoom={initPositionMap.zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => apiIsLoaded(map)}
        showInfo={true}
        onDragEnd={(map) => {
          if (enableClustering) return;

          const latitude = map.getCenter().lat();
          const longitude = map.getCenter().lng();
          if (onDragend) {
            onDragend({
              latitude,
              longitude,
            });
          }
        }}
      >
        {enableClustering
          ? clusters.map((cluster) => {
              // Get custom pins
              const marker = cluster.properties.marker;

              const locationCustomIcon = getCustomPin(
                categoryIcons,
                marker && marker.categoryIds?.split(",").map((x) => parseInt(x))
              );
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster.properties;

              if (isCluster && pointCount) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <div
                      className="cluster-marker"
                      style={{
                        width: `${30 + (pointCount / markers.length) * 60}px`,
                        height: `${30 + (pointCount / markers.length) * 60}px`,
                        backgroundColor:
                          clusterColors[
                            Math.ceil((pointCount / markers.length) * 8) - 1
                          ],
                      }}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        );
                        gmap.setZoom(expansionZoom);
                        gmap.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              }

              return (
                marker.latitude !== 0 &&
                marker.longitude !== 0 && (
                  <GoogleMapMarker
                    key={marker.id}
                    lat={marker.latitude}
                    lng={marker.longitude}
                    pin={
                      locationCustomIcon
                        ? locationCustomIcon
                        : customPin
                        ? customPin
                        : "/images/beIcon.png"
                    }
                    marker={marker}
                    showInfoWindow={markerSelected === marker.id}
                    showLandingPageLink={showLandingPageLink}
                    showLandingPageLinkTarget={showLandingPageLinkTarget}
                    onClick={(marker) => {
                      /** function to send the Id selected to the parent */
                      if (onClick) onClick(marker.id);
                    }}
                    showImagesInResults={showImagesInResults}
                    showPopup={showPopup}
                    interfaceName={interfaceName}
                    returnUrlParameters={returnUrlParameters}
                    showDirectionsLink={
                      window?.bullseyeLocationsConfig?.showDirectionsLink ??
                      true
                    }
                  ></GoogleMapMarker>
                )
              );
            })
          : markers.map((marker) => {
              const locationCustomIcon = getCustomPin(
                categoryIcons,
                marker && marker.categoryIds?.split(",").map((x) => parseInt(x))
              );

              return (
                marker.latitude !== 0 &&
                marker.longitude !== 0 && (
                  <GoogleMapMarker
                    key={marker.id}
                    lat={marker.latitude}
                    lng={marker.longitude}
                    pin={
                      locationCustomIcon
                        ? locationCustomIcon
                        : customPin
                        ? customPin
                        : "/images/beIcon.png"
                    }
                    marker={marker}
                    showInfoWindow={markerSelected === marker.id}
                    showDirectionsLink={
                      window?.bullseyeLocationsConfig?.showDirectionsLink ??
                      true
                    }
                    showLandingPageLink={showLandingPageLink}
                    showLandingPageLinkTarget={showLandingPageLinkTarget}
                    onClick={(marker) => {
                      /** function to send the Id selected to the parent */
                      if (onClick) onClick(marker.id);
                    }}
                    showImagesInResults={showImagesInResults}
                    showPopup={showPopup}
                    interfaceName={interfaceName}
                    returnUrlParameters={returnUrlParameters}
                  ></GoogleMapMarker>
                )
              );
            })}
        {/* my current location, the little grey spot */}
        {currentLocation.latitude !== 0 && currentLocation.longitude !== 0 && (
          <GoogleMapMarker
            key={currentLocation.marker.id}
            lat={currentLocation.latitude}
            lng={currentLocation.longitude}
            pin={currentLocation.pin}
            marker={currentLocation.marker}
            showInfoWindow={markerSelected === currentLocation.marker.id}
            showDirectionsLink={
              window?.bullseyeLocationsConfig?.showDirectionsLink ?? true
            }
            onClick={(marker) => {
              /** function to send the Id selected to the parent */
              if (onClick) onClick(marker.id);
            }}
          ></GoogleMapMarker>
        )}
      </GoogleMapReact>
    </>
  );
};
GoogleMap.propTypes = {
  /** List of location with at least the following attributes */
  markers: PropTypes.arrayOf(
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** location Id */
      id: PropTypes.number.isRequired,
      /** coords of location */
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      /** name of the location */
      locationName: PropTypes.string,
      /** address of the location */
      address1: PropTypes.string,
      /** city of the address */
      city: PropTypes.string,
      /** state of city */
      state: PropTypes.string,
      /** postal code of the location */
      postalCode: PropTypes.string,
      /** country of this location */
      country: PropTypes.string,
      /** url of the lading page detail of this location */
      URL: PropTypes.string,
    })
  ),
  /** Google Api Key to get map */
  googleApikey: PropTypes.string.isRequired,
  /** callback to set the map is ready */
  onMapReady: PropTypes.func,
  /** function to on click on one item to the list will be selected into the parent */
  onClick: PropTypes.func,
  /** if the parent send some item pre selected */
  markerSelected: PropTypes.number.isRequired,
  /** If present the control will replace the default for this url */
  customPin: PropTypes.string,
  /** Show landing page link on the name of the location */
  showLandingPageLink: PropTypes.bool.isRequired,
};

GoogleMap.defaultProps = {
  /** Default value for showLandingPageLink flag is true */
  showLandingPageLink: false,
  /** for internal control of the selected marker */
  markerSelected: 0,
  /** Default value to show image inside popup */
  showImagesInResults: false,
};

export default GoogleMap;
