import React from "react";

const NotFound = () => {
  return (
    <div className="d-flex">
      <div className="m-5 d-none d-md-block">
        <img src="/images/bullseye-white-icon.png" alt="bullseye icon white" />
      </div>
      <div>
        <div className="my-5 d-block">
          <img
            src="/images/bullseye_logo_outlined.svg"
            alt="bullseye logo"
            style={{ height: 85 }}
          />
        </div>
        <h1 className="my-4 font-weight-500 bullseye-red">We're Sorry...</h1>
        <p>The content you are looking for couldn't be found.</p>

        <p>
          Site administrators can{" "}
          <a href="https://app.bullseyelocations.com/Admin/Login.aspx">LOGIN</a>
          , visit our website at{" "}
          <a href="https://www.bullseyelocations.com">
            https://www.bullseyelocations.com
          </a>
          , or email{" "}
          <a href="mailto:info@bullseyelocations.com">
            info@bullseyelocations.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
