import React from "react";
import PropTypes from "prop-types";
import "./multiOptionsInput.scss";
const OptionInput = ({
  id,
  name,
  type = "radio",
  value,
  label,
  register,
  inline = false,
  required = false,
}) => {
  return (
    <div>
      <div className="form-check ">
        <input
          className="form-check-input multiOptionsInput__sizeRadiosAndCheckBox "
          ref={register}
          type={type}
          name={name}
          id={id}
          value={label}
          aria-required={required || null}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
};

OptionInput.prototype = {
  /** name of the controll input */
  name: PropTypes.string.isRequired,
  /** type of the multiOption Radio or CheckBox */
  type: PropTypes.string.isRequired,
  /** default value */
  value: PropTypes.string,
  /** string of the Input Label */
  label: PropTypes.string.isRequired,

  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
};

export default OptionInput;
