import React from "react";

const NotConfigured = () => {
  return (
    <div className="d-flex">
      <div className="m-5 d-none d-md-block">
        <img src="/images/bullseye-white-icon.png" alt="bullseye icon white" />
      </div>
      <div>
        <div className="my-5 d-block">
          <img
            src="/images/bullseye_logo_outlined.svg"
            alt="bullseye logo"
            style={{ height: 85 }}
          />
        </div>

        <h5 className="bullseye-red">
          There is no current locator configured at this URL.
        </h5>
        <p>
          For more information please visit our website at{" "}
          <a href="https://www.bullseyelocations.com">
            https://www.bullseyelocations.com
          </a>
          , or email{" "}
          <a href="mailto:info@bullseyelocations.com">
            info@bullseyelocations.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default NotConfigured;
