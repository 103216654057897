export enum FormSubmissionType {
  DisplayMessage = 1,
  RedirectURL = 2,
  RedirectEmbeddedInterface = 3,
}

export enum CountryListIds {
  NO_COUNTRY = 0,
  USA = 1,
  CANADA = 2,
  UK = 88,
}

export const InputValidationPatter = {
  // eslint-disable-next-line no-useless-escape
  url: /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
  imageURL: /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
};

export enum GeocodingServices {
  Google = 1,
  Mapbox = 2,
  BEGoogle = 3,
  BEMapbox = 4,
  Geonames = 46,
}

export enum EventLogType {
  logSearch = 1,
}

export enum SearchResponseLogType {
  urlClicked = 1,
  emailClicked = 2,
  locationResultsClicked = 3,
  locationMapClicked = 4,
  directionsClicked = 5,
  promotionPrint = 6,
  promotionShare = 7,
  locationPromotionPrint = 8,
  attributeClicked = 9,
  phoneNumberClicked = 10,
  moreInfoClicked = 11,
}

export enum SearchType {
  radius = 1,
  territory = 2,
  literal = 3,
  country = 4,
  territoryOrRadius = 5,
  territoryAndRadius = 6,
  internetOnly = 7,
  internationalRadius = 8,
  mapLocations = 9,
  locationGroupsLocations = 10,
  territoryLocations = 11,
  companyNameLocations = 12,
  territoryStateOnly = 13,
}
