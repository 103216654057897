import axios from "axios";
// import globalConfigProp from "../config.json";

const getIPAddressURL = "https://www.cloudflare.com/cdn-cgi/trace";
const getGeoCodeFromIPURL = `${process.env.REACT_APP_URL_WRAPPER}/GeoConfiguration/GetGeoLocationIP?ip=`;
//"https://honaero2.bullseyelocations.com/api/map/GetIPLocation?ip=";
// const AddressByIp = "https://geoip.maxmind.com/geoip/v2.1/city";
// https://wswrapper.bullseyelocations.com/GeoConfiguration/GetGeoLocationIP?ip=181.174.107.224

export async function GetIpAddress() {
  //axios.defaults.params = {};

  const ip = await axios.get(getIPAddressURL);
  return ip.data;
}

export async function GetLatLngByIP(ipAddress) {
  //axios.defaults.params = {};

  try {
    const ipResult = await axios.get(`${getGeoCodeFromIPURL}${ipAddress}`);

    return ipResult.data;
  } catch (error) {
    console.error("Authenticated", error);
  }

  return null;
}
