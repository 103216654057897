import React from "react";
//import "google-maps-reviews/google-maps-reviews.css";
import Reviews from "../reviews/reviews";
import PropTypes from "prop-types";
import {
  getDaysAgoFromToday,
  sortArrayReviewsByDateDesc,
} from "../../utils/utils";
/**
 * BullsEye Reviews Components, shows all the reviews from a location
 */
const BullseyeReviews = ({
  title,
  subTitle,
  extraTitle,
  maxItemsToDisplay,
  reviews,
  onSubmit,
  reviewConfiguration,
  submitReviews,
}) => {
  let sumRating = reviews.reduce(function (prev, cur) {
    return prev + cur.OverallRating;
  }, 0);

  reviews = sortArrayReviewsByDateDesc(reviews);
  let listReviews = {
    rating: reviews.length > 0 ? parseInt(sumRating / reviews.length) : 0,
    countReviews: reviews.length,
    reviews: reviews.map((review) => {
      return {
        author_name: review.FirstName + " " + review.LastName,
        rating: review.OverallRating,
        relative_time_description: getDaysAgoFromToday(review.DateCreated),
        text: review.Comments,
        recommend: review.Recommend,
        services: [
          { name: "Professionalism", rating: review.RatingCategoryOne },
          { name: "Product Knowledge", rating: review.RatingCategoryTwo },
          { name: "Service", rating: review.RatingCategoryThree },
        ],
      };
    }),
  };
  
  return (
    <>
      <Reviews
        title={title}
        subTitle={subTitle}
        extraTitle={extraTitle}
        maxItemsToDisplay={maxItemsToDisplay}
        listReviews={listReviews}
        isBullseyeReviews={true}
        onSubmit={(data) => onSubmit(data)}
        reviewConfiguration={reviewConfiguration}
        submitReviews={submitReviews}
      />
    </>
  );
};
BullseyeReviews.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string.isRequired,
  /** text for additional title in the new review writing form */
  subTitle: PropTypes.string,
  /** text for additional extra title in the new review writing form*/
  extraTitle: PropTypes.string,
  /** number that defines how many reviews can be displayed, by default it is 3 */
  maxItemsToDisplay: PropTypes.number,
  /** List of reviews to display */
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      /**Review author name */
      FirstName: PropTypes.string.iRequired,
      /**Review author last name */
      LastName: PropTypes.string.iRequired,
      /** Review rating */
      OverallRating: PropTypes.number.isRequired,
      /** Date the review was made */
      DateCreated: PropTypes.instanceOf(Date).isRequired,
      /** Review comments */
      Comments: PropTypes.string.isRequired,
      /** Field that assigns 1 if it recommends the place or 0 if not */
      Recommend: PropTypes.number,
      /** Category one rating */
      RatingCategoryOne: PropTypes.number,
      /** Category two rating */
      RatingCategoryTwo: PropTypes.number,
      /** Category three rating */
      RatingCategoryThree: PropTypes.number,
    })
  ),
  /** reviews configurations */
  reviewConfiguration: PropTypes.shape({
    /** if you want an option to write review */
    allowLocationManageReviews: PropTypes.bool.isRequired,
    /** if you want to enable rating of services over category one */
    ratingCategoryOne: PropTypes.bool.isRequired,
    /** if you want to enable rating of services over category two */
    ratingCategoryThree: PropTypes.bool.isRequired,
    /** if you want to enable rating of services over category three */
    ratingCategoryTwo: PropTypes.bool.isRequired,
    /** if you want to enable the option to recommend the place */
    recommendationQuestion: PropTypes.bool.isRequired,

    /** if you want to show the comments on the review */
    comments: PropTypes.bool.isRequired,
    /** if you want to show the rating on the review */
    overallRating: PropTypes.bool.isRequired,
    /** if you want to show the Author on the review */
    reviewerName: PropTypes.bool.isRequired,
  }),
  /** Function that receives the information of the form for a new review, for this the option must be enabled in the configuration */
  onSubmit: PropTypes.func.isRequired,
};

// Specifies the default values for props:
BullseyeReviews.defaultProps = {
  maxItemsToDisplay: 3,
};

export default BullseyeReviews;
