import React from "react";
import "./genericGrid.scss";
import LinkButton from "../linkButton/linkButton";

const GenericGrid = ({ containerClass, data, onMoreInfoClick }) => {
  const displayHeader = data.columns.map((col, index) => {
    return (
      <th scope="col" key={`${col}.${index}`}>
        <div dangerouslySetInnerHTML={{ __html: col }} />
      </th>
    );
  });

  const displayData = data.data
    .sort((a, b) => a.order - b.order)
    .map((info, index) => {
      let arrayData = Object.keys(info).filter((item) => item !== "order");
      console.log("array Data: ", arrayData);
      console.log("array Data[0]: ", info["treatment"]);

      const boldStyle =
        info["treatment"] &&
        (info["treatment"].includes("Utrisha") ||
          info["treatment"].includes("LumiGEN") ||
          info["treatment"].includes("Fungicide"))
          ? "bold"
          : "normal";

      return (
        <tr key={index} style={{ fontWeight: boldStyle }}>
          {arrayData.map((key, i) => {
            return (
              <td key={`${info[key]}.${i}`}>
                {info[key] === "onCustomClick" && (
                  <LinkButton
                    // iconPosition={"top"}
                    className="bullseye-btn-outline moreInfoBtn"
                    url={""}
                    title={"More Info"}
                    isEventCancelable={true}
                    onClickButton={(e) => {
                      onCustomClick(data.fullData[index]);
                    }}
                  />
                )}
                {key !== "order" && (
                  <div
                    className={
                      boldStyle === "bold" &&
                      key === "yieldAdvantage" &&
                      Number(info[key]) > 0
                        ? "classrow-yieldAdvantage"
                        : ""
                    }
                    dangerouslySetInnerHTML={{ __html: info[key] }}
                  />
                )}
              </td>
            );
          })}
        </tr>
      );
    });

  const onCustomClick = (data) => {
    onMoreInfoClick(data);
  };

  return (
    <div
      className={`genericGrid__container table-responsive ${containerClass}`}
    >
      {data && (
        <table className="table table-sm table-striped table-hover">
          <thead>
            <tr className="bg-primary">{displayHeader}</tr>
          </thead>
          <tbody>{displayData}</tbody>
        </table>
      )}
    </div>
  );
};

export default GenericGrid;
