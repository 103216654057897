import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { date1IsEqualsOrGreaterThanDate2 } from "../../../utils/utils";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import Label from "../label/label";

const DateTimeRange = ({
  name,
  label,
  compact,
  small,
  errors,
  register,
  onChange,
  required,
  validations,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const onChangevalue = (date, e, isEnDate = false) => {
    if (!isEnDate) setStartDate(date);
    else setEndDate(date);
  };

  return (
    <div className="form-group">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <div className="d-flex align-content-stretch flex-wrap">
        <ReactDatePicker
          selected={startDate}
          className={`form-control p-2 ${small ? "form-control-sm" : ""} ${
            errors["startDate"] ? "is-invalid" : ""
          } `}
          name="startDatePicker"
          onChange={(date, e) => onChangevalue(date, e)}
          startDate={startDate}
          endDate={endDate}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MM/d/yyyy HH:mm"
          openToDate={new Date()}
          selectsStart
          minDate={new Date()}
          autoComplete={"off"}
          placeholderText={"Start date"}
        />
        <input
          id={"startDate"}
          type={"hidden"}
          value={startDate}
          name="startDate"
          ref={register(validations)}
          onChange={onChange}
        />
        {errors["startDate"] && !startDate && (
          <ErrorDisplay
            errorMessage={errors["startDate"]?.message}
            isAllCols={true}
          />
        )}

        <div className="align-self-center p-2">to</div>
        <ReactDatePicker
          selected={endDate}
          className={`form-control p-2 ${small ? "form-control-sm" : ""} ${
            errors["endDate"] ? "is-invalid" : ""
          } `}
          name="endDatePicker"
          onChange={(date, e) => onChangevalue(date, e, true)}
          startDate={startDate}
          endDate={endDate}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MM/d/yyyy HH:mm"
          openToDate={new Date()}
          selectsEnd
          minDate={startDate}
          autoComplete={"off"}
          placeholderText={"End date"}
        />
        <input
          id={"endDate"}
          type={"hidden"}
          value={endDate}
          name="endDate"
          ref={register({
            validate: (endDate) =>
              !endDate
                ? true
                : date1IsEqualsOrGreaterThanDate2(endDate, startDate) ||
                  "The end date must be greater than the start date",
          })}
          onChange={onChange}
        />
        {errors["endDate"] && (
          <ErrorDisplay
            errorMessage={errors["endDate"]?.message}
            isAllCols={true}
          />
        )}
      </div>
    </div>
  );
};
export default DateTimeRange;
