import React from "react";
import PropTypes from "prop-types";
import Address from "../address/Address";
import "./nearbyLocations.scss";

/** Component to display the nearly locations.
 *  params: Title-> title to display.
 *          nearbyLocationsList-> list of locations to display
 *          information per location necessary to this component
 *
 *          -Address1
 *          -City
 *          -State
 *          -Zip Code
 *          -Distance
 *
 */
const NearbyLocations = ({ title, nearbyLocationsList, customPin }) => {
  return (
    <>
      <div className="bullseye-red h4">{title}</div>
      <div className="d-flex flex-wrap nearbyLocations_container">
        {nearbyLocationsList.map((location) => {
          return (
            <div
              key={location.id}
              className="nearbyLocations__card--maxSize my-2 d-flex border-bottom"
            >
              <div className=" float-left mt-n2">
                <img
                  className="mx-auto p-2 d-block"
                  src={customPin ? customPin : "/images/beIcon.png"}
                  alt={location.locationName}
                  style={{ maxHeight: "40px" }}
                />
              </div>
              <div>
                <strong>{location.locationName}</strong>
                <Address
                  address1={location.address1}
                  city={location.city}
                  stateAbbr={location.state}
                  postalCode={location.postalCode}
                  country={location.country}
                  shortAddress={true}
                ></Address>
                <p className="font-italic text-muted">
                  {location.distance ?? 0} miles away
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

NearbyLocations.propTypes = {
  /** Title component  */
  title: PropTypes.string.isRequired,

  nearbyLocationsList: PropTypes.arrayOf(
    /** Object with the location information */
    PropTypes.shape({
      address1: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      stateAbbr: PropTypes.string,
      postalCode: PropTypes.string,
      locationName: PropTypes.string.isRequired,
      distance: PropTypes.number.isRequired,
    })
  ),
  /** If present the control will replace the default for this url */
  customPin: PropTypes.string,
};

export default NearbyLocations;
