import React, { useEffect, useContext, Suspense } from "react";

/** Components */
//import ListMapLocator from "./listMapLocator";

import { Helmet } from "react-helmet-async";

/** Utilities */
import { setSecurityParameters } from "../../../services/httpService";
import {
  getValidQuerystringParams,
  getQueryStringParam,
  validRadiusSearches,
} from "../../../utils/bullseyeUtils";
import { getUserCountry } from "../../../utils/bullseyeUtils2";
import { SEARCH_TYPE, GEOCODE_SERVICES } from "../../../utils/constants";
import { isMobile } from "react-device-detect";

/** Hooks */
import { useClientConfiguration } from "../../../hooks/useClientConfiguration";
import { useParams, useHistory } from "react-router-dom";
import useGeoLocation from "../../../hooks/useGeolocation";
import { useUniversalGeocoder } from "../../../hooks/useUniversalGeocoder";

import { SearchContext } from "../../../context/searchContext";
import * as Sentry from "@sentry/react";
import { LanguageContext } from "../../../context/language";
import { SearchContext2 } from "../../../context/search";

/** Environment Variables */
const homepage = process.env.REACT_APP_VIRTUAL_PATH;

const ListMapLocator = React.lazy(() => import("../listMapLocator"));

// const getUserCountry = (
//   countryCode,
//   countryName,
//   countryId,
//   countryArray,
//   countryRegions
// ) => {
//   // This will search if the countryID in the pulled from querystring or
//   // the detected country in the maxmind exists in the configuration countries
//   let country = "";
//   let countryRegion = null;

//   if (countryCode || countryName || countryId) {
//     /** Search in the configuration if the user country or the country in
//      * the Querystring is configured
//      */
//     const countryFromConfig = countryArray.find(
//       (c) =>
//         c.name === countryName || c.id === countryId || c.code === countryCode
//     );

//     if (countryFromConfig) {
//       country = countryFromConfig.id;
//     } else if (countryArray.length === 1) {
//       country = countryArray[0].id;
//     }

//     countryRegion = countryRegions.find((x) => x.countryId === country);
//   }

//   return [country, countryRegion];
// };

const GetMapListConfiguration = (props) => {
  // Get parameters from URL
  const { interfaceName } = useParams();
  const { setParameters } = useContext(SearchContext2);

  const queryString = props.location.search;

  Sentry.setTag("interface_name", interfaceName);

  const languageCode = getQueryStringParam(queryString, "langcode");
  let radius = getQueryStringParam(queryString, "radius");
  const ip = getQueryStringParam(queryString, "ip");
  const interfaceId = getQueryStringParam(queryString, "interfaceId");
  const categories = getQueryStringParam(queryString, [
    "categories",
    "category",
    "categoryids",
  ]);

  const searchParams = getValidQuerystringParams(queryString);
  const searchContext = useContext(SearchContext);
  const { setClientTerms } = useContext(LanguageContext);

  /** Convert to int the queryString radius */
  if (radius) {
    radius = parseInt(radius, 10);
  }

  const history = useHistory();
  const clientConfig = useClientConfiguration(
    interfaceName,
    "",
    languageCode,
    interfaceId
  );

  //Call hook to get geolocation; if no search parameter is passed in
  // https://developers.google.com/web/fundamentals/native-hardware/user-location
  const [geoLocation] = useGeoLocation(
    isMobile,
    clientConfig.useIPDetection &&
      Object.keys(searchParams).length === 0 &&
      !searchParams.error &&
      !clientConfig.maxMindFlag,
    ip,
    !clientConfig.loading
  );

  if (
    clientConfig.configClientSearch.clientId > 0 &&
    clientConfig.configClientSearch.apiKey
  ) {
    setSecurityParameters(
      clientConfig.configClientSearch.clientId,
      clientConfig.configClientSearch.apiKey
    );
  }

  let serviceKey = null;
  let geocodingService = null;

  if (
    !clientConfig.loading &&
    clientConfig.mappingSettings &&
    clientConfig.useIPDetection
  ) {
    serviceKey =
      clientConfig?.mappingSettings[0].geocodingServiceID ===
        GEOCODE_SERVICES.google ||
      clientConfig?.mappingSettings[0].geocodingServiceID ===
        GEOCODE_SERVICES.be_google
        ? clientConfig?.mappingSettings[0]?.apiKey
        : clientConfig?.mappingSettings[0]?.token;
    geocodingService = clientConfig?.mappingSettings[0].geocodingServiceID;
  }

  // If any of the category filter is mandatory don't make the autosearch
  let categoryRequired =
    clientConfig?.interfaceCategoryGroups?.filter((x) => x.filterRequired)
      .length > 0;

  // We are only do this for mobile
  const currentPosition = useUniversalGeocoder(
    null,
    isMobile ? geoLocation.coordinates.lat : null,
    isMobile ? geoLocation.coordinates.lng : null,
    geocodingService,
    serviceKey,
    geoLocation.coordinates?.country,
    languageCode,
    geoLocation.hasError
  );

  // This is trigged when mobile/GPS is enabled
  useEffect(() => {
    searchContext.setCurrentLocation({
      ...currentPosition,
      hasError: geoLocation.hasError,
      error: geoLocation.error,
    });
    if (isMobile && searchContext.searchParameters.loading) {
      if (!clientConfig.useIPDetection && !clientConfig.loading) {
        // TEST AC
        setParameters({
          radius: radius ?? clientConfig.selectedRadius,
          categoryIDs: categories,
          locationGroupIds:
            clientConfig.locationGroups?.length > 0
              ? clientConfig.locationGroups.map((x) => x.locationGroupID)
              : null,
          _reference: "A1",
        });

        searchContext.setSearchParameters({
          countryId: null,
          radius: radius ?? clientConfig.selectedRadius,
          loading: false,
          ready: clientConfig.autoSearch && !categoryRequired,
          postalCode: null,
          latitude: null,
          longitude: null,
          city: null,
          state: null,
          searchDisplayText: null,
          categoryIDs: categories,
          locationGroupIds:
            clientConfig.locationGroups?.length > 0
              ? clientConfig.locationGroups.map((x) => x.locationGroupID)
              : null,
        });
      }

      if (currentPosition.geocode_success && !currentPosition.loading) {
        const [countryFromUser, countryRegion] = getUserCountry(
          currentPosition.country,
          currentPosition.country,
          0,
          clientConfig.countries,
          clientConfig.countryRegions
        );

        if (
          !clientConfig.showAllLocations &&
          clientConfig.showAllLocationsPerCountry &&
          !clientConfig.showNearestLocationsInList &&
          countryRegion?.searchTypeId === SEARCH_TYPE.radius
        ) {
          console.log("Search by country activate");
          // const territorySearch =
          //   countryRegion?.searchTypeId === SEARCH_TYPE.territory;

          setParameters({
            countryId: countryFromUser,
            radius: null,
            postalCode: null,
            // postalCode: territorySearch
            //   ? geoLocation.coordinates?.postalCode
            //   : null,
            latitude: null,
            longitude: null,
            city: null,
            state: null,
            // city: territorySearch ? geoLocation.coordinates?.city : null,
            // state: territorySearch ? geoLocation.coordinates?.state : null,
            categoryIDs: categories,
            locationGroupIds: null,
            searchTypeId: countryRegion?.searchTypeId,
            _reference: "A7",
          });

          searchContext.setSearchParameters({
            countryId: countryFromUser,
            radius: null,
            loading: false,
            ready: clientConfig.autoSearch && !categoryRequired,
            postalCode: null,
            latitude: null,
            longitude: null,
            city: null,
            state: null,
            searchDisplayText: currentPosition.formatted_address,
            searchTypeId: countryRegion?.searchTypeId,
            categoryIDs: categories,
          });
        } else {
          setParameters({
            countryId: countryFromUser,
            radius: radius ?? clientConfig.selectedRadius,
            latitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lat
              : null,
            longitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lng
              : null,
            city: currentPosition.city,
            state: currentPosition.state,
            categoryIDs: categories,
            locationGroupIds:
              clientConfig.locationGroups?.length > 0
                ? clientConfig.locationGroups.map((x) => x.locationGroupID)
                : null,
            _reference: "A2",
          });

          searchContext.setSearchParameters({
            countryId: countryFromUser,
            radius: radius ?? clientConfig.selectedRadius,
            loading: false,
            ready: clientConfig.autoSearch && !categoryRequired,
            postalCode: null,
            latitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lat
              : null,
            longitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lng
              : null,
            city: currentPosition.city,
            state: currentPosition.state,
            searchDisplayText: currentPosition.formatted_address,
            categoryIDs: categories,
            locationGroupIds:
              clientConfig.locationGroups?.length > 0
                ? clientConfig.locationGroups.map((x) => x.locationGroupID)
                : null,
          });
        }
      }
    }
  }, [currentPosition]);

  useEffect(() => {
    /** if we still don't have the configuration do nothing */
    if (clientConfig.loading) return;

    // If no countries are specify in the configuration that means,
    // there is locations in the account or for this interface this
    // will redirect to the Not Found locations  error page
    if (clientConfig.countries.length === 0) {
      history.push(homepage + "error/no-locations");
    }

    setClientTerms(languageCode ?? "en", clientConfig.clientTerm);

    /** We are going to set parameters using  */
    if (geoLocation.loaded) {
      // This will search if the countryID in the pulled from querystring or
      // the detected country in the maxmind exists in the configuration countries
      let countryFromUser = "";
      let countryRegion = null;

      if (
        geoLocation.coordinates.country ||
        geoLocation.coordinates.countryName ||
        searchParams?.countryId
      ) {
        /** Search in the configuration if the user country or the country in
         * the Querystring is configured
         */
        // [countryFromUser, countryRegion] = getUserCountry(
        //   currentPosition.country,
        //   currentPosition.country,
        //   searchParams?.countryId,
        //   clientConfig.countries,
        //   clientConfig.countryRegions
        // );
        const countryFromConfig = clientConfig.countries.find((c) => {
          if (searchParams?.countryId) return c.id === searchParams?.countryId;

          return (
            c.name === geoLocation.coordinates.countryName ||
            c.code === geoLocation.coordinates?.country
          );
        });

        if (countryFromConfig) {
          countryFromUser = countryFromConfig.id;
        } else if (clientConfig.countries.length === 1) {
          countryFromUser = clientConfig.countries[0].id;
        }

        countryRegion = clientConfig.countryRegions.find(
          (x) => x.countryId === countryFromUser
        );
      } else if (isMobile && clientConfig.countries.length === 1) {
        // this if will happend if the user block the GPS on mobile
        // and we only have one country
        countryFromUser = clientConfig.countries[0].id;

        countryRegion = clientConfig.countryRegions.find(
          (x) => x.countryId === countryFromUser
        );
      }

      // This mean we have default parameters and we won't use any of the
      // Parameters on the user location
      if (Object.keys(searchParams).length > 0) {
        let searchDisplayText = "";

        if (searchParams?.city || searchParams?.state) {
          if (searchParams?.city && searchParams?.state) {
            searchDisplayText = searchParams?.city + ", " + searchParams?.state;
          } else {
            searchDisplayText = searchParams?.city ?? searchParams?.state;
          }
        } else {
          searchDisplayText = searchParams?.postalCode;
        }

        setParameters({
          postalCode: searchParams?.postalCode,
          countryId: countryFromUser,
          radius: searchParams?.radius ?? radius ?? clientConfig.selectedRadius,
          keyword: searchParams?.keyword,
          city: searchParams?.city,
          state: searchParams?.state,
          categoryIDs: searchParams?.categoryids,
          locationGroupIds:
            clientConfig.locationGroups?.length > 0
              ? clientConfig.locationGroups.map((x) => x.locationGroupID)
              : null,
          _reference: "A3",
        });

        searchContext.setSearchParameters({
          postalCode: searchParams?.postalCode,
          countryId: countryFromUser,
          radius: searchParams?.radius ?? radius ?? clientConfig.selectedRadius,
          keyword: searchParams?.keyword,
          city: searchParams?.city,
          state: searchParams?.state,
          distanceUnit: searchParams?.distanceUnit,
          searchDisplayText: searchDisplayText,
          latitude: null,
          longitude: null,
          loading: false,
          ready: true,
          categoryIDs: searchParams?.categoryids,
          locationGroupIds:
            clientConfig.locationGroups?.length > 0
              ? clientConfig.locationGroups.map((x) => x.locationGroupID)
              : null,
        });
      } else {
        if (
          isMobile &&
          (geoLocation.coordinates.lat !== 0 ||
            geoLocation.coordinates.lng !== 0)
        ) {
          return;
        }

        let searchDisplayText = "";

        if (
          !clientConfig.useZipCode &&
          geoLocation.coordinates?.city &&
          geoLocation.coordinates?.state
        ) {
          searchDisplayText =
            geoLocation.coordinates.city + ", " + geoLocation.coordinates.state;
        } else if (geoLocation.coordinates?.postalCode) {
          searchDisplayText = geoLocation.coordinates.postalCode;
        }

        // This will show trigger a country search for the list
        if (
          !clientConfig.showAllLocations &&
          clientConfig.showAllLocationsPerCountry &&
          !clientConfig.showNearestLocationsInList &&
          countryRegion?.searchTypeId === SEARCH_TYPE.radius
        ) {
          console.log("Search by country activate");
          // const territorySearch =
          //   countryRegion?.searchTypeId === SEARCH_TYPE.territory;

          setParameters({
            countryId: countryFromUser,
            radius:
              !countryRegion ||
              countryRegion?.searchTypeId === SEARCH_TYPE.radius
                ? radius ?? clientConfig.selectedRadius
                : null,
            // postalCode: territorySearch
            //   ? geoLocation.coordinates?.postalCode
            //   : null,

            // city: territorySearch ? geoLocation.coordinates?.city : null,
            // state: territorySearch ? geoLocation.coordinates?.state : null,
            categoryIDs: categories,
            locationGroupIds: null,
            searchTypeId: countryRegion?.searchTypeId,
            _reference: "A6",
          });

          searchContext.setSearchParameters({
            countryId: countryFromUser,
            radius:
              !countryRegion ||
              countryRegion?.searchTypeId === SEARCH_TYPE.radius
                ? radius ?? clientConfig.selectedRadius
                : null,
            loading: false,
            ready: clientConfig.autoSearch && !categoryRequired,
            searchDisplayText: searchDisplayText,
            searchTypeId: countryRegion?.searchTypeId,
            categoryIDs: categories,
          });
        } else {
          setParameters({
            countryId: countryFromUser,
            radius: radius ?? clientConfig.selectedRadius,
            postalCode:
              clientConfig.useZipCode &&
              countryRegion?.searchTypeId !== SEARCH_TYPE.country
                ? geoLocation.coordinates.postalCode
                : null,
            latitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lat
              : null,
            longitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lng
              : null,
            city: !clientConfig.useZipCode
              ? geoLocation.coordinates.city
              : null,
            state: !clientConfig.useZipCode
              ? geoLocation.coordinates.state
              : null,
            categoryIDs: categories,
            locationGroupIds:
              clientConfig.locationGroups?.length > 0
                ? clientConfig.locationGroups.map((x) => x.locationGroupID)
                : null,
            searchTypeId: countryRegion?.searchTypeId,
            _reference: "A4",
          });

          /** If there is not parameters we are going to send only the Lat and Lon
           *  detected on the getLocation using the MaxMind */

          searchContext.setSearchParameters({
            countryId: countryFromUser,
            radius: radius ?? clientConfig.selectedRadius,
            loading: false,
            ready: clientConfig.autoSearch && !categoryRequired,
            postalCode:
              clientConfig.useZipCode &&
              countryRegion?.searchTypeId !== SEARCH_TYPE.country
                ? geoLocation.coordinates.postalCode
                : null,
            latitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lat
              : null,
            longitude: validRadiusSearches(countryRegion)
              ? geoLocation.coordinates.lng
              : null,
            city: !clientConfig.useZipCode
              ? geoLocation.coordinates.city
              : null,
            state: !clientConfig.useZipCode
              ? geoLocation.coordinates.state
              : null,
            searchDisplayText: searchDisplayText,
            categoryIDs: categories,
            locationGroupIds:
              clientConfig.locationGroups?.length > 0
                ? clientConfig.locationGroups.map((x) => x.locationGroupID)
                : null,
            searchTypeId: countryRegion?.searchTypeId,
          });
        }
      }
    }
  }, [geoLocation.loaded, clientConfig.loading]);

  return (
    !clientConfig.loading &&
    geoLocation.loaded &&
    !searchContext.searchParameters.loading && (
      <>
        <Helmet>
          {/* <link
            rel="stylesheet"
            type="text/css"
            href={`${cssBaseURL}/${
              clientConfig.configClientSearch.clientId
            }/landing/${interfaceName}${
              interfaceId ? "." + interfaceId : ""
            }.min.css`}
          /> */}
          <style>{`${clientConfig.basicStyleCSS}`}</style>

          {/* <link
            rel="stylesheet"
            type="text/css"
            href={`${cssBaseURL}/${
              clientConfig.configClientSearch.clientId
            }/landing/${interfaceName}${
              interfaceId ? "." + interfaceId : ""
            }.advanced.min.css`}
          /> */}
          <style>{`${clientConfig.advancedStyleCSS}`}</style>
          <link
            rel="canonical"
            href={`${window.location.href.slice(
              0,
              window.location.href.indexOf("?")
            )}`}
          />
          <script>{`${clientConfig.advancedStyleJS}`}</script>
          {/* <script
            src={`${cssBaseURL}/${clientConfig.configClientSearch.clientId}/landing/${clientConfig.name}.advanced.min.js`}
          >
          </script> */}

          {clientConfig.faviconFileUrl ? (
            <link rel="icon" href={clientConfig.faviconFileUrl} />
          ) : (
            <link rel="icon" href={`${homepage}favicon.ico`} />
          )}
        </Helmet>
        <Suspense fallback={<></>}>
          <ListMapLocator clientConfig={clientConfig} />
        </Suspense>
      </>
    )
  );
};

export default GetMapListConfiguration;
