import { useContext, useEffect, useState } from "react";
import { LocationsContext } from "../context/locations";
import { DoSearch2 } from "../services/restSearchService";
import { SEARCH_TYPE } from "../utils/constants";

export function useDoSearch2(searchParameters, onSuccess, configParameters) {
  const [doSearch2State, setdoSearch2State] = useState({
    loading: true,
    errors: "",
  });

  const { setLocations, clearLocations } = useContext(LocationsContext);

  const clearSearch = () => {
    if (clearLocations) {
      clearLocations();
    }

    setdoSearch2State({
      loading: false,
      errors: "",
    });
  };

  useEffect(() => {
    const localConfigParameters = { ...configParameters };

    if (clearLocations) {
      clearLocations();
    }

    setdoSearch2State({
      loading: true,
    });

    const doSearch2 = async () => {
      // Config transformations
      // Show Internet locations only in the results
      if (configParameters.internetOnlySearch) {
        delete localConfigParameters.internetOnlySearch;

        localConfigParameters.searchTypeOverride = 7;
      }

      // delete locatinoGroupId
      delete localConfigParameters.locationGroupIds;

      // Enable cross-country borders search
      if (
        configParameters.crossCountrySearch &&
        searchParameters.searchTypeId === SEARCH_TYPE.radius
      ) {
        localConfigParameters.SearchTypeOverride = 1;
        localConfigParameters.CountryScope = "All";
      }

      delete localConfigParameters.crossCountrySearch;
      delete localConfigParameters.searchTypeId;

      if (configParameters.showEvents) {
        delete localConfigParameters.showEvents;

        localConfigParameters.returnEvent = true;
      }

      if (
        configParameters.showAllLocationsPerCountry &&
        !configParameters.showNearestLocationsInList &&
        !configParameters.manualSearching
      ) {
        delete localConfigParameters.showAllLocationsPerCountry;
        delete localConfigParameters.showNearestLocationsInList;

        localConfigParameters.maxResults =
          process.env.REACT_APP_MAX_RESULTS_LIST;
      }

      // Save the icons in another variable to avoid noise in the
      // DoSearch2 call. Getting a new array with all the icons
      // assigned to specfic category
      // const iconCategories = configParameters.interfaceIcons?.filter(
      //   (c) => c.interfaceIconCategories.length > 0
      // );
      delete localConfigParameters.interfaceIcons;
      delete localConfigParameters.interface;

      delete searchParameters.locationGroupIds;

      // if (searchParameters.categoryIDs && configParameters.categoryIDs) {
      //   localConfigParameters.categoryIDs += "," + searchParameters.categoryIDs;
      // } else

      if (
        searchParameters.categoryIDs &&
        searchParameters.categoryIDs.length > 0
      ) {
        localConfigParameters.categoryIDs = searchParameters.categoryIDs;
      } else if (configParameters.categoryIDs) {
        localConfigParameters.categoryIDs = configParameters.categoryIDs;
      }

      let locations;

      try {
        locations = await DoSearch2({
          ...searchParameters,
          ...localConfigParameters,
        });
        if (setLocations) {
          setLocations(locations);
        }
      } catch (error) {
        console.error("DoSearch2", error);

        setdoSearch2State({
          loading: false,
          errors: error.response?.headers.errormessage,
        });
      }

      if (locations && locations.ResultList) {
        setdoSearch2State({
          loading: false,
        });
      }

      if (onSuccess) {
        onSuccess();
      }
    };
    console.log("DoSearch2 searchParameters:", searchParameters);

    setdoSearch2State({
      loading: true,
    });

    if (
      searchParameters.countryId &&
      searchParameters.ready &&
      (searchParameters.countryId ||
        searchParameters.state ||
        searchParameters.city ||
        searchParameters.postalCode) &&
      configParameters.configClientSearch?.clientId > 0 &&
      configParameters.configClientSearch.apiKey
    ) {
      doSearch2();
    } else if (
      searchParameters.latitude &&
      searchParameters.longitude &&
      searchParameters.latitude !== 0 &&
      searchParameters.longitude !== 0 &&
      searchParameters.ready
    ) {
      doSearch2();
    } else if (searchParameters.ready && searchParameters.keyword) {
      searchParameters.countryId = searchParameters.countryId || null;

      doSearch2();
    } else {
      clearSearch();
    }
  }, [searchParameters, onSuccess]);

  return [doSearch2State, clearSearch];
}
