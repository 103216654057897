import React, { useState } from "react";
import OverallRating from "./overallRating";
import CardListRating from "./cardListRating";
import "./reviews.scss";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import WriteReview from "./writeReview";

/** Component to display the information of reviews */
const Reviews = ({
  title,
  subTitle,
  extraTitle,
  listReviews,
  isBullseyeReviews = false,
  onSubmit,
  maxItemsToDisplay = 3,
  reviewConfiguration,
  submitReviews,
}) => {
  const { rating, countReviews, reviews, urlMoreReviews } = listReviews;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isShowViewFullReviews, setIsShowViewFullReviews] = useState(false);

  const openLightbox = (isShowReviews = false) => {
    setIsShowViewFullReviews(isShowReviews);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };
  let newReviewConfiguration = { ...reviewConfiguration };
  if (!reviewConfiguration) {
    newReviewConfiguration = {
      allowLocationManageReviews: false,
      comments: true,
      overallRating: true,
      ratingCategoryOne: true,
      ratingCategoryThree: true,
      ratingCategoryTwo: true,
      recommendationQuestion: true,
      reviewerName: true,
    };
  }

  return (
    <>
      {reviews && (
        <div className="reviews__container">
          <div className="d-block bullseye-red h4">{title}</div>
          {!isBullseyeReviews && subTitle && (
            <div className="d-block">{subTitle}</div>
          )}
          <OverallRating
            showOverallRating={newReviewConfiguration.overallRating}
            rating={rating}
            countReviews={countReviews}
            allowWriteReviews={
              isBullseyeReviews &&
              onSubmit &&
              newReviewConfiguration.allowLocationManageReviews &&
              submitReviews
            }
            onClickWriteReviews={() => openLightbox(false)}
          />

          <CardListRating
            listRatings={
              reviews.length >= maxItemsToDisplay
                ? reviews.slice(0, maxItemsToDisplay)
                : reviews
            }
            reviewConfiguration={newReviewConfiguration}
          />
          <div className="my-2 reviews__readMoreReviews">
            {!isBullseyeReviews && (
              <a target="_blank" rel="noreferrer" href={urlMoreReviews}>
                Read More Reviews
              </a>
            )}
            {isBullseyeReviews && reviews.length > maxItemsToDisplay && (
              <button
                onClick={() => openLightbox(true)}
                className="btn btn-link"
              >
                Read More Reviews
              </button>
            )}
          </div>
        </div>
      )}
      {isBullseyeReviews && onSubmit && (
        <Modal
          animation={true}
          show={viewerIsOpen}
          onHide={closeLightbox}
          centered
          contentClassName="reviews__modal--content"
          size={isShowViewFullReviews ? "lg" : "md"}
        >
          <Modal.Header closeButton>
            <div className="d-block bullseye-red h4">{title}</div>
          </Modal.Header>
          <Modal.Body>
            {isShowViewFullReviews && (
              <div className="p-3">
                <CardListRating
                  listRatings={reviews}
                  reviewConfiguration={newReviewConfiguration}
                />
              </div>
            )}
            {!isShowViewFullReviews && (
              <WriteReview
                title={subTitle}
                subTitle={extraTitle}
                onSubmit={onSubmit}
                reviewConfiguration={newReviewConfiguration}
                cancelButtonAction={closeLightbox}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
Reviews.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string.isRequired,
  /** Sub title to display on the header component */
  subTitle: PropTypes.string,
  /** number that defines how many reviews can be displayed, by default it is 3 */
  maxItemsToDisplay: PropTypes.number,
  /** List of reviews to display */
  listReviews: PropTypes.shape({
    /** AVG rating number of the review */
    rating: PropTypes.number.isRequired,
    /** count of the reviews */
    countReviews: PropTypes.number.isRequired,
    /** url external to show more reviews */
    urlMoreReviews: PropTypes.string,
    /** reviews configurations */
    reviewConfiguration: PropTypes.shape({
      /** if you want an option to write review */
      allowLocationManageReviews: PropTypes.bool.isRequired,
      /** if you want to enable rating of services over category one */
      ratingCategoryOne: PropTypes.bool.isRequired,
      /** if you want to enable rating of services over category two */
      ratingCategoryThree: PropTypes.bool.isRequired,
      /** if you want to enable rating of services over category three */
      ratingCategoryTwo: PropTypes.bool.isRequired,
      /** if you want to enable the option to recommend the place */
      recommendationQuestion: PropTypes.bool.isRequired,

      /** if you want to show the comments on the review */
      comments: PropTypes.bool.isRequired,
      /** if you want to show the rating on the review */
      overallRating: PropTypes.bool.isRequired,
      /** if you want to show the Author on the review */
      reviewerName: PropTypes.bool.isRequired,
    }),
    /** List of reviews */
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        /** Name of the person who made the review */
        author_name: PropTypes.string.isRequired,
        /** rating of the review */
        rating: PropTypes.number.isRequired,
        /** description of the time when was made the review */
        relative_time_description: PropTypes.string.isRequired,
        /** description text of the review */
        text: PropTypes.string.isRequired,
        /** List of ratings services if there was a list  */
        services: PropTypes.arrayOf(
          PropTypes.shape({
            /** name of the list to rate */
            name: PropTypes.string.isRequired,
            /** rating of this list */
            rating: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
  /**flag to know if the review is from BullsEye or Google */
  isBullseyeReviews: PropTypes.bool,
  /** Function that returns the information of the form for a new review, for this the option must be enabled in the configuration */
  onSubmit: PropTypes.func,
};

// Specifies the default values for props:
Reviews.defaultProps = {
  isBullseyeReviews: false,
  maxItemsToDisplay: 3,
};
export default Reviews;
