import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App2 from "./app2";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("Sentry OFF...");
} else {
  console.log("Sentry Init...");
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN, // "https://a4b8136cd756409cab7aefbc055f4916@o445828.ingest.sentry.io/6438737", // Loblaw
    integrations: [new Integrations.BrowserTracing()],
    release: "8.50.2",
    environment: process.env.REACT_APP_SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <App2 />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
