import { LanguageState } from ".";

interface LanguageActionType {
  type: "[Language] - Set ClientTerms";
  payload: { language: string; clientTerms: { [key: string]: string } };
}

export const languageReducer = (
  state: LanguageState,
  action: LanguageActionType
): LanguageState => {
  switch (action.type) {
    case "[Language] - Set ClientTerms":
      return {
        ...state,
        clientTerms: action.payload.clientTerms,
        language: action.payload.language,
      };

    default:
      return state;
  }
};
