import React from "react";
import TextInput from "../textInput";
import PropTypes from "prop-types";

const NameInput = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  errors,
  placeholder = "",
  required = false,
  onChange,
  validations,
}) => {
  let firstNameValidation = {};
  let lastNameValidation = {};

  if (Object.keys(validations).length > 0) {
    firstNameValidation = { required: "First Name is required" };
    lastNameValidation = { required: "Last Name is required" };
  }

  return (
    <>
      <TextInput
        name={"FirstName"}
        label={"First Name"}
        compact={compact}
        small={small}
        register={register(firstNameValidation)}
        required={required}
        errors={errors}
        onChange={onChange}
      />
      <TextInput
        name={"LastName"}
        label={"Last Name"}
        compact={compact}
        small={small}
        register={register(lastNameValidation)}
        required={required}
        errors={errors}
        onChange={onChange}
      />
    </>
  );
};

NameInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,

  /** text to put into the input like placeholder */
  placeholder: PropTypes.string,

  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,
};

export default NameInput;
