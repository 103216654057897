import _ from "lodash";
import admins from "../config.json";
import moment from "moment";
import "moment-timezone";

const getUrlParameterValue = (sParam) => {
  var sPageURL = window.location.search.substring(1).toLocaleLowerCase(),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam.toLocaleLowerCase()) {
      return typeof sParameterName[1] === "undefined"
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

const NewId = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

const splitListByColumns = function (
  originalList,
  numberOfColumns,
  screenSize
) {
  let numberColumns;
  let lists = [];

  switch (screenSize) {
    case "sm":
      numberColumns = originalList.length;
      break;
    case "md":
      numberColumns =
        numberOfColumns > 1 ? originalList.length / 2 : originalList.length;
      break;
    case "lg":
      numberColumns = originalList.length / numberOfColumns;
      break;

    default:
      numberColumns = originalList.length / numberOfColumns;
      break;
  }
  lists = _.chunk(originalList, Math.ceil(numberColumns));

  return lists;
};

const getStrListSplitByComma = function (originalList) {
  let strSplit = "";

  originalList.forEach((e) => {
    if (strSplit !== "") strSplit += ", ";
    strSplit += e.title;
  });
  return strSplit;
};
/** Get a category list from the collection of the JSON,  */
const getListCategoryFromCollectionByExistingCategories = function (
  collection,
  existingCategories
) {
  let returnList = [];
  collection
    .filter((e) => {
      return existingCategories.includes(e.id);
    })
    .forEach((item) => {
      returnList.push({ id: item.id, title: item.name });
    });

  return returnList;
};

function getLocationSocialMedia(socialMediaInfo) {
  const icons = [];

  if (!socialMediaInfo) return icons;

  if (socialMediaInfo.InstagramURL) {
    icons.push({
      name: "instagram",
      icon: "https://www.bullseyelocations.com/images/instagram.svg",
      url: admins.instagramMainUrl + socialMediaInfo.InstagramURL,
    });
  }

  if (socialMediaInfo.LinkedInURL) {
    icons.push({
      name: "linkedin",
      icon: "https://www.bullseyelocations.com/local/images/linkedin.svg",
      url: admins.LinkedInMainUrl + socialMediaInfo.LinkedInURL,
    });
  }

  if (socialMediaInfo.FacebookURL) {
    icons.push({
      name: "facebook",
      icon: "https://www.bullseyelocations.com/local/images/facebook.svg",
      url: admins.facebookMainUrl + socialMediaInfo.FacebookURL,
    });
  }

  if (socialMediaInfo.TwitterURL) {
    icons.push({
      name: "twitter",
      icon: "https://www.bullseyelocations.com/local/images/twitter.svg",
      url: admins.TwitterMainUrl + socialMediaInfo.TwitterURL,
    });
  }

  if (socialMediaInfo.PinterestURL) {
    icons.push({
      name: "pinterest",
      icon: "https://www.bullseyelocations.com/local/images/pinterest.svg",
      url: admins.PinterestMainUrl + socialMediaInfo.PinterestURL,
    });
  }

  if (socialMediaInfo.YelpURL) {
    icons.push({
      name: "yelp",
      icon: "https://www.bullseyelocations.com/local/images/yelp.svg",
      url: admins.YelpMainUrl + socialMediaInfo.YelpURL,
    });
  }
  return icons;
}

const documentIcon = (
  <svg role="img" className="mr-2" width="16" height="16">
    <use xlinkHref="/local/images/b-icons/bootstrap-icons.svg#file-earmark-text-fill" />
  </svg>
);

function getStateNameByAbbr(name, region1List) {
  var result = [];
  if (name && region1List) {
    result = region1List.filter(
      (o) => o.Abbr.toLowerCase() === name.toLowerCase()
    );
  }
  return result.length > 0 ? result[0].Name : null; // or undefined
}

function getStateAndCity(state, city, region1List) {
  let content;

  if (state && city) {
    content = `${city},${state}`;
  } else if (state && !city) {
    content = getStateNameByAbbr(state, region1List);
  } else {
    content = "";
  }
  return content;
}

const lowerCase = (str) => str[0].toLowerCase() + str.slice(1);

const objectPropertiesToCamelCase = (object) => {
  const result = object.map((obj) =>
    Object.fromEntries(Object.entries(obj).map(([k, v]) => [lowerCase(k), v]))
  );

  return result;
};

const capitalizeFirstLetter = (string) => {
  if (!string) return "";

  let textToConvertFirstLetter = string.charAt(0).toUpperCase();
  return textToConvertFirstLetter + string.toLowerCase().slice(1);
};

const capitalizeStringFirstLetter = (string) => {
  var splitStr = string.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

function dateToStringFormat(date, format) {
  // ac I removed the add one because was incorrect
  let newDate = moment(date); //.add(1, "d");
  return newDate.format(format);
}

function convertEpochToDate(utcSeconds, shortDate) {
  if (isNaN(utcSeconds)) return null;

  let d = new Date(utcSeconds);

  if (shortDate) {
    return (
      d.toUTCString().split(" ")[1] +
      " " +
      d.toUTCString().split(" ")[2].toUpperCase()
    );
  } else {
    return d.toUTCString().split(" ").slice(0, 4).join(" ");
  }
}

function convertJsonTimeToString(jsonTime) {
  return moment
    .utc(moment.duration(jsonTime).asMilliseconds())
    .format("hh:mm A");
}

function getDaysAgoFromToday(date) {
  const day = moment(date);
  return day.fromNow();
}
function sortArrayReviewsByDateDesc(oldReviews) {
  return oldReviews.sort(function (left, right) {
    return moment(right.DateCreated).diff(moment(left.DateCreated));
  });
}
function datesAreEquals(date1, date2) {
  let newDate1 = moment(date1);
  let newDate2 = moment(date2);

  return newDate1.format("MM/DD/YYYY") === newDate2.format("MM/DD/YYYY");
}
function sortArrayYelpReviewsByDateDesc(oldReviews) {
  return oldReviews.sort(function (left, right) {
    return moment(right.RelativeTime).diff(moment(left.RelativeTime));
  });
}
function isValidPostalCode(postalCode, countryCode) {
  let postalCodeRegex;
  switch (countryCode) {
    case "US":
      postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
      break;
    case "CA":
      postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
      break;
    default:
      postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  }
  return postalCodeRegex.test(postalCode);
}

export function date1IsEqualsOrGreaterThanDate2(date1, date2) {
  let newDate1 = moment(date1);
  let newDate2 = moment(date2);

  return newDate1 >= newDate2;
}

export function getURLReturn(
  interfaceName = "",
  countryParam = "",
  stateParam = "",
  cityParam = "",
  params = "",
  interfaceTypeId
) {
  const urlHostName = window.location.hostname;
  const returnURL = getUrlParameterValue("urlR");
  const returnList = getUrlParameterValue("islist");
  const isInternetLocation = getUrlParameterValue("internetlocation");
  let urlParams = removeURLParameter(window.location.search, "urlR");
  let interfaceVirtualDirectory = interfaceTypeId === 9 ? "/local/" : "/pages/";

  if (
    returnList &&
    !window.location.pathname.includes(process.env.REACT_APP_LIST_ONLY_NAME)
  ) {
    let listReturnUrl;
    if (isInternetLocation) {
      listReturnUrl = `//${urlHostName}/local/${process.env.REACT_APP_LIST_ONLY_NAME}/${interfaceName}`;
    } else {
      //is normal location
      //Need to get back to the list with the current country, state and city
      listReturnUrl = `//${urlHostName}/local/${
        process.env.REACT_APP_LIST_ONLY_NAME
      }/${interfaceName}/${countryParam}/${stateParam}/${cityParam
        .toLocaleLowerCase()
        .replace(" ", "-")}/`;
    }

    return listReturnUrl;
  }

  if (
    window.location.href.includes(process.env.REACT_APP_LIST_ONLY_NAME) &&
    (countryParam || stateParam || cityParam)
  ) {
    //is in the listonlyv2
    //need to check country, state and city params to create the correct return url

    if (countryParam && stateParam && cityParam) {
      //goes to state list
      return `//${urlHostName}/local/${process.env.REACT_APP_LIST_ONLY_NAME}/${interfaceName}/${countryParam}/${stateParam}/`;
    } else {
      if (!urlParams && params) {
        urlParams = params;
      }
      return `//${urlHostName}/local/${process.env.REACT_APP_LIST_ONLY_NAME}/${interfaceName}${urlParams}`;
    }
  } else {
    if (!urlParams && params) {
      urlParams = params;
    } else if (
      interfaceName &&
      !urlParams &&
      countryParam.length === 0 &&
      stateParam.length === 0 &&
      cityParam.length === 0 &&
      window.location.href.includes(process.env.REACT_APP_LIST_ONLY_NAME)
    ) {
      let finalUrlParams = urlParams;
      finalUrlParams = removeURLParameter(finalUrlParams, "islist");
      finalUrlParams = removeURLParameter(finalUrlParams, "internetlocation");

      return `//${urlHostName}${interfaceVirtualDirectory}${interfaceName}${finalUrlParams}`;
    }
  }

  if (returnURL) {
    return `${returnURL}${urlParams}`;
  }

  if (!returnURL) {
    let finalUrlParams = urlParams;
    finalUrlParams = removeURLParameter(finalUrlParams, "islist");
    finalUrlParams = removeURLParameter(finalUrlParams, "internetlocation");
    return `//${urlHostName}${interfaceVirtualDirectory}${interfaceName}${finalUrlParams}`;
  }
}

export function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.toLocaleLowerCase().split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter.toLocaleLowerCase()) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
}

export {
  getUrlParameterValue,
  NewId,
  splitListByColumns,
  getStrListSplitByComma,
  getListCategoryFromCollectionByExistingCategories,
  getLocationSocialMedia,
  getStateNameByAbbr,
  getStateAndCity,
  // getCloseInformation,
  objectPropertiesToCamelCase,
  capitalizeFirstLetter,
  dateToStringFormat,
  getDaysAgoFromToday,
  sortArrayReviewsByDateDesc,
  datesAreEquals,
  sortArrayYelpReviewsByDateDesc,
  documentIcon,
  isValidPostalCode,
  capitalizeStringFirstLetter,
  convertEpochToDate,
  convertJsonTimeToString,
};
