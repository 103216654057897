import React from "react";

const NoLocations = () => {
  return (
    <div className="d-flex">
      <div className="m-5 d-none d-md-block">
        <img src="/images/bullseye-white-icon.png" alt="bullseye icon white" />
      </div>
      <div>
        <div className="my-5 d-block">
          <img
            src="/images/bullseye_logo_outlined.svg"
            alt="bullseye logo"
            style={{ height: 85 }}
          />
        </div>

        <h3 className="bullseye-red">
          There are no locations for this account in Bullseye.
        </h3>

        <p>
          If you are the administrator of the site, please{" "}
          <a href="https://app.bullseyelocations.com/Admin/Login.aspx">
            log into
          </a>
          , the Bullseye Admin to add locations.
        </p>
        <p>
          For more information please visit our website at{" "}
          <a href="https://www.bullseyelocations.com">
            https://www.bullseyelocations.com
          </a>
          , or email{" "}
          <a href="mailto:info@bullseyelocations.com">
            info@bullseyelocations.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default NoLocations;
